<template>
  <div class="subject-category-container">
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Subject Categories
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>
        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Subject Categories</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center " style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img class="cursor" @click="notificationDialog = true" src="../../assets/bell 1.svg">
          </v-hover>
        </v-badge>

        <div>

          <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="px-8 pad">

      <v-row>
        <v-col>

          <v-dialog max-width="887px" class="cdz" v-model="dialog" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="primary mb-8">{{ formbtn() }} Subject Category</v-card-title>
                <v-card-text class="px-6 pb-0">
                  <v-text-field outlined class="rounded-xl" v-model="subCategory" label="Enter Subject Category*" required
                    :rules="[v => !!v || 'Subject Category is required']"></v-text-field>
                  <v-text-field outlined class="rounded-xl pb-0" v-model="subDescription"
                    label="Description"></v-text-field>
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn width="102px" height="48px" rounded outlined class="accent--text pa-4"
                    @click="() => { dialog = false; formbtnBool = false }">Cancel</v-btn>
                  <v-btn width="102px" height="48px" rounded @click="saveInputs" class="accent pa-4" :loading="loading">{{
                    formbtn() }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>

          </v-dialog>
        </v-col>
        <!-- <v-col cols="4">
        <v-text-field label="Search" prepend-inner-icon="mdi-magnify" v-model="search" clearable></v-text-field></v-col> -->
      </v-row>
      <v-row class="d-flex justify-center my-0 py-0">
        <v-col>
          <div class="text-h6">Subject Categories</div>
        </v-col>

        <v-col cols="9" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">

            <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search" hide-details
              class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
            <!-- <v-btn depressed outlined rounded class="border-button mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn> -->
            <v-btn class="border-button mx-3" outlined rounded :disabled="selected.length == 0"
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn class="primary mx-2"
            rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn depressed outlined rounded class="border-button mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-btn v-if="$store.state.role.subjectCategory.admin" @click="dialog = true, newCreateSubCatValue()"
              class="accent mx-3 white--text" depressed rounded><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>

          </v-row>
        </v-col>
      </v-row>
      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table" :search="search" v-model="selected"
        :headers="headers" :loading="dataTableLoading" loading-text="Loading Subject Categories" :items="tableData"
        show-select :single-select="singleSelect" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 50, 100]
        }">
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.subjectCategory.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg"/>
          </v-btn>
        </template>
        <template v-slot:no-results>
          <div class="mt-16 pt-16">
            <img 
            src="../../assets/noResultFound.svg"/>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-container></v-container>
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-6 pb-0">Delete Subject Category</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>This action
                will
                permanently delete the item . This cannot be undone</p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid> <v-btn depressed class="background black--text"
                  large width="157px" rounded @click="deleteDialog = false">CANCEL</v-btn> <v-btn
                  class="accent white--text" depressed large width="157px" rounded :loading="dLoading"
                  @click="deleteData(selected)">DELETE</v-btn></div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Subject Category Deleted</p>
              <v-btn class="accent" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Subject Category {{ formbtnValue() }}</p>
              <v-btn class="accent" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

  </div>
</template>
<script>
import SubjectCategoryController from '@/controllers/SubjectCategoryController';
import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"
import { getDateFromTimestamp } from "@/helpers/utils";

export default {
  name: "SubjectCategoryView",
  components: {
    Notification, MastersDropdownComponent
  },
  data() {
    return {
      notificationDialog: false,
      options: {},
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      dialog: false,
      errorMessage: "",
      subCategory: null,
      subDescription: null,
      singleSelect: false,
      selected: [],
      deleteDialog: false,
      dLoading: false,
      loading: false,
      editId: null,
      formbtnBool: false,
      subjectCategoryData: {},
      search: '',
      searchBool: false,
      dataTableLoading: true,
      headers: [
        { text: "Subject Category", value: "name" },
        { text: "Created  On", value: "createdOn" },
        { text: "Actions", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },

    };
  },
  // watch: {
  //   options: {
  //     handler() {
  //       console.log(this.options);
  //       this.pageSize = this.options.itemsPerPage;
  //       this.page = this.options.page;
  //       if(this.searchBool){
  //         this.searchData(this.search);
  //       }else{
  //         this.getSubjectCategory();
  //       }

  //     },
  //     deep: true,
  //   },
  //   search(newValue){
  //     console.log(newValue);
  //       this.searchBool=true
  //       this.pageSize = this.options.itemsPerPage;
  //       this.page = this.options.page;
  //       this.options.page=1;
  //       this.searchData(newValue);
  //       if(newValue=="" || newValue==null){
  //         this.getSubjectCategory();
  //         this.searchBool=false;
  //       }
  //   }
  // },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async downloadCSV(csvData, fileName) {

      const csv = Papa.unparse(csvData);


      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {

          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    downlaodRoles() {
      const fileName = 'subjectcategory.csv';
      this.downloadCSV(this.tableData, fileName);
    },
    // async searchData(search){
    //   const response = await SubjectCategoryController.searchSubCat(
    //     this.pageSize,
    //     this.page,
    //     search,
    //   );
    //   console.log(response.data);
    //   console.log(this.searchBool);
    //   this.count = response.data.data.count;
    //   this.tableData = response.data.data.rows;
    // },

    getDate(timeStamp) {
      return getDateFromTimestamp(timeStamp);
    },

    updateData(item) {
      console.log(item.id)
      this.editId = item.id; // selected id for edit 
      this.formbtnBool = true; // change update/create btn value
      this.dialog = true;
      this.subCategory = item.name;
      this.subDescription = item.description;
    },
    newCreateSubCatValue() {
      this.subCategory = null;
      this.subDescription = null;
      this.formbtnBool = false
    },

    formbtn() {
      return this.formbtnBool === false ? 'Create' : 'Update'
    },
    formbtnValue() {
      return this.formbtnBool === false ? 'Created' : 'Updated'
    },

    async saveInputs() {
      if (this.$refs.form.validate()) {
        var res;
        // checking case for update/create
        this.loading = true;
        if (this.formbtnBool == false) {
          const response = await SubjectCategoryController.createCategory(this.subCategory, this.subDescription);
          console.log(response)
          res = response;
        }
        else {
          const response = await SubjectCategoryController.updateCategory(this.subCategory, this.subDescription, this.editId)
          console.log(response);
          res = response
        }
        // Close the dialog
        this.loading = false;
        this.formbtnBool == false;
        this.dialog = false;
        if (res.data.flag) {
          this.successDialog = true;
        } else {
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }
        this.getSubjectCategory();
      }
    },

    async deleteData(data) {
      // if(data.length==1){
      // this.dLoading = true;
      // const response = await SubjectCategoryController.deleteCategory(data[0].id);
      // this.getSubjectCategory();
      // this.deleteDialog = false;
      // console.log(response)
      // this.dLoading = false;
      // this.selected = []
      // }else{
      //   var ids="";
      //   for(var i=0;i<data.length;i++){
      //     ids = ids + data[i].id;
      //       if( i != data.length - 1 ) {
      //           ids = ids + ","
      //       }
      //   }
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id)
      })
      this.dLoading = true;
      const response = await SubjectCategoryController.deleteBulkCategory(ids);
      if (response.data.flag) {
        this.getSubjectCategory();
        this.deleteDialog = false;
        console.log(response)
        this.dLoading = false;
        this.selected = []
      } else {
        alert(response.data.error)
        this.getSubjectCategory();
        this.deleteDialog = false;
        console.log(response)
        this.dLoading = false;
        this.selected = []
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },


    async getSubjectCategory() {
      const response = await SubjectCategoryController.getCategoryByPagination(
        this.pageSize,
        this.page
      );
      if (response.status == 200) {
        this.dataTableLoading = false;
        this.count = response.data.count;
        if (response.data.subjectCatgories.length > 0) {
          this.subjectCategoryData = response.data.subjectCatgories;
          this.tableData = this.sortByAlphabet(this.subjectCategoryData, "name")
        }
      }
      else {
        this.dataTableLoading = false;
        // alert(response.data.error);
        alert("Something went wrong");
      }


    },


  },
  created() {
    if (!this.$store.state.role.subjectCategory.read) {
      this.$router.push('/notFound')
    }
    this.getSubjectCategory();
    this.$store.state.breadcrumb = "Subject Category";
  },
};
</script>
<style></style>