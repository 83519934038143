import { render, staticRenderFns } from "./FunnelChartSchool4.vue?vue&type=template&id=51544930&scoped=true&"
import script from "./FunnelChartSchool4.vue?vue&type=script&lang=js&"
export * from "./FunnelChartSchool4.vue?vue&type=script&lang=js&"
import style0 from "./FunnelChartSchool4.vue?vue&type=style&index=0&id=51544930&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51544930",
  null
  
)

export default component.exports