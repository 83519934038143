<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              League
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>
        <!-- <p class="breadcrumb-text" style="margin-top: 2px">League</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img class="cursor" @click="notificationDialog = true" src="../../assets/bell 1.svg" />
          </v-hover>
        </v-badge>

        <div>
          <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="px-8 pad">
      <v-row>
        <v-col>
          <v-dialog max-width="887px" class="cdz" v-model="dialog" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="primary mb-8">{{ formbtn() }} League</v-card-title>
                <v-list-item-title class="text-center mb-4">
                  Upload league icon <sup>*</sup>
                </v-list-item-title>

                <div class="d-flex justify-center mb-6 bg-surface-variant">
                  <div class="box" @dragover.prevent @drop="onDrop" v-if="!image">
                    <div class="uploadrow">
                      <div class="uploadleft">
                        <v-icon class="uploadicon">mdi-tray-arrow-up</v-icon>
                      </div>
                      <div class="uploadright">
                        <div>
                          <p class="font-weight-light">Drag .png or .svg here</p>
                          <p class="font-weight-light">OR</p>
                          <div class="font-weight-light">
                            <div class="uplaodbtn">
                              Click here
                              <input type="file" name="image" @change="onChange" />
                            </div>
                            to browse(512 Kb max.)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>

                  <div v-if="image">
                    <div class="imgback">
                      <img :src="image" alt="" width="100" height="auto" class="img" />
                      <div class="pencil">
                        <v-icon class="editpen">mdi-pencil-circle</v-icon>
                        <input class="editlogo" type="file" name="image" @change="onChange" />
                      </div>
                    </div>
                  </div>
                </div>
                <v-card-text class="pt-3 px-6">
                  <v-row class="py-0">
                    <v-col class="py-0" cols="12">
                      <v-text-field outlined class="rounded-xl" v-model="name" label="League Name*"
                        :rules="[(v) => !!v || 'League Name is required']" required></v-text-field>
                    </v-col>

                  </v-row>
                  <!-- <v-row class="py-0">
                    <v-col class="py-0" cols="12">
                      <v-file-input chips outlined rounded id="fileInput" class="rounded-xl no-prepend-icon no-elevation"
                        label="Select Icon" solo accept="image/png, image/svg" v-model="iconURL" @change="onChange" flat>
                        <template #append>
                          <div class="d-flex align-center cursor n-mt-10">
                            <v-icon>mdi-attachment</v-icon> Attachment
                          </div>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row class="py-0">
                    <v-col class="py-0" cols="12">
                      <v-file-input chips outlined rounded id="fileInput" class="rounded-xl no-prepend-icon elevation-0"
                        label="Select Banner" solo accept="image/png, image/svg" v-model="iconURL" flat>
                        <template #append>
                          <div class="d-flex align-center cursor n-mt-10">
                            <v-icon>mdi-attachment</v-icon> Attachment
                          </div>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row> -->
                </v-card-text>
                <v-card-text class="pb-3 px-6 pt-0">

                </v-card-text>
                <v-card-text class="py-0 my-0 px-6">
                  <v-row class="py-0">
                    <!-- <v-col cols="3" class="py-0">
                      <div class="mt-4">Select range for League</div>
                    </v-col> -->
                    <v-col cols="6" class="py-0">
                      <v-text-field outlined label="Eligibility From*" v-model="startsFrom"
                        class="rounded-xl py-0 "></v-text-field>
                    </v-col>
                    <v-col fluid cols="6" class="py-0">
                      <v-text-field outlined label="Eligibility To*" v-model="endsAt"
                        class="rounded-xl py-0"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn width="102px" height="48px" rounded outlined class="accent--text pa-4"
                    @click="dialog = false">Cancel</v-btn>
                  <v-btn :disabled="selectedFile == null" width="102px" height="48px" :loading="loading" rounded
                    @click="uploadIcon" class="accent pa-4">{{
                      formbtn() }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-col>
        <!-- <v-col cols="4">
        <v-text-field
          label="Search"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          clearable
        ></v-text-field
      ></v-col> -->
      </v-row>
      <v-row class="d-flex justify-center my-0 py-0">
        <v-col cols="2">
          <div class="text-h6 pt-1">League</div>
        </v-col>

        <v-col cols="10" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">

            <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search" hide-details
              class="search-bar border-button rounded-pill mx-3" style="display: flex; align-items: center;" dense
              clearable></v-text-field>

            <!-- <v-btn depressed outlined rounded class="border-button mx-3"
              @click="filterDialog = true"><v-icon>mdi-tune</v-icon>Filter</v-btn> -->
            <v-btn class="border-button mx-3" rounded outlined :disabled="selected.length == 0"
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn class="primary mx-2" rounded
            ><v-icon>mdi-export</v-icon>Export</v-btn
          > -->
            <v-btn depressed outlined rounded class="border-button mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-btn v-if="$store.state.role.skills.admin" @click="(dialog = true), newCreateSkills()"
              class="accent mx-3 white--text" depressed rounded><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>
            <v-dialog v-model="deleteDialog" max-width="366px" class="cdz" persistent>
              <v-card fluid>
                <v-container fluid class="pa-0">
                  <v-container></v-container>
                  <v-card-text class="text-center">
                    <v-icon size="70" class="pt-4">mdi-trash-can-outline</v-icon>
                    <p class="text-h5 pt-6 pb-0">Delete Skill</p>
                    <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                      This action will permanently delete the item . This cannot
                      be undone
                    </p>

                    <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                      <v-btn depressed class="background black--text" large width="157px" rounded @click="() => {
                        deleteDialog = false;
                        formbtnBool = false;
                      }
                        ">CANCEL</v-btn>
                      <v-btn class="accent white--text" depressed large width="157px" rounded :loading="dLoading"
                        :disabled="selected.length == 0" @click="deleteData(selected)">DELETE</v-btn>
                    </div>
                  </v-card-text>
                </v-container>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table" :search="search" v-model="selected"
        :loading="dataTableLoading" loading-text="Loading Leagues" :headers="headers" :items="tableData" show-select
        :single-select="singleSelect" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 50, 100],
        }">
        <template v-slot:[`item.iconURl`]="{ item }">
          <v-img height="30px" width="30px" :src="item.iconURl"
            class="pa-6 bg-secondary rounded-circle d-inline-block"></v-img>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.skills.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" />
          </v-btn>
        </template>
        <template v-slot:no-results>
          <div class="mt-16 pt-16">
            <img 
            src="../../assets/noResultFound.svg"/>
          </div>
       </template>

      </v-data-table>
      <v-dialog v-model="errorDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteSuccessDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">League Deleted</p>
              <v-btn class="accent" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p v-if="formbtnBool" class="text-h5 py-4">Skill Updated</p>

              <p v-else class="text-h5 py-4">New Skill Created</p>
              <v-btn class="accent" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- filter dialog-->
      <v-dialog v-model="filterDialog" class="cdz" max-width="400px">
        <v-card width="400px" height="100%">
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div @click="clearFilter" class="text-body1 font-weight-bold black--text cursor">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card height="450px" elevation="0" id="myScroll" class="pt-5 ,pb-5">
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text">
                      Subject
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group v-model="selectedSubjectFilter" active-class="primary" column :multiple="true">
                  <!-- <v-chip
                    v-for="(SubjectType, index) in subjects"
                    :key="index"
                    :value="SubjectType"
                    elevated
                  >
                    {{ SubjectType.name }}
                  </v-chip> -->
                </v-chip-group>
              </v-card>
              <div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn rounded outlined class="pa-4" @click="filterDialog = false">Cancel</v-btn>
                  <v-btn rounded class="accent pa-4" @click="filterSkills(selectedSubjectFilter)">Apply</v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

  </div>
</template>
<script>
import LeagueController from "@/controllers/LeagueController";
import SubjectController from "@/controllers/SubjectController";
import AuthService from "../../services/AuthService";
import SkillsController from "@/controllers/SkillsController";
import { storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"


export default {
  name: "LeagueView",
  components: {
    Notification, MastersDropdownComponent
  },

  data() {
    return {
      notificationDialog: false,
      options: {},
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      deleteDialog: false,
      dialog: false,
      dialogTitle: "Dialog Title",
      name: "",
      startsFrom: "",
      image: "",
      selectedFile: null,
      endsAt: "",
      iconURL: "NA",
      bannerURL: null,
      errorMessage: "",
      selected: [],
      singleSelect: false,

      dLoading: false,
      filterData: false,
      boards: [],
      board: null,
      formbtnBool: false,

      filterDialog: false,
      selectedSubjectFilter: [],

      search: "",
      loading: false,
      searchBool: false,
      dataTableLoading: true,
      headers: [
        { text: "Icon", value: "iconURl" },
        { text: "League Name", value: "name" },
        { text: "Starts From", value: "startsFrom" },
        { text: "Ends At", value: "endsAt" },
        { text: "Actions", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
    };
  },
  // watch: {
  //   options: {
  //     handler() {
  //       console.log(this.options);
  //       this.pageSize = this.options.itemsPerPage;
  //       this.page = this.options.page;
  //       if (this.filterData) {
  //         this.filterSkills(this.selectedSubjectFilter);
  //       } else if (this.searchBool) {
  //         this.searchData(this.search);
  //       } else {
  //         this.getLeagues();
  //       }
  //     },
  //     deep: true,
  //   },
  //   search(newValue) {
  //     console.log(newValue);
  //     this.searchBool = true;
  //     this.pageSize = this.options.itemsPerPage;
  //     this.page = this.options.page;
  //     this.options.page = 1;
  //     this.searchData(newValue);
  //     if (newValue == "" || newValue == null) {
  //       this.getLeagues();
  //       this.searchBool = false;
  //     }
  //   },
  // },
  methods: {

    onChange(e) {
      console.log(e)
      this.uploadIcon()
      this.selectedFile = e.target.files[0];
      this.createFile(this.selectedFile);
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedFile = e.dataTransfer.files[0];
      this.createFile(this.selectedFile);
    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      // var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);

    },
    removeFile() {
      this.image = "";
      this.iconURl = "";
    },
    uploadIcon() {
      // this.loading = true;
      if (this.selectedFile != null) {
        console.log("Uploaded", this.selectedFile.name);

        const storageRef = ref(storage, "/leagues/" + this.selectedFile.name);
        uploadBytes(storageRef, this.selectedFile).then((snapshot) => {
          console.log("Uploaded");
          getDownloadURL(snapshot.ref).then((url) => {
            this.iconURl = url;
            console.log("url ", url)
            console.log("url ", this.iconURl)

            this.saveInputs(url);
            this.loading = false;

          }).catch((error) => {
            this.loading = false;

          });
        });
      }
    },

    async downloadCSV(csvData, fileName) {

      const csv = Papa.unparse(csvData);


      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {

          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    downlaodRoles() {
      const fileName = 'leagues.csv';
      this.downloadCSV(this.leaguesData, fileName);
    },
    // async getPreSignedUrl() {
    //   const response = await UploadController.getPreSignedUrl({
    //     context: "user-profiles",
    //     file_name: this.selectedFile[this.expandedPanelIndex].name,
    //     mime_type: this.selectedFile[this.expandedPanelIndex].type,
    //     file_type: "certifcate",
    //     business_type: "b2c",
    //     uuid: "123-456-7",
    //   });
    //   this.preSignedUrl = response.data.data.signed_request;
    //   this.academicQualifications[this.expandedPanelIndex].certificateUrl =
    //     response.data.data.url;
    //   this.uploadToS3();
    // },



    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    // async searchData(search) {
    //   const response = await SkillsController.searchSkills(
    //     this.pageSize,
    //     this.page,
    //     search
    //   );
    //   console.log(response.data);
    //   console.log(this.searchBool);
    //   this.count = response.data.data.count;
    //   this.tableData = response.data.data.rows;
    // },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    updateData(item) {
      this.editId = item.id;
      // selected id for edit
      this.name = item.name;
      this.startsFrom = item.startsFrom;
      this.endsAt = item.endsAt;
      this.iconURl = this.iconURL,
        this.bannerURL = this.BannerURL


      // this.selectedSubjects = item.subjects;
      // (this.novicMin = item.noviceMin),
      //   (this.novicMax = item.noviceMax),
      //   (this.advBegMin = item.advanceBegineerMin),
      //   (this.advBegMax = item.advanceBegineerMax),
      //   (this.competentMin = item.competentMin),
      //   (this.competentMax = item.competentMax),
      //   (this.proficientMin = item.proficientMin),
      //   (this.proficientMax = item.proficientMax),
      //   (this.expertMin = item.expertMin),
      //   (this.expertMax = item.expertMax),
      console.log(item);
      this.formbtnBool = true; // change update/create btn value
      this.dialog = true;
    },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      if (response.status == 200) {
        if (response.data.subjects.length > 0) {
          this.subjects = response.data.subjects;
        }
      }
    },
    async newCreateSkills() {
      this.name = "",
        this.startsFrom = "",
        this.endsAt = ""
      this.iconURl = ""
      this.selectedFile = ""
      this.bannerURL = ""
      this.formbtnBool = false
    },

    async saveInputs(url) {
      console.log("savve data")
      if (this.$refs.form.validate()) {
        var res;
        this.loading = true;
        // checking case for update/create
        if (this.formbtnBool == false) {
          const response = await LeagueController.createLeague({
            name: this.name,
            startsFrom: this.startsFrom,
            endsAt: this.endsAt,
            iconURl: url,
            bannerURL: this.BannerURL
          });
          console.log("create cate", response);
          res = response;
        } else {
          const response = await LeagueController.updateLeagues(
            {
              name: this.name,
              startsFrom: this.startsFrom,
              endsAt: this.endsAt,
              iconURl: url,
              bannerURL: this.BannerURL
            },
            this.editId
          );
          console.log(response);
          res = response;
        }
        // Close the dialog

        this.formbtnBool == false;
        this.dialog = false;
        if (res.data.flag) {
          this.loading = false;
          this.successDialog = true;
        } else {
          this.loading = false;
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }
        this.getLeagues();
      }
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    selectAll() {
      // Copy all v-select's items in your selectedItem array
      this.selectedSubjects = this.subjects.map((obj) => obj.name);
    },


    async getLeagues() {
      const response = await LeagueController.getLeagues();
      if (response.status == 200) {
        this.dataTableLoading = false;
        console.log(response);

        this.count = response.data.count;
        if (response.data.leagues.length > 0) {
          this.leaguesData = response.data.leagues;
          this.tableData = this.sortByAlphabet(this.leaguesData, "name");
        }
      } else {
        this.dataTableLoading = false;
        // alert(response.data.error);
        alert("Something went wrong");
      }
    },


    async deleteData(data) {
      // if (data.length == 1) {
      //   const response = await SkillsController.deleteSkills(data[0].id);
      //   this.getLeagues();
      //   console.log(response);
      //   this.deleteDialog = false;
      //   this.selected.length = 0;
      // } else {
      //   var ids = "";
      //   for (var i = 0; i < data.length; i++) {
      //     ids = ids + data[i].id;
      //     if (i != data.length - 1) {
      //       ids = ids + ",";
      //     }
      //   }
      this.dLoading = true;
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id);
      });
      const response = await LeagueController.deleteBulkLeagues(ids);
      if (response.data.flag) {
        this.dLoading = false;
        console.log(response);
        this.deleteDialog = false;
        this.selected.length = 0;
        this.getLeagues();
      } else {
        this.dLoading = false;
        alert(response.data.error);
        this.getLeagues();
        console.log(response);
        this.deleteDialog = false;
        this.selected.length = 0;
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },
    clearFilter() {
      this.selectedSubjectFilter = [];
      this.filterDialog = false;
      this.getLeagues();
      this.filterData = false;
    },
    async filterSkills(selectSubject) {
      console.log("filter function call");
      var subjectIds = "";
      selectSubject.forEach((item) => {
        subjectIds = subjectIds + item.id + ",";
      });
      subjectIds = subjectIds.slice(0, -1);
      const response = await SkillsController.filterSkills(
        subjectIds,
        this.pageSize,
        this.page
      );
      console.log(response);
      this.filterDialog = false;
      this.schoolData = response.data.data;
      this.tableData = this.schoolData.rows;
      console.log("GradeIds", subjectIds);
      this.count = response.data.data.count;
      this.filterData = true;
    },
  },
  created() {
    if (!this.$store.state.role.skills.read) {
      this.$router.push("/notFound");
    }
    this.getLeagues();
    this.getSubjects();
    this.$store.state.breadcrumb = "Skills";
  },
};
</script>
