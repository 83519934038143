import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_DASHBOARD_API_URL,
});
export default {
    async getMestaStudentResults(query) {
        try {
            const response = await instance.get("getMestaStudentResults" +query);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
}