<!-- PieChart -->
<template>
  <div class="card-container">
    <div class="card-content">
      <GChart type="PieChart"  :data="chartData" :options="chartOptions" />
      <div class="card-back d-flex flex-column justify-space-between">
        <h3 class="left-aligned">Campaign Status</h3>
        <div class="left-aligned">
          LIVE CAMPAIGN - <span class="text-h5"></span>
        </div>
        <div class="left-aligned">
          CAMPAIGN ABOUT TO END - <span class="text-h5"></span>
        </div>
        <div class="left-aligned">
          NO. OF SCHOOL PARTICIPATED - <span class="text-h5"></span>
        </div>
        <div class="left-aligned">
          NO. OF TEACHERS PARTICIPATED - <span class="text-h5"></span>
        </div>
        <div class="total-count">
          <span text-subtitle-1>VIEW ALL DATA</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";


export default {
  name: "DonutChartSchool",
  props: {
    clustersData: {
      type: Array,
      required: true,
    }
  },

  components: {
    GChart,
  },
  data() {
    return {
      chartData: [],
      chartOptions: {
        title: "Cluster-wise Participation",
        hAxis: null,
        titleTextStyle: {
          fontSize: 14, // Increase the font size of the title
          alignment: "start", // Align the title to the start (left)
          padding: { top: 0, left: 0 }, // Add padding to the top and left of the title
        },
        vAxis: {
          minValue: 0,
          gridlines: { color: "transparent" }, // Remove background lines
          textPosition: "none", // Remove y-axis values
        },
        areaOpacity: 1, // Set the opacity of the area chart to 1
        colors: this.generateGreenColors(30), // ["#D1EA67", "#8FC579", "#5A9E77", "#33766D", "#214E50"]
    
        legend: {
          position: "bottom", // Set the legend position to the left side
        },
        chartArea: {
          left: "12%",
          right: 0,
         
        },
        pieHole: 0.4,
        annotations: {
          style: "line",
          textStyle: {
            fontSize: 12,
            fontName: "GraphikMedium",
            bold: true,
          },
        },
      },
      showContent: false,
      //chartData:[["",""]],
    };
  },

  watch: {
    
    schoolData: {
      
      handler(newValue) {
        this.chartData = newValue
       // console.log("new  knd wfd",newValue);

        // console.log("newValue",this.schoolData)
      //  console.log('Checkpoint 06: Watch - schoolData updated');
        // this.convertDataToChartData(newValue);
      const height=window.innerHeight;
      this.chartOptions.height=height*0.34
      },
      immediate: true, 
    },
  },
  methods: {
    generateGreenColors(count) {
      const colors = [];
      const baseColor = "#77c97a"; // Base green color
      const shadeIncrement = 14; // Increment value for generating shades

      for (let i = 0; i < count; i++) {
        const shade = i * shadeIncrement;
        const color = this.calculateShade(baseColor, shade);
        colors.push(color);
      }

      return colors;
    },

    calculateShade(baseColor, shade) {
      // Convert baseColor to RGB
      const baseColorRgb = this.hexToRgb(baseColor);

      // Calculate shaded color
      const shadedColorRgb = {
        r: baseColorRgb.r,
        g: baseColorRgb.g - shade,
        b: baseColorRgb.b,
      };

      // Convert shadedColorRgb back to hex
      const shadedColorHex = this.rgbToHex(shadedColorRgb);

      return shadedColorHex;
    },

    hexToRgb(hex) {
      // Extract RGB values from hex color code
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },

    rgbToHex(rgb) {
      // Convert RGB values to hex color code
      return (
        "#" +
        ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b)
          .toString(16)
          .slice(1)
      );
    },
  },

  created() {
      console.log("created >>",this.clustersData)
    this.chartData = this.clustersData;

  },
};
</script>

<style scoped>
.card-container {
  position: relative;
  perspective: 1000px;
}

.card-content {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: #ffffff;
}

.card-content.flipped {
  transform: rotateY(180deg);
}

.link-container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
}

.flip-link {
  color: #007fff;
  cursor: pointer;
  font-weight: bold;
}
.left-aligned {
  text-align: left;
  color: black;
}

.spaced {
  padding-bottom: 12px; /* Adjust the value as needed */
}

.total-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  height: 31px;
  background-color: #4d695b;
  border-radius: 4px;
  padding: 0 2px 0 2px;
}
</style>