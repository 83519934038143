<template>
    <GChart class="timeChart" type="BarChart" :data="chartData" :options="chartOptions" />
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
    name: "TimeChart",
    components: {
        GChart
    },
    props:["timeData"],
    data() {
        return {

            chartData: this.timeData,

            // chartData: [
            //     ['Type', 'Time', { role: 'style' }],
            //     ['Given', 19.30, '#9F84AE'],
            //     ['Average', 10.49, '#AA69CE' ],
            //     ['Current', 12.40, '#9F84AE' ],
            // ],
            chartOptions: {
                title: 'Time Taken',
               
                titleTextStyle: {
                    fontSize: 12
                },
                hAxis: {
                    title: 'Time (Sec)',
                    ticks: [],
                    viewWindow: {
                        min: 0,
                        // max: 20
                    },
                    gridlines: {
                        color: 'transparent' 
                    },
                },
                vAxis: {
                    title: '',
                },
                legend: 'none'
            },
        }
    }
}
</script>

<style scoped>
.timeChart {
    height: 12.5rem;
    padding-bottom: 0.5rem;
}
</style>

