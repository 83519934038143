<template>
  <div v-if="isLoading===true">
    <template>
      <v-dialog v-model="isLoading" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div v-else>
    <div class="background sticky-element pb-4">
    <v-card height="64px" width="100%"
      class="ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Roles</p> <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center " style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg">
          </v-hover>
        </v-badge>
        <div>

          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <!-- <v-btn @click="logout">logout</v-btn> -->
        </v-menu>
      </div>
    </v-card>
    </div>
    <!-- <div class="d-flex ">  -->

      <div class="w-100 px-8 d-flex justify-between pb-4">
      <span class="align-center" style="font-size: 20px; font-weight: 500">{{role.roleName}}</span>
       <div class="ml-auto">
        <!-- <v-btn rounded depressed class="px-6 mr-4" @click="cancel">Cancel</v-btn> -->
        
        <v-btn rounded depressed class="px-6 mr-4" v-if="isEdit == false"  @click="close">Close</v-btn>

        <v-btn rounded depressed class="px-6 mr-4" v-if="isEdit == true" @click="isEdit = false; getRoleGroupUsers('ALL')">Cancel</v-btn>

        <v-btn rounded depressed class="accent px-6" v-if="isEdit == false" @click="isEdit = true" :disabled="disableEdit == true">Edit</v-btn>

        <v-btn rounded depressed class="accent px-6" v-if="isEdit == true" @click="editUserConfirmation" >Submit</v-btn>
      </div>
    </div>
    <!-- </div> -->
    <div class="d-flex align-center mb-4">
        <div class="w-100 px-8">
          <v-card elevation="0" height="40px"
            class="w-100 outlined white--text d-flex pl-8 pr-2 align-center justify-space-between primary rounded-t-lg card-header-layout">
            <div class="text--primary text-h6 font-weight-medium py-4">
              USER CONTROL
            </div>
            
          </v-card>
          <v-card>
            <div class="my-2 pt-2 px-4 d-flex justify-space-between align-center">

              <v-chip-group v-model="userStatus" class="d-flex">
                <v-chip class="mr-2 pa-4" outlined :color="userStatus == 0 ? 'accent' : ''" @click=" getRoleGroupUsers('ALL')">
                  ALL ({{ counts.ALL }})
                </v-chip>
                <v-chip class="mr-2 pa-4" outlined :color="userStatus == 1 ? 'accent' : ''" @click=" getRoleGroupUsers('INVITED')">
                  INVITED ({{ counts.INVITED }})
                </v-chip>
                <v-chip class="mr-2 pa-4" outlined :color="userStatus == 2 ? 'accent' : ''" @click=" getRoleGroupUsers('ACTIVE')">
                  ACTIVE ({{ counts.ACTIVE }})
                </v-chip>
                <v-chip class="pa-4" outlined :color="userStatus == 3 ? 'accent' : ''" @click=" getRoleGroupUsers('INACTIVE')">
                  INACTIVE ({{ counts.INACTIVE }})
                </v-chip>
              </v-chip-group>

              <div>
                  <v-chip class="mr-2 pa-4" outlined @click="filterDialog = true"> <v-icon left>
                  mdi-tune
                  </v-icon><span v-if="filterCount > 0">Filter ({{ filterCount }})</span>
                  <span v-else>Filter</span></v-chip>

                <v-chip class="mr-2 pa-4" outlined :disabled="activateUsers == false" @click="activateDialog = true" v-if="userStatus == 3">ACTIVATE</v-chip>

                <v-chip v-else class="mr-2 pa-4" outlined :disabled="deactivateUsers == false" @click="deactivateDialog = true">DEACTIVATE</v-chip>


                <v-chip class=" pa-4" outlined :disabled="resendInviteToUsers == false" @click="inviteConfirmationDialog = true">RESEND INVITE</v-chip>
              </div>
          </div>
          <v-divider></v-divider>
          <v-card  width="100%" class="elevation-0  mr-4 ">
            <div class="w-100">
              <v-data-table
              fixed-header
              height="calc(100vh - 349px)"
              :items="roleSpecificUsers"
              item-key="uid"
              >
               <template v-slot:header="{ props }">
                <tr height="70px">
                  <th>
                    <input class="big-checkbox" type="checkbox"  
                  @change="selectAllItems" v-if="selectedUser.length > 0" v-model="selectAll" />
                  </th>
                  <th class="left-align pl-4"><b>Email</b></th>
                  <th class="left-align pl-4"><b>Status</b></th>
                  <th><b>Cluster</b></th>
                  <th><b>School</b></th>
                </tr>
                <!-- <tr>
                  <td colspan="5">
                    <v-divider></v-divider>
                  </td>
                </tr> -->

              </template>
                
              <template v-slot:item="{ item }">

              <tr>
                
              <td class="text-center">
                
                <input class="big-checkbox" type="checkbox"  :disabled="!item.isCheckboxEnabled"
                  @change ="onCheckboxClick()" :value="item" v-model="selectedUser" />
              </td>
              <td class="left-align"> <div class="text-body-1"><b>{{ item.name }}</b></div>
                  <div class="text-body-1">{{ item.email }}</div></td>
               <td class="left-align">{{ item.status }}</td>
              <td class="py-3"><v-autocomplete
                    class="rounded-xl" 
                    v-model="item.clusterName"
                    :items="clusterData"
                    item-text="name"
                    item-value="name"
                    outlined
                    hide-details
                    :disabled="!isEdit"
                    @change="getSchool(item)"
                    :rules="[(v) => !!v || 'Cluster is required']"

                  ></v-autocomplete></td>
                  <td class="py-3"><v-autocomplete
  class="rounded-xl" 
  v-model="item.schoolName"
  :items="item.schools"
  item-text="name"
  item-value="name"
  outlined
  hide-details
  :disabled="!isEdit"
  :rules="[(v) => !!v || 'School is required']"
></v-autocomplete>
                </td>
       
                </tr>
                </template>
                
              </v-data-table>

            </div> 
          </v-card>
        </v-card>
          
      </div>
      </div>

      <v-dialog v-model="inviteConfirmationDialog" class="cdz" max-width="685px" min-height="308px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <img src="../assets/Resend_Popup.svg">
              <p class="text-h5 py-4">Are you sure you want to Resend the email ?</p>
              <v-row justify="space-between">
                <v-col cols="6">
                  <v-btn block large rounded @click="inviteConfirmationDialog = false">Cancel</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn class="accent" block large rounded @click="resendInvite">Submit</v-btn>
                </v-col>
            </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- update confirmation dialog -->
      <v-dialog v-model="updateConfirmationDialog" class="cdz" max-width="685px" min-height="308px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <img src="../assets/Resend_Popup.svg">
              
              <p class="text-h5 py-4">Are you sure you want to update the User ?</p>
              <v-row justify="space-between">
                <v-col cols="6">
                  <v-btn block large rounded @click="updateConfirmationDialog = false">Cancel</v-btn>
                </v-col>
                <v-col cols="6">
                  
                  <v-btn class="accent" block large rounded @click="updateUsers">Submit</v-btn>
                </v-col>
            </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- activate/deactivate confirmation dialog -->
      <v-dialog v-model="activateDialog" class="cdz" max-width="685px" min-height="308px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <img src="../assets/activate_user.svg">
              
              <p class="text-h5 py-4">Are you sure you want to activate the User ?</p>
              <v-row justify="space-between">
                <v-col cols="6">
                  <v-btn block large rounded @click="activateDialog = false">Cancel</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn class="accent" block large rounded @click="activateOrDeactivateUsers('ACTIVE')" >Submit</v-btn>
                  
                </v-col>
            </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- deactivate confirmation dialog -->
      <v-dialog v-model="deactivateDialog" class="cdz" max-width="685px" min-height="308px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <img src="../assets/deactivate_user.svg">
              <p class="text-h5 py-4" >Are you sure you want to deactivate the User ?</p>
              
              <v-row justify="space-between">
                <v-col cols="6">
                  <v-btn block large rounded @click="deactivateDialog = false">Cancel</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn class="accent" block large rounded @click="activateOrDeactivateUsers('INACTIVE')" >Submit</v-btn>
                  
                </v-col>
            </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      

      <v-dialog v-model="inviteSuccessDialog" class="cdz" max-width="424px" min-height="303px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center" v-if="successLoading">
              <img src="../assets/admin-loader.gif" width="95px" alt="Loading...">
            </v-card-text>
            <v-card-text class="text-center" v-else>
              <img src="../assets/done.svg">
              <p class="text-h5 py-4">INVITATION SENT</p>
              <v-btn class="accent" large width="207px" rounded @click="closeInviteSuccessDialog">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" class="cdz" max-width="424px" min-height="303px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center" v-if="successLoading">
              <img src="../assets/admin-loader.gif" width="95px" alt="Loading...">
            </v-card-text>
            <v-card-text class="text-center" v-else>
              <img src="../assets/done.svg">
              <p class="text-h5 py-4">USER DEACTIVATED</p>
              <v-btn class="accent" large width="207px" @click="closeSuccessDialog('INACTIVE')"rounded >OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="updateSuccessDialog" class="cdz" max-width="424px" min-height="303px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center" v-if="successLoading">
              <img src="../assets/admin-loader.gif" width="95px" alt="Loading...">
            </v-card-text>
            <v-card-text class="text-center" v-else>
              <img src="../assets/done.svg">
              <p class="text-h5 py-4">USER UPDATED</p>
              <v-btn class="accent" large width="207px" @click="closeUpdateDialog('ALL')"rounded >OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="activeSuccessDialog" class="cdz" max-width="424px" min-height="303px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center" v-if="successLoading">
              <img src="../assets/admin-loader.gif" width="95px" alt="Loading...">
            </v-card-text>
            <v-card-text class="text-center" v-else>
              <img src="../assets/done.svg">
              <p class="text-h5 py-4">USER ACTIVATED</p>
              
              <v-btn class="accent" large width="207px" @click="closeSuccessDialog('ACTIVE')"rounded >OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card
          width="570px"
          height="100vh"
          class="filterDialogClass scroll-view"
        >
        <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div
                @click="clearFilter"
                class="text-body1 font-weight-bold black--text cursor"
              >
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>
          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <v-tab
                class="black--text justify-start"
                v-if="this.clusterSchool == ''"
                active-class="activeTab"
                >Cluster & School
                <span v-if="schoolTypeFilter.length > 0" class="ml-1"
                  >({{ schoolTypeFilter.length }})</span
                >
              </v-tab>
            </div>
            <v-tab-item v-if="this.clusterSchool == ''">
              <v-card flat class="school-filter">
                <v-card-text class="align-center d-flex flex-column">
                  <v-card-title class="ml-0 pl-0"> Clusters </v-card-title>
                  <v-chip-group
                    v-model="clusterTypeFilter"
                    active-class="secondary"
                    column
                    :multiple="true"
                    @change="getFilterCount"
                  >
                    <!-- @click="getClustersSchool" -->
                    <v-chip
                      v-for="(data, index) in clusterFilterData"
                      :key="index"
                      elevated
                      :value="data.name"
                    >
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>

                  <v-card-title class="pb-4 ml-0 pl-0"> Schools </v-card-title>

                  <v-card width="326px" class="elevation-0 transparent">
                    <v-text-field
                      solo
                      label="Search here..."
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      v-model="searchSchool"
                      class="search-bar2 border-button rounded-pill mx-2"
                      dense
                      clearable
                    ></v-text-field>
                  </v-card>

                  <v-progress-circular
                    class="mt-10"
                    indeterminate
                    v-if="
                      clustersSchool.length == 0 &&
                      clusterTypeFilter.length != 0
                    "
                    :size="76"
                  ></v-progress-circular>
                  <v-chip-group
                    v-model="schoolTypeFilter"
                    class="mt-4"
                    active-class="secondary"
                    column
                    @change="getFilterCount"
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(data, index) in showClusterSchool"
                      height="40px"
                      class="w-100 rounded-lg"
                      :rounded="10"
                      :key="index"
                      elevated
                      :value="data.name"
                    >
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div>
            <v-card-actions class="px-6 my-2">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                outlined
                class="accent--text pa-4"
                @click="filterDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                rounded
                class="accent mx-4 pa-4"
                :disabled="filterCount == 0"
                :loading="dLoading"
                @click="
                  (appliedFilter = true),
                    (filterDialog = false),
                    filterUser(
                      clusterTypeFilter,
                      schoolTypeFilter
                    ),
                    getFilterCount()
                "
                >Apply</v-btn
              >
            </v-card-actions>
          </div>
      </v-card>
      </v-dialog>

</div>
</template>
<script>
  import SchoolController from "@/controllers/SchoolController";
  import ClusterController from "@/controllers/ClusterController";
  import RolesController from "@/controllers/RolesController";

export default {
name: "RoleUserView",

data(){
	return{
		preloader:false,
    isLoading:true,
    roleSpecificUsers:[],
    singleSelect:true,
    count:0,
    selectedUser:[],
    options: {},
    clusterData: [
        {
          name: "All Clusters",
          id: "all",
        },
      ],
      clusterFilterData:[],
      schools: [
        { name: "All" },
      ],
      userStatus:0,
      role: this.$route.params.role,
      isEdit:false,
      successDialog:false,
      inviteConfirmationDialog:false,
      sendInviteUser:{},
      deactivateUsers:false,
      resendInviteToUsers:false,
      counts:{},
      successDialog:false,
      inviteSuccessDialog:false,
      updateConfirmationDialog:false,
      selectAll: false,
      filterDialog:false,
      dLoading:false,
      filterData:false,
      filterCount: 0,
      filterVal: "",
      appliedFilter:false,
      clusterTypeFilter: [],
      schoolTypeFilter: [],
      clusterSchool: "",
      showClusterSchool: [],
      clustersSchool:[],
      searchSchool: "",
      debouncedFetchUsers: null,
      isDeactivate:false,
      clustSchools:[],
      updateUser:[],
      activateUsers:false,
      activateDialog:false,
      deactivateDialog:false,
      activeSuccessDialog:false,
      successLoading:false,
      disableEdit:false,
      updateSuccessDialog:false,
      schoolsByCluster: null,
      allSchools: null,
	}
},
  watch: {
    searchSchool(newValue) {
      if (newValue === "") {
        this.showClusterSchool = this.clustersSchool;
      } else {
        const lowercaseNewValue = newValue?.toLowerCase();
        this.showClusterSchool = this.clustersSchool.filter((obj) =>
          obj.name?.toLowerCase().includes(lowercaseNewValue)
        );
      }
    },

    clusterTypeFilter(newValue) {
      this.getClustersSchool(newValue);
    },
    '$route.params.role': function(newRole) {
      this.role = newRole;
      
    }

  },
  methods:{

    async loadAllData() {
      try {
        await this.fetchAllClusters();
        const activeStatus = this.getActiveStatus();
        await this.getRoleGroupUsers(activeStatus);
        // Add any other data loading methods here
      } catch (error) {
        console.error("Error loading data:", error);
        // Handle the error appropriately
      } finally {
        this.isLoading = false;
      }
    },
    editUserConfirmation(){
      this.isEdit = false; 
      this.updateConfirmationDialog = true;
      this.isDeactivate = false;
    },
    deactivateUserConfirmation(){
      this.updateConfirmationDialog = true;
      this.isDeactivate = true;
    },
    getActiveStatus(){
      if(this.userStatus == 1){
        return 'INVITED'
      }
      else if(this.userStatus == 2){
        return 'ACTIVE'
      }
      else if(this.userStatus == 3){
        return 'INACTIVE'
      }
      else{
        return 'ALL'
      }
    },
    getFilterCount() {
      this.filterCount = 0;
      let data = [this.schoolTypeFilter]
      this.filterCount = data.reduce((acc, cur)=> cur.length>0? acc+1 : acc, 0)
    },  
    clearFilter() {
      this.appliedFilter = false;
      this.clusterTypeFilter = [];
      this.schoolTypeFilter = [];
      this.filterVal = "";
      this.filterCount = 0;
      this.filterData = false;
     
      this.filterUser(
        this.clusterTypeFilter,
        this.schoolTypeFilter
      );
    },
    async filterUser(
      clusterTypeFilter,
      schoolTypeFilter
    ) {
      this.dLoading = true;
      const activeStatus = this.getActiveStatus();
      
      await this.getRoleGroupUsers(activeStatus);
      this.dLoading = false;
      this.filterData = true;
      
    },
    closeInviteSuccessDialog() {
      this.inviteSuccessDialog = false;
      this.resendInviteToUsers = false;
      // this.getRoleGroupUsers();
      this.selectedUser = []; // Unselect all checkboxes
      // Enable all checkboxes
      this.roleSpecificUsers.forEach(user => {
        user.isCheckboxEnabled = true;
      });
      this.disableEdit = false;
    },
    closeSuccessDialog(status){
      this.successDialog = false;
      this.activeSuccessDialog = false;
      this.deactivateUsers = false;
      this.activateUsers = false;
      this.getRoleGroupUsers(status);
      this.updateChipSelection(status);
      this.disableEdit = false;
    },
    closeUpdateDialog(status){
      this.getRoleGroupUsers(status);
      this.updateChipSelection(status);
      this.updateSuccessDialog = false;
    },
    selectAllItems() {
      if (this.selectAll == true) {
        // Select all items where isCheckboxEnabled is true
        this.selectedUser = this.roleSpecificUsers.filter(item => item.isCheckboxEnabled);
      } else {
        // Unselect all items
        this.selectedUser = [];
        this.deactivateUsers = false;
        this.resendInviteToUsers = false;
        this.roleSpecificUsers.forEach(item => {
          item.isCheckboxEnabled = true;
        });
        this.disableEdit = false;
      }
    },
    
    onCheckboxClick() {
      if(this.selectedUser.length > 0){
        this.disableEdit = true;
        if(this.selectedUser[0].status == 'ACTIVE'){
          this.deactivateUsers = true;
        }
        else if(this.selectedUser[0].status == 'INACTIVE'){
          this.activateUsers = true;
        }
        else if(this.selectedUser[0].status == 'INVITED'){
          this.resendInviteToUsers = true;
        }
        else{
          this.deactivateUsers = false;
          this.resendInviteToUsers = false;
          this.activateUsers = false;
        }
        this.roleSpecificUsers.forEach(item => {
        if (this.selectedUser[0].status != item.status) {
          item.isCheckboxEnabled = false;
        } 
        });
      }
      else{
        this.deactivateUsers = false;
        this.resendInviteToUsers = false;
        this.activateUsers = false;
        this.disableEdit = false;
        this.selectAll = false;
        this.roleSpecificUsers.forEach(item => {
          item.isCheckboxEnabled = true;
        });
      }
      
    },
    
    close(){
      this.$router.push({
        path: "/roles",
        name: "RoleView",
      });
    },
    confirmInvite(user){
      this.sendInviteUser = user;
      this.inviteConfirmationDialog = true;
    },
    async resendInvite(){
      this.inviteConfirmationDialog = false;
      this.inviteSuccessDialog = true;
      this.successLoading = true;
      try {
        const response = await RolesController.inviteRole({
          invitedUserList: this.selectedUser,
          roleId: this.role.id,
          resend: true
        });
        
        if (response.data.flag) {
          this.selectedUser = [];
          this.successLoading = false;
          // this.inviteSuccessDialog = false;
          
        } else {
          console.error('Error inviting users:');
        }
      } catch (error) {
        console.error('Error inviting users:');
      } finally {
        this.successLoading = false;
        // this.inviteSuccessDialog = false;
        // this.inviteLoading = false;
      }
    },
    activateOrDeactivateSelectedUsers(action) {
      this.selectedUser.forEach(user => {
        user.status = action;
      });
    },
    updateChipSelection(action){
      if (action == 'ACTIVE')
        this.userStatus = 2;
      else if (action == 'INACTIVE')
        this.userStatus = 3;
      else if(action == 'INVITED')
        this.userStatus = 1;
      else
        this.userStatus = 0;
    },
    updateDialogStatus(action) {
      if(action === 'ACTIVE'){
        this.activateDialog = false;
        this.activeSuccessDialog = true;
        this.successLoading = true;
      }
      else{
        this.deactivateDialog = false;
        this.successDialog = true;
        this.successLoading = true;
      }

    },
    async activateOrDeactivateUsers(action){
      try {

        this.updateDialogStatus(action);
        this.activateOrDeactivateSelectedUsers(action);
        
        const response = await RolesController.updateUsers({
          editUserList: this.selectedUser,
          roleId: this.role.id,
          activateOrDeactivate:true
        });

        if (response.data.flag) {
          this.selectedUser = [];
          this.successLoading = false;
            
        } else {
          console.error('Error updating users:', response.message || response);
        }
      } catch (error) {
        console.error('Error updating users:', error);
      } finally {
        this.successLoading = false;
        
        // this.inviteLoading = false;
      }
    },
    async updateUsers(){
      try {
        const response = await RolesController.updateUsers({
          editUserList: this.roleSpecificUsers,
          roleId: this.role.id,
          activateOrDeactivate:false
        });

        if (response.data.flag) {
          this.updateConfirmationDialog = false;
          this.updateSuccessDialog = true;
          await this.getRoleGroupUsers('ALL');
          this.roleSpecificUsers.forEach(user => this.getSchool(user));
        } else {
          console.error('Error updating users:', response.message || response);
        }
      } catch (error) {
        console.error('Error updating users:', error);
      }
    },
	  logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();
      if (response.data.flag) {

        var data = response.data.clusters;
        this.clusterData = [...this.clusterData, ...data];
        this.clusterFilterData = data;
      } else {
        alert(response.data.error);
      }
    },
    async getClustersSchool(clusterTypeFilter) {
      if (this.clusterTypeFilter.length > 0) {
        const response = await SchoolController.getClusterSchool(
          this.clusterTypeFilter
        );
        if (response.status == 200) {
          var schools = response.data.schools;
          this.clustersSchool = schools;
          this.showClusterSchool = this.clustersSchool;
          
        } else {
          // alert(response.data.error);
        }
      } else {
        this.showClusterSchool = [];
      }
    },

    getSchool(user) {
      if (user.clusterName && user.clusterName !== 'All Clusters') {
        const schools = this.schoolsByCluster[user.clusterName] || [];
        // Ensure the user's current school is included
        if (user.schoolName && !schools.some(school => school.name === user.schoolName)) {
          schools.push({ name: user.schoolName });
        }
        // Append 'All' to each school list
        this.$set(user, 'schools', [{ name: 'All' }, ...schools]);
        this.$set(this, 'schools', [{ name: 'All' }, ...schools]);
      } else {
        // Append 'All' to each school list
        this.$set(user, 'schools', [{ name: 'All' }, ...this.allSchools]);
        this.$set(this, 'schools', [{ name: 'All' }, ...this.allSchools]);
      }
    },
    async getRoleGroupUsers(status) {
      this.selectedUser = [];
      this.selectAll = false;
      try {
        const response = await RolesController.getRoleGroupUsers(this.role.id, status,this.clusterTypeFilter,this.schoolTypeFilter);

        const updatedUsers = response.data.data.map((user) => ({
          ...user,
          isCheckboxEnabled: true, 
        }));
        const allSchoolsResponse = await SchoolController.getSchool();
        this.allSchools = allSchoolsResponse.data.schools;

        const uniqueClusters = [...new Set(updatedUsers.map(user => user.clusterName).filter(Boolean))];
        const schoolResponse = await SchoolController.getClusterSchool(uniqueClusters);
        this.schoolsByCluster = schoolResponse.data.schools.reduce((acc, school) => {
          if (!acc[school.cluster]) acc[school.cluster] = [];
          acc[school.cluster].push(school);
          return acc;
        }, {});

        updatedUsers.forEach(user => {
          if (user.clusterName && user.clusterName !== 'All Clusters') {
            user.schools = [{ name: 'All' }, ...(this.schoolsByCluster[user.clusterName] || [])];
            user.schoolName = user.schools.find(school => school.name === user.schoolName)?.name || "";
          } else {
            user.schools = [{ name: 'All' }, ...this.allSchools];
            user.schoolName = user.schoolName || "";
          }
        });

        this.$set(this, 'roleSpecificUsers', updatedUsers);
        this.$set(this, 'counts', response.data.counts);
        
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }   
    },
  
},
props: {
     user: {
      type: Object,
      required: true,
      default: () => ({}) 
    },
    
},
computed: {
    switchColor() {
      return this.user.status === 'INVITED' ? 'grey' : 'accent';
    },
    
  },
  async created(){
    await this.loadAllData();
    this.isLoading = false;
  },
  async mounted() {
    
    await this.fetchAllClusters();  
  },
}	
</script>
<style scoped>
  .list-layout {
    top: 321px !important;
    left: 1565px !important; 
  }
  .active {
    font-weight: 600;
    color: var(--brown-01);
    border: 1px solid var(--brown-01);
    background: rgb(217, 203, 190);
  }
  .theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: white !important;

  }
  tr:nth-child(even) {
    background: white !important;
  }
.my-custom-row-class {
  padding-top: 10px; /* Adjust as needed */
  padding-bottom: 10px; /* Adjust as needed */
}
.custom-header {
  text-align: center;
}
.left-align{
  text-align: left;
}
.big-checkbox {
  transform: scale(1.4); /* Adjust the scale value as needed */
  margin: 8px; /* Adjust the margin as needed */
}


</style>
