<template>
  <section>
    <v-slider
        v-model="skill.threshold"
        :tick-labels="ticksLabels"
        :min="1"
        :max="10"
        step="1"
        ticks="always"
        tick-size="4"
        :disabled = "sliderDisabled"
      ></v-slider>

  </section>
</template>

<script>
export default {
  props: ["sliderDisabled", "skill"],
  data() {
    return {
      ticksLabels: ["01","02","03","04","05","06","07","08","09","10"],
    };
  },
  watch: {
    "skill.threshold"(newValue) {
      this.$emit('thresholdSending', newValue);
    }
  },

};
</script>
