<template>
  <div fluid class="funnel-container pl-0 pr-0">
        <GChart type="AreaChart" :data="chartData" :options="chartOptions" />
        <img class="chart-icon" src="../assets/DownIcon.png" alt="Icon"/>

  </div>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";
 
export default {
  name: "FunnelChart6",
  props: ["invites"],
  components: {
    GChart,
  },
  data() {
    return {
      maxValue:0,
      chartData: [],
      chartOptions: {
      title: '',
      // hAxis: null,
      hAxis: {
          baselineColor: "none" // Set the x-axis baseline color to transparent
        },
      vAxis: {
          minValue: 0,
          maxValue:0,
          gridlines: { color: 'transparent' }, // Remove background lines
          textPosition: 'none' // Remove y-axis values
     },
     areaOpacity: 1, // Set the opacity of the area chart to 1
     colors: ["#C3ED4B"], // Set the color of the area graph to yellow
     width:'100%',
     height:64,
     backgroundColor:'#FFFFFF',
     chartArea:{left:0,right:0,top:0,width:'100%',height:'100%'}
      },
    };
  },

  watch: {
    invites: {
      immediate: true,
      handler(newValue) {
        // console.log("inside watcher",newValue);
        this.convertDataToChartData(newValue);
        this.chartOptions.vAxis.maxValue=newValue.jobSeekerInvited
        
      const height=window.innerHeight;
      this.chartOptions.height=height*0.1032
      },
    },
  },
  methods: {
    convertDataToChartData (invites) {
   
   // console.log("inside method",invites);
   this.chartData = [["", ""]];
   this.chartData.push(["", invites.jobSeekerScreeningStarted]);
   this.chartData.push(["", invites.jobSeekerScreeningPassed]);
   // console.log("inside method",this.chartData);
 
 },
  },
  created(){
    // console.log("isnide created",this.invites);
  }
};
</script>
 <style scoped>
    .funnel{
      background: #FFFFFF;
      border-radius: 8px;
      padding-left: 0px;
      padding-right: 0px;
      margin-right:0px ;
      width: 100%;
    }
</style>
<!--
 
-->