<template>
  <div class="w-100">
    <div  v-if="showGraph" ref="chartContainer"   class="chart ml-n4 w-100" id="Chart-Container"></div>
    <div  class="w-100 text-center data-not-available" v-else>
      Data Not Available
    </div>
  </div>
</template>

<script>
export default {
  name: "CampTotalPieChart",
  props: ["graphData"],
  data() {
    return {
      chart: null,
      chartData: [],
      selectedRowIndex: -1,

      showGraph: false,
    
    };
  },
  watch: {
    graphData: {
      immediate: true,
      handler(newVal) {
        // console.log('graphData has changed:', newVal);
        this.chartData = newVal;
        
        this.checkvalue();
        if (google.charts && this.showGraph) {
          google.charts.setOnLoadCallback(this.drawChart);
        }
      },
    },
  },
  mounted() {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(() => {
      if (this.chartData.length > 0) {
        this.drawChart();
      }
    });
  },
  methods: {
    checkvalue(){
      if(this.chartData.length > 1){
        var sum = 0;
        this.chartData.forEach((obj)=>{
          sum += obj[1]
        })
          sum > 0 ? this.showGraph =  true : this.showGraph =  false
      }
      else {
        this.showGraph =  false
      }
    },
    drawChart() {
      this.chart = new google.visualization.BarChart(
        this.$refs.chartContainer
      );

      const data = google.visualization.arrayToDataTable(this.chartData);

      const options = {
        title: "Cluster",
        width: 350,
        height: 132,
   
        titleTextStyle: {
          
          fontSize: 14, // Increase the font size of the title
          fontName: "GraphikMedium",
          bold: false, // Make the title bold if desired
          alignment: "start", // Align the title to the start (left)
          padding: { top: 0, left: 0 }, // Add padding to the top and left of the title
          
        },
        legend: { position: "none" },
        vAxis: {
          minValue: 0,
          gridlines: { color: "blue" },
          testPosition: "none",
        },
        bars: "horizontal",
        series: {
          0: { bar: { groupWidth: "100%" } },
        },
        axes: {
          x: {
            0: { side: "top", label: "" },
          },
          hAxis: {
            textPosition: "none",
          },
        },
        annotations: {
          textStyle: {
            fontSize: 16,
          },
        },
       
        chartArea: {
          left: 76,
          right: 0,
          top: 48,
          width: "100%",
          height: "100",
        },
      };  
  

      this.chart.draw(data, options);
      google.visualization.events.addListener(
        this.chart,
        "select",
        this.chartSelect
      );


      
    },
    chartSelect() {
  const selectedItem = this.chart.getSelection()[0];
        if(selectedItem == undefined){
          this.chart.setSelection([]);
           this.selectedRowIndex = -1;
           this.$emit("data-updated",null );
        }
  
if (selectedItem) {
  const selectedRow = selectedItem.row; 
  const isSelected = selectedItem.row === this.selectedRowIndex;

  if (isSelected) {
    this.chart.setSelection([]);
    this.selectedRowIndex = -1;
  } else {
    this.selectedRowIndex = selectedRow;
  }
  this.$emit("data-updated",this.chartData[selectedRow+1][0]);
}
},

  },
};

</script>

<style scoped>
.chart {
  position: absolute;
  font-size: 13.5px;
  z-index: 100;
  /* width: 100px;
  height: 10px; */
  margin-left: 20px;
  margin-top: -10px;
}

.chart > h3 {
  font-weight: 400;
}

</style>
