<template>
    <GChart class="columnChart" type="ColumnChart" :data="chartData" :options="chartOptions" />
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
    name: "ReportBar",
    components: {
        GChart
    },

    props:["chartData", "maxValue"],
    // props: ["colData"],

    data() {
        return {
            chartOptions: {
                title: '',
                hAxis: {
                    title: '',
                },
                vAxis: {
                    title: '',
                    gridlines: {
                        color: 'transparent'
                    },
                    maxValue: this.maxValue,
                },
                series: {
                    0: { color: '#E0ED4B' },
                    1: { color: '#B0D644' }, 
                },
                legend: 'none',
                backgroundColor: 'white'
            },
        }
    }
}
</script>

<style scoped>
.columnChart {
    height: 94% !important;

    /* background-color: red; */
    /* padding-bottom: 2rem !important; */
}
</style>


