import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});
export default {

    getWeightages: async function (id) {
        try {
            const response = await instance.get('weightages?adminId=' + id,
                {
                    headers: {
                        'Authorization': AuthService.getToken()
                    }
                })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    // deleteWeightages: async function (id, weightageId) {
    //     console.log("delete selected id", weightageId)
    //     try {
    //         const response = await instance.delete('weightages?adminId=' + id,
    //             {
    //                 ids: [weightageId],
    //             },
    //             {
    //                 headers: {
    //                     'Authorization': AuthService.getToken()
    //                 }
    //             });
    //         return response;
    //     } catch (error) {
    //         return error.response;
    //     }
    // },

    deleteWeightages : async function (id,ids) {
        console.log("delete selected id",ids)
        try {
            const response = await instance.delete('weightages?adminId=' + id,{
                data:{
                    ids:ids
                }
            },
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },


    createSkillsWeightageThresholds: async function (data, id) {
        try {
            const response = await instance.post('weightage?adminId=' + id,
                data, 
                {
                    headers: {
                        'Authorization': AuthService.getToken()
                    }
                })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updateSkillsWeightageThresholds: async function(data) {
        try {
            const response = await instance.put('weightage',
            data,
            {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        }
        catch (error) {
            return error.response;
        }
    }
}