<template>
    <div class="funnel-container pl-0 pr-0">
      <GChart type="AreaChart" :data="chartData" :options="chartOptions" />
      <div class="chart-label">{{ invites[propertyBegin] }}</div>
    </div>
  </template>
  
  <script>
  import { GChart } from "vue-google-charts/legacy";
  
  export default {
    name: "FunnelChartCommon",
    props: {
    scaleMax: {
      type: String,
      required: true
    },
    invites: {
      type: Object,
      required: true,
    },
    propertyBegin: {
      type: String,
      required: true,
    },
    propertyEnd: {
      type: String,
      required: true,
    },
    funnelColor: {
      type: String,
      default: "#4AA42A",
    },
  }, 
    components: {
      GChart,
    },
    data() {
      return {
        chartData: [],
        chartOptions: {
          title: '',
          hAxis: {
            baselineColor: "none"
          },
          vAxis: {
            viewWindow: {
              max: 0
            },
            minValue: 0,
            gridlines: { color: 'transparent' },
            textPosition: 'none'
          },
          areaOpacity: 1,
          colors: [this.funnelColor],
          width: '100%',
          height: '100%',
          backgroundColor: '#FFFFFF',
          chartArea: { left: 0, right: 0, top: 0, width: '100%', height: '100%' }
        },
      };
    },
  
    watch: {
      invites: {
        immediate: true,
        handler(newValue) {
          this.convertDataToChartData(newValue);
          // this.chartOptions.vAxis.maxValue =;
          this.chartOptions.vAxis.viewWindow.max = newValue[this.scaleMax];
          const height = window.innerHeight;
          this.chartOptions.height = height * 0.1523;
        },
      },
    },
    methods: {
      convertDataToChartData(invites) {
        this.chartData = [["", ""]];
  
        // Use the 'property' prop to dynamically access the property of the 'invites' object
        this.chartData.push(["", invites[this.propertyBegin]]);
        this.chartData.push(["", invites[this.propertyEnd]]);
      },
    },
  };
  </script>
  
  <style scoped>
    .funnel-container {
      background: #FFFFFF;
      border-radius: 8px;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 0px;
      width: 100%;
    }
  </style>
  