<template>
  <div>
    <!-- Filter Dialog -->
    <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
      <v-card width="570px" height="100vh" class="filterDialogClass">
        <v-row class="mt-4 mx-1">
          <v-col>
            <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
              Filter
            </div>
          </v-col>
          <v-col class="d-flex justify-end">
            <div
              @click="clearFilter"
              class="text-body1 font-weight-bold black--text cursor"
            >
              CLEAR FILTER
            </div>
          </v-col>
        </v-row>

        <v-tabs vertical>   
          <div class="ml-3 mt-4 mr-4 filterLine">
            <v-tab key="Levels" class="black--text justify-start" active-class="activeTab">
              Levels
              <span v-if="selectedLevelFilter.length > 0" class="ml-1">
                ({{ selectedLevelFilter.length }})</span>
            </v-tab>
            <v-tab key="SetNumbers" class="black--text justify-start" active-class="activeTab">
              Sets
              <span v-if="selectedSetFilter.length > 0" class="ml-1">
                ({{ selectedSetFilter.length }})</span>
            </v-tab>
            <v-tab key="NepLevels" class="black--text justify-start" active-class="activeTab">
              Nep Level
              <span v-if="selectedNepLevelFilter.length > 0" class="ml-1">
                ({{ selectedNepLevelFilter.length }})</span>
            </v-tab>
          </div>

        <v-tab-item key="Levels">
          <v-card>
            <v-card-text>
              <v-chip-group active-class="secondary" v-model="selectedLevelFilter" column :multiple="true">
                <v-chip v-for="(level,index) in levelOptions" 
                :key="index"
                :value="level"
                elevated>
                  {{level}}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item key="SetNumbers">
          <v-card>
            <v-card-text>
              <v-chip-group active-class="secondary" v-model="selectedSetFilter" column :multiple="true">
                <v-chip v-for="(set,index) in setNumsOptions" 
                :key="index"
                :value="set"
                elevated>
                  Set{{set}}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item key="NepLevels">
          <v-card>
            <v-card-text>
              <v-chip-group active-class="secondary" v-model="selectedNepLevelFilter" column :multiple="true">
                <v-chip v-for="(nepLevel,index) in nepLevelOptions" 
                :key="index"
                :value="nepLevel"
                elevated>
                  {{nepLevel}}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>


        <div class="filterDialogButton">
          <v-card-actions class="px-6 pb-0">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              outlined
              class="accent--text pa-4"
              @click="closeFilter"
              >Cancel</v-btn
            >
            <v-btn
              class="accent pa-4"
              :disabled="selectedLevelFilter.length==0 && selectedSetFilter.length==0 && selectedNepLevelFilter.length==0"
              @click="filterQuestions(selectedLevelFilter,selectedSetFilter,selectedNepLevelFilter),getFilterCount()"
              >Apply</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>                
    </v-dialog>

    <v-card
      height="64px"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Psychometry</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg" />
          </v-hover>
        </v-badge>

        <div>
          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>

    <v-container fluid class="px-8 py-0 qpad">
      <div>
        <v-row class="mt-6 justify-space-between">
          <v-col cols="3">
            <div class="text-h6 pb-1">Psychometry</div>
          </v-col>
          <v-col cols="9" class="mt-0 mb-6">
            <v-row justify="end">
              <v-text-field
                solo
                label="Search here..."
                prepend-inner-icon="mdi-magnify"
                hide-details
                v-model="search"
                class="search-bar border-button rounded-pill mx-3"
                dense
                clearable
              ></v-text-field>
              <v-btn @click="toggleFilterDialog" depressed rounded outlined class="border-button mx-3 pl-4 pr-6"
                ><v-icon class="pr-2">mdi-tune</v-icon>
                <span v-if="filterCount > 0">FILTER ({{ filterCount }})</span>
                <span v-else>FILTER</span>
              </v-btn>
              <v-btn v-if="$store.state.role.questionBank.admin" depressed rounded outlined
                class="border-button mx-3 pl-4 pr-6"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>

              <v-btn depressed outlined rounded class="border-button mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>

              <!-- <v-btn class="accent mx-3" rounded depressed>
                                <v-icon class="pr-2">mdi-plus</v-icon> Create
                            </v-btn> -->

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="accent mx-3 pl-4 pr-6" rounded depressed v-bind="attrs" v-on="on"
                    v-if="$store.state.role.questionBank.admin">
                    <v-icon class="pr-2 ">mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-icon>mdi-file-document-multiple </v-icon>
                      <input type="file" @change="handleFileUpload" />

                      CREATE NEW QUESTIONS IN BULK
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="downloadFile" class="cursor">
                      <v-icon>mdi-book-open-variant </v-icon>
                      DOWNLOAD SAMPLE FILE
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </v-row>
          </v-col>
        </v-row>

        <v-data-table
          :loading="isLoading"
          v-model="selectedTableItems"
          :headers="headers"
          height="calc(100vh - 230px)"
          fixed-header
          :items="displayedQuestions"
          :search="search"
          show-select
          :single-select="singleSelect"
          item-key="id"
          :items-per-page="-1"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
        >
          <template v-slot:[`item.questionStatement`]="{ item }">
            <div v-html="item.questionStatement"></div>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            {{ item.level }}
          </template>
          <template v-slot:[`item.setNumber`]="{ item }">
            <div class="text-center">{{item.setNumber}}</div>
          </template>
          <template v-slot:[`item.actions`]>
            <div class="d-flex flex-row">
              <img width="36px" height="36px" class="cursor" src="../assets/edit.svg" />
            </div>
          </template>
        </v-data-table>
      </div>

      <!-- Uploading Bulk Questions Dialog-->
      <v-dialog max-width="600px" max-height="80vh" v-model="uploadingQuestionsDialog" center>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="6" md="6">
              <v-card-title class="pa-0 ma-0">
                Uploading... {{ bulkQuestionUploadResponse.length }} /
                {{ bulkQuestionJsonData.length }}
              </v-card-title>
            </v-col>
            <v-col cols="6" md="6">
              <v-btn class="float-right" icon
                @click="uploadingQuestionsDialog = false; getQuestions();"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-data-table :headers="headersresponce" :items="bulkQuestionUploadResponse" :items-per-page="-1">
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex flex-row ml-2">
                {{ item.status }}

                <v-icon v-if="item.status.includes('Uploaded Successfully!')" color="green">mdi-check-all</v-icon>
                <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Papa from "papaparse";
import PsychometryController from "@/controllers/PsychometryController";
import axios from "axios"

export default {
  data() {
    return {
      bulkQuestionJsonData: [],
      headersresponce: [
        { text: "Question Index in CSV", value: "questionIndex" },
        { text: "Status", value: "status" },
      ],
      uploadingQuestionsDialog: false,
      search: "",
      selectedTableItems: [],
      headers: [
        { text: "Question", value: "questionStatement", cellClass: "auto" },
        { text: "Level", value: "level", cellClass: "w-10" },
        { text: "Set No", value: "setNumber", cellClass: "w-10"}
        // { text: "nep Level", value: "nepLevel", cellClass: "w-10" },
        //{ text: "Actions", value: "actions", cellClass: "w-10" },
      ],
      tableData: [],
      singleSelect: false,

      bulkQuestionUploadResponse: [],
      isLoading:true,
      filterDialog:false,
      appliedFilter:false,
      filterCount:0,
      selectedLevelFilter:[],
      selectedSetFilter:[],
      selectedNepLevelFilter:[],
      filteredArray:[],
      setNumsOptions:[],
      levelOptions:[],
      nepLevelOptions:[],

      appliedSearch:false,
      searchedResults:[],
    };
  },

  computed:{
    displayedQuestions(){
      if(this.appliedFilter==true){
        if(this.appliedSearch==true){
          return this.searchedResults
        }
        else{
          return this.filteredArray
        }
      }
      else{
        if(this.appliedSearch==true){
          return this.searchedResults
        }
        else{
          return this.tableData
        }
      }
      
    }
  },

  watch:{
    search(newValue){
      if (newValue=='' || newValue == null) {
        this.appliedSearch=false;
      }
      else{
        this.searchData(newValue);
        // console.log(newValue);
      }
    },
  },

  methods: {
    toggleFilterDialog(){
      this.filterDialog=true;
      this.getSets();
      this.getLevels();
      this.getNepLevel();
    },

    searchData(search){
      if(this.appliedFilter==true){
        this.searchedResults=this.filteredArray.filter((question)=>
      new RegExp(search,"i").test(question.questionStatement)
      );
      }
      else{
        this.searchedResults=this.tableData.filter((question)=>
      new RegExp(search,"i").test(question.questionStatement)
      );
      }
        this.appliedSearch=true;
    },

    closeFilter(){
      this.filterDialog=false;
    },

    clearFilter(){
      this.selectedLevelFilter=[]
      this.selectedSetFilter=[]
      this.selectedNepLevelFilter=[]
      this.filterCount = 0,
      this.appliedFilter=false
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedLevelFilter.length > 0) {
        this.filterCount++;
      }
      if (this.selectedSetFilter.length > 0) {
        this.filterCount++;
      }
      if (this.selectedNepLevelFilter.length > 0) {
        this.filterCount++;
      }
    },

    filterQuestions(selectedLevel,selectedSetNum,selectedNepLevel){
      this.filteredArray=[];
      for(const question of this.tableData){
        let levelExists=false;
        let neplevelExists=false;
        let setNumExists=false;
        if(selectedLevel.length>0){
          for (const level of selectedLevel){
            if(question.level.includes(",")){
              let levs=question.level.split(", ")
              let checker = (arr, target) => target.every(v => arr.includes(v));
              levelExists=checker(selectedLevel,levs);
            }
            if(question.level==level){
             levelExists=true;
            }
          }
        }
        else{
          levelExists=true;
        }

        if(selectedSetNum.length>0){
          for (const set of selectedSetNum){
            if(question.setNumber==set){
              setNumExists=true
            }
          }
        }
        else{
          setNumExists=true;
        }
        if(selectedNepLevel.length>0){
          for (const nepLevel of selectedNepLevel){
            if(question.nepLevel==nepLevel){
              neplevelExists=true;
            }
          }
        }
        else{
          neplevelExists=true;
        }

        if(levelExists==true && setNumExists==true && neplevelExists==true){
          this.filteredArray.push(question)
        }
    }
      // console.log(this.tableData);
      // console.log(this.filterArray);
      this.appliedFilter=true;
      this.filterDialog=false;
    },

    getLevels(){
      this.levelOptions=[];
      const levelSet= new Set();
      for(const question of this.tableData){
        if(question.level.includes(",")){
          const splitArr=question.level.split(", ");
          splitArr.forEach((val)=>{levelSet.add(val)});
        }
        else{
          levelSet.add(question.level);
        }
      } 
      levelSet.forEach((val)=>this.levelOptions.push(val));
    },
    
    getSets(){
      this.setNumsOptions=[];
      const setNumb= new Set();
      for(const question of this.tableData){
        setNumb.add(question.setNumber)
      } 
      setNumb.forEach((val)=>this.setNumsOptions.push(val))
    },

    getNepLevel(){
      this.nepLevelOptions=[];
      const NeplevelSet= new Set();
      for(const question of this.tableData){
        NeplevelSet.add(question.nepLevel)
      } 
      NeplevelSet.forEach((val)=>this.nepLevelOptions.push(val))
    },

    async downloadFile() {
      try {
        let templateFileName = 'Hubble STAR - Question Bank Sample File.csv'
        const response = await axios.get(`https://firebasestorage.googleapis.com/v0/b/vgos-sss-stag.appspot.com/o/question_bank%2FQuestion%20Bulk%20Upload%20Template%20-%20Sheet1.csv?alt=media&token=9b80474b-f445-48e0-be23-02b8734a7ce3 `, {
          responseType: 'blob' // Important
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', templateFileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading file:', error);
      }

    },

    skippedQuestion(id, reason) {
      console.log("reason",)
      if (reason == '') {

      }
      else {
        this.removedQuestion.push("Q" + id + ". Failed" + "->reason-> " + reason)

      }

    },
    async getQuestions() {
      const responce = await PsychometryController.getQuestions();
      console.log("psy q response", responce)
      this.isLoading=false;
      this.tableData = responce.data.questions
    },
    csvFileToJSON(file) {
      var contents = [];
      try {
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            this.content = results;
            this.parsed = true;
            contents = this.content.data;
            var jsonData = [];
            var removedQuestion = [];
            var headers = contents[0];
            var blankcell = false;
            for (var i = 1; i < contents.length; i++) {
              var rowData = {};
              var options = [];
              var cells = contents[i];
              // if (blankcell) {
              //   break;
              // }
              for (var j = 0; j < cells.length; j++) {
                {
                  // if (cells[j].trim().length === 0) {
                  //   if (!removedQuestion.includes(i)) {
                  //     removedQuestion.push(i);
                  //     var text = "blank cell ";
                  //     this.skippedQuestion(i, text);
                  //   }

                  //   continue;
                  // }

                  var key = headers[j];

                  key = "" + key;

                  if (
                    headers[j].includes("questionAssetUrl") &&
                    cells[j].includes("https://drive.google.com/file/")
                  ) {
                    var found = cells[j].match(/\/d\/([A-Za-z0-9-]+)/);
                    //  url.val().match( /d\/([A-Za-z0-9\-]+)/ )
                    console.log("url data here", found);
                    if (found && found[1].length) {
                      rowData[headers[j]] =
                        "https://drive.google.com/uc?export=view&id=" +
                        found[1];
                    }
                    continue;
                  }

                  if (
                    key.includes("optionValue") &&
                    cells[j] != "NA" &&
                    cells[j] != "" &&
                    cells[j] != null &&
                    cells[j] != undefined
                  ) {
                    var optionFormate = {};
                    var f = 0;
                    while (f < 3) {
                      var tempKey
                      tempKey = headers[j];
                      tempKey = tempKey.substring(0, tempKey.length - 1);
                      optionFormate[tempKey] = cells[j].trim();
                      j++;
                      f++;
                    }
                    j--;
                    options.push(optionFormate);
                  } else {
                    rowData[key] = cells[j].trim();
                  }
                }
                if (j == cells.length - 1) {
                  rowData["questionOptions"] = options;
                }
              }
              if (i != 0) {
                if (!removedQuestion.includes(i)) {
                  jsonData.push(rowData);
                }
              }
            }
            var msg = "Question No. ";
            removedQuestion.forEach((obj) => {
              msg = msg + obj + ",";
            });
            msg = msg + " Will not upload due to black cell or Wrong Entry";
            if (removedQuestion.length != 0) {
              var msg2 = this.removedQuestion.join("\n");
              // this.downloadTextFile(msg, "uploadReport.txt");
              this.downloadTextFile(msg2, "uploadReport.txt");
            }
            // console.log("skipped reson=>",this.removedQuestion.join(" ,"))
            console.log("json data", jsonData);
            this.bulkQuestionJsonData = jsonData;
            var index = 2;

            if (!blankcell) {
              this.bulkQuestionJsonData.forEach(async (question) => {
                try {
                  const response = await PsychometryController.createQuestion(
                    question
                  );
                  if (response.status == 200) {
                    console.log(question);
                    this.bulkQuestionUploadResponse.push({
                      questionIndex: index,
                      status: "Uploaded Successfully!",
                    });
                  } else {
                    this.bulkQuestionUploadResponse.push({
                      questionIndex: index,
                      status: "Uploaded Failed!",
                    });
                  }
                  index++;
                } catch (error) {
                  console.log(error);
                }
                // console.log(this.bulkQuestionUploadResponse);
              });
            }
          }.bind(this),
        });
      } catch (e) {
        console.error(e);
      }
      //this.createQuestionsFromBulk();
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      console.log(file);
      if (file) {
        this.csvFileToJSON(file);
        this.uploadingQuestionsDialog = true;
      }
    },

    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
  },
  created() {
    this.getQuestions();
  }



};
</script>

<style scoped>
.v-list-item__content {
  min-height: 64px;
}

.qpad {
  padding-top: 80px !important;
}
</style>