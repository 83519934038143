<template>
  <div>
    <v-card height="64px" width="100%"
      class="mx-4 my-0 px-4  background  elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <!-- <v-card-title>
          Create Role <v-span><v-icon class="ml-2 ">mdi-chevron-down</v-icon></v-span>
        </v-card-title> -->
      <div class="d-flex w-100 flex-row justify-space-between">
        <div class="ma-0 pa-0">
          <span class="cursor" @click="$router.push('/roles')">Roles</span>
          <v-icon>mdi-chevron-right</v-icon>
          <span class="cursor">Create Roles</span>
          <v-icon>mdi-chevron-down</v-icon>
        </div>
        <div width="600px"></div>
      </div>

      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center" style="position: fixed; right: 0;">

        <v-badge color="red"  dot right transition="slide-x-transition" class="mr-8 cursor">
          <v-hover >
            <img @click="notificationDialog = true" src="../assets/bell 1.svg">
          </v-hover>
        </v-badge>

        <div>
          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>

    <v-container fluid class="createUserPad px-8 pb-0 background">

      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text v-if="CLoading">
              <img src="../assets/admin-loader.gif" width="85px" alt="Loading...">
            </v-card-text>
            <v-card-text class="text-center" v-else>
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>

              <p class="text-h5 py-4">NEW ROLE CREATED</p>

              <v-btn class="accent" large width="157px" rounded @click="
                successDialog = false;
              $router.push('/roles');
              ">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- <div class="d-flex w-100 flex-row justify-space-between">
        <div class="ma-0 pa-0">
          <span class="cursor" @click="$router.push('/roles')">Role</span>
          <v-icon>mdi-chevron-right</v-icon>
          <span class="cursor">Create Roles</span>
          <v-icon>mdi-chevron-down</v-icon>
        </div>
        <div width="600px"></div>
      </div> -->

      <v-row justify="space-between" class="my-0">
        <v-col cols="4" sm="4" md="4">
          <!-- <div  v-if="update" class="text-h5">update Role</div> -->
          <div class="text-h6">Create Roles</div>
        </v-col>

        <v-col cols="8" sm="8" class="d-flex flex-row justify-end">
          <v-btn v-if="$store.state.role.rolesAndPrivilage.admin" @click="$router.push('/roles')" text
            rounded>Cancel</v-btn>
          <v-btn v-if="$store.state.role.rolesAndPrivilage.admin" class="primary mx-2 btn-color" :disabled="disableSubmit"
            @click="createRole" rounded>submit</v-btn>
        </v-col>
      </v-row>
      <v-form lazy-validation ref="form">
        <v-card elevation="0" class="background">
          <v-card elevation="0" class="pa-4 rounded-lg">
            <v-text-field class="rounded-xl" v-model="roles.roleName" label="Role Name*" outlined hide-details
              :rules="[(v) => !!v || 'Name is required']" required></v-text-field>
            <div class="text-body-1 my-2">Description</div>
            <v-row>
              <v-col>
                <ckeditor :config="editorConfig" v-model="roles.roleDescription" class="m-ckeditor"
                 @ready="onEditorReady">
                </ckeditor>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-form>

      <v-card  id="myScroll" elevation="0" class="background createRoleHeight mt-2 ">
        <v-card elevation="0" class="pa-0 ma-0 pb-4 rounded-lg">
          <v-card elevation="0" height="40px" class="px-3" color="black white--text rounded-t-lg">
            <!-- headder  -->
            <v-row justify="space-between" class="my-0 mt-4 primary">
              <v-col cols="8" sm="8" md="8" class="d-flex flex-row align-center">
                <!-- <div class="text-h6">Module Name</div> -->
                <v-card-subtitle class="ma-0 pa-0 black--text text-subtitle-2">
                  Module Name
                </v-card-subtitle>
              </v-col>
              <v-col cols="4" sm="4" class="d-flex flex-row justify-center align-center">
                <!-- <div class="text-h6">actions</div> -->
                <v-card-subtitle class="ma-0 pa-0 black--text text-subtitle-2">
                  Actions
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card>
          <v-row justify="space-between" class="ma-0 mx-4 mt-5 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 pt-1 d-flex justify-center">
              <v-card-text class="ma-0 pa-0"><b>User Management</b></v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0 pb-2">
              <v-checkbox hide-details class="mr-8 mt-0" @click="
                roles.userManagement.read = roles.userManagement.admin;
              allUserManagement(roles.userManagement.admin);
              " v-model="roles.userManagement.admin" label="Admin"></v-checkbox>
              <v-checkbox @click="allUsersRead(roles.userManagement.read)" :disabled="roles.userManagement.admin"
                hide-details class="mr-8 mt-0" v-model="roles.userManagement.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-divider :thickness="3"></v-divider>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0 pt-1">Teacher Onboarding</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="
                roles.teacherOnboarding.read = roles.teacherOnboarding.admin
                " v-model="roles.teacherOnboarding.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.teacherOnboarding.admin" hide-details class="mr-8 mt-0"
                v-model="roles.teacherOnboarding.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0 pt-1">Other Admin Onboarding</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="
                roles.otherAdminOnboarding.read =
                roles.otherAdminOnboarding.admin
                " v-model="roles.otherAdminOnboarding.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.otherAdminOnboarding.admin" hide-details class="mr-8 mt-0"
                v-model="roles.otherAdminOnboarding.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0 pt-1">Roles & Privilage</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="
                roles.rolesAndPrivilage.read = roles.rolesAndPrivilage.admin
                " v-model="roles.rolesAndPrivilage.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.rolesAndPrivilage.admin" hide-details class="mr-8 mt-0"
                v-model="roles.rolesAndPrivilage.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
        </v-card>

        <v-card class="my-4 py-4 rounded-lg" elevation="0">
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pb-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 pt-1">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0"><b>Masters</b></v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="
                roles.masters.read = roles.masters.admin;
              allMasters(roles.masters.admin);
              " v-model="roles.masters.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.masters.admin" hide-details class="mr-8 mt-0"
                @click="allMastersRead(roles.masters.read)" v-model="roles.masters.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0 pt-1">Cluster</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.cluster.read = roles.cluster.admin"
                v-model="roles.cluster.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.cluster.admin" hide-details class="mr-8 mt-0" v-model="roles.cluster.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Brand</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.Brand.read = roles.Brand.admin"
                v-model="roles.Brand.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.Brand.admin" hide-details class="mr-8 mt-0" v-model="roles.Brand.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">School</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.school.read = roles.school.admin"
                v-model="roles.school.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.school.admin" hide-details class="mr-8 mt-0" v-model="roles.school.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Board</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.board.read = roles.board.admin"
                v-model="roles.board.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.board.admin" hide-details class="mr-8 mt-0" v-model="roles.board.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Level</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.Level.read = roles.Level.admin"
                v-model="roles.Level.admin" label="Admin"></v-checkbox>
              <v-checkbox hide-details :disabled="roles.Level.admin" class="mr-8 mt-0" v-model="roles.Level.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Grade</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.Grade.read = roles.Grade.admin"
                v-model="roles.Grade.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.Grade.admin" hide-details class="mr-8 mt-0" v-model="roles.Grade.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Subject Category</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.subjectCategory.read = roles.subjectCategory.admin"
                v-model="roles.subjectCategory.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.subjectCategory.admin" hide-details class="mr-8 mt-0"
                v-model="roles.subjectCategory.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Subjects</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.subjects.read = roles.subjects.admin"
                v-model="roles.subjects.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.subjects.admin" hide-details class="mr-8 mt-0" v-model="roles.subjects.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Skills</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="roles.skills.read = roles.skills.admin"
                v-model="roles.skills.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.skills.admin" hide-details class="mr-8 mt-0" v-model="roles.skills.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Learning Objective</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="
                roles.learningObjective.read = roles.learningObjective.admin
                " v-model="roles.learningObjective.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.learningObjective.admin" hide-details class="mr-8 mt-0"
                v-model="roles.learningObjective.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">League</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0">
              <v-checkbox hide-details class="mr-8 mt-0" @click="
                roles.league.read = roles.league.admin
                " v-model="roles.league.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.league.admin" hide-details class="mr-8 mt-0"
                v-model="roles.league.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
        </v-card>

        <v-card elevation="0" class="my-4 py-4 rounded-lg">
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0 pt-2">Campaign</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end ma-0 pa-0 ">
              <v-checkbox hide-details class="mr-8 mt-0 " @click="roles.campaign.read = roles.campaign.admin"
                v-model="roles.campaign.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.campaign.admin" hide-details class="mr-8 mt-0" v-model="roles.campaign.read"
                label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0 pt-4">Assessment</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end mx-0 px-0">
              <v-checkbox hide-details class="mr-8  mt-0" @click="roles.assessment.read = roles.assessment.admin"
                v-model="roles.assessment.admin" label="Admin"></v-checkbox>
              <v-checkbox :disabled="roles.assessment.admin" hide-details class="mr-8 mt-0"
                v-model="roles.assessment.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Question Bank</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end mx-0 px-0 py-0">
              <v-checkbox hide-details class="mr-8 mt-0 py-0" @click="roles.questionBank.read = roles.questionBank.admin"
                v-model="roles.questionBank.admin" label="Admin"></v-checkbox>
              <v-checkbox hide-details class="mr-8  mt-0 py-0" :disabled="roles.questionBank.admin"
                v-model="roles.questionBank.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Topics</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end mx-0 px-0 py-0">
              <v-checkbox hide-details class="mr-8 mt-0 py-0" @click="roles.topics.read = roles.topics.admin"
                v-model="roles.topics.admin" label="Admin"></v-checkbox>
              <v-checkbox hide-details class="mr-8  mt-0 py-0" :disabled="roles.topics.admin"
                v-model="roles.topics.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
          <v-row justify="space-between" class="ma-0 mx-4 mt-2 pa-0">
            <v-col cols="4" sm="4" md="4" class="ma-0 pa-0 py-0">
              <!-- <div class="text-h5">User Management</div> -->
              <v-card-text class="ma-0 pa-0">Interview</v-card-text>
            </v-col>
            <v-col cols="8" sm="8" class="d-flex flex-row justify-end mx-0 px-0 py-0">
              <v-checkbox hide-details class="mr-8 mt-0 py-0" @click="roles.interview.read = roles.interview.admin"
                v-model="roles.interview.admin" label="Admin"></v-checkbox>
              <v-checkbox hide-details class="mr-8  mt-0 py-0" :disabled="roles.interview.admin"
                v-model="roles.interview.read" label="Read"></v-checkbox>
              <!-- <v-checkbox></v-checkbox> -->
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-container>
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>
  </div>
</template>
    
    
    
<script>
import ClusterController from "@/controllers/ClusterController";
import SchoolController from "@/controllers/SchoolController";
import RolesController from "@/controllers/RolesController";
import AuthService from "../services/AuthService";
import Notification from "./Notification";

export default {
  name: "CreateRoleView",
  components: {
    Notification
  },
  data() {
    return {
      notificationDialog: false,
      clusterData: [
        {
          name: "All Clusters",
          id: "all",
        },
      ],
      schools: [
        { name: "All" }
      ],
      update: false,
      roleId: "",
      CLoading: false,
      successDialog: false,
      adminKeys: [
        "cluster",
        "Brand",
        "board",
        "school",
        "Level",
        "Grade",
        "subjectCategory",
        "subjects",
        "skills",
        "learningObjective",
        "league",
        "topics",
        "interview"
      ],
      userKeys: [
        "otherAdminOnboarding",
        "rolesAndPrivilage",
        "teacherOnboarding",
      ],
      roles: {
        roleName: "",
        dashboard: true,
        clusterName: "",
        users: [],
        schools: [],
        userManagement: {
          admin: false,
          read: false,
        },

        teacherOnboarding: {
          admin: false,
          read: false,
        },
        otherAdminOnboarding: {
          admin: false,
          read: false,
        },
        rolesAndPrivilage: {
          admin: false,
          read: false,
        },
        masters: {
          admin: false,
          read: false,
        },
        cluster: {
          admin: false,
          read: false,
        },
        Brand: {
          admin: false,
          read: false,
        },
        board: {
          admin: false,
          read: false,
        },
        school: {
          admin: false,
          read: false,
        },
        Level: {
          admin: false,
          read: false,
        },
        Grade: {
          admin: false,
          read: false,
        },
        subjectCategory: {
          admin: false,
          read: false,
        },
        subjects: {
          admin: false,
          read: false,
        },
        skills: {
          admin: false,
          read: false,
        },
        learningObjective: {
          admin: false,
          read: false,
        },
        campaign: {
          admin: false,
          read: false,
        },
        assessment: {
          admin: false,
          read: false,
        },
        questionBank: {
          admin: false,
          read: false,
        },
        league: {
          admin: false,
          read: false,
        },
        topics: {
          admin: false,
          read: false,
        },
        interview: {
          admin: false,
          read: false,
        }
      },
      editorConfig: {
        extraPlugins: "ckeditor_wiris",
      },
      editorConfigForOption: {
        extraPlugins: "ckeditor_wiris",
      },
    };
  },

  methods: {
    onEditorReady(editor) {
      editor.on("afterCommandExec", function(event) {
        var commandName = event.data.name;
        if(['ckeditor_wiris_openFormulaEditor', 'ckeditor_wiris_openFormulaEditorChemistry'].includes(commandName) ) {
          setTimeout(() => {
              const wiris_license_overlays = document.querySelectorAll('.wrs_tickContainer')
              wiris_license_overlays.forEach(element => {
                element.remove();
              });
            }, 2000)
        }
      })
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    allUserManagement(value) {
      var keys = this.userKeys;
      console.log("keys", keys)
      keys.forEach(key => {
        this.roles[key].admin = value;
        this.roles[key].read = value;
      }
      );

    },
    allUsersRead(value) {
      var keys = this.userKeys;
      console.log("keys", keys)
      keys.forEach(key => {

        this.roles[key].read = value;
      }
      );
    },
    allMasters(value) {
      var keys = this.adminKeys;
      console.log("keys", keys)
      keys.forEach(key => {
        this.roles[key].admin = value;
        this.roles[key].read = value;
      }
      );
    },
    allMastersRead(value) {
      var keys = this.adminKeys;
      console.log("keys", keys)
      keys.forEach(key => {

        this.roles[key].read = value;
      }
      );
    },

    createOrUpdateRole() {
      if (this.update) {
        this.updateRole();
      } else {
        this.createRole();
      }
    },
    moveToRole() {
      this.$router.push("/roles");
    },
    async createRole() {
      // this.$store.state.role = this.roles
      if (this.$refs.form.validate()) {
        this.CLoading = true;
        this.adminKeys.forEach((obj) => {
          if (this.roles[obj].admin == true || this.roles[obj].read == true) {
            this.roles.masters.read = true;
            this.roles.masters.admin = true;
          }
        });
        this.userKeys.forEach((data) => {
          if (this.roles[data].admin == true || this.roles[data].read == true) {
            this.roles.userManagement.read = true;
            this.roles.userManagement.admin = true;
          }
        });
        console.log("create role store", this.$store.state.role);
        const response = await RolesController.createRole(this.roles);
        if (response.data.flag) {
          this.CLoading = false;
          this.successDialog = true;
        } else {
          alert("Failed");
        }
        console.log("create role", response);
      }
    },

    async updateRole() {
      this.CLoading = true;
      // this.$store.state.role = this.roles
      console.log("create role store", this.$store.state.role);
      const response = await RolesController.updateRole(this.roles);
      if (response.data.flag) {
        this.CLoading = false;
        this.successDialog = true;
      } else {
        alert("Failed");
      }
      console.log("create role", response);
    },

    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();
      console.log("clusters response", response);
      if (response.data.flag) {
        console.log(response);

        var data = response.data.clusters;
        this.clusterData = [...this.clusterData, ...data];
      } else {
        alert(response.data.error);
      }
    },
    async getSchool() {

      if (this.roles.clusterName != 'All Clusters') {
        var temp = [this.roles.clusterName]
        this.schools = [];
        const response = await SchoolController.getClusterSchool(temp);
        console.log("school", response);
        if (response.data.flag) {
          var schools = response.data.schools;
          this.schools = ['All', ...schools];

        } else {
          alert(response.data.error);
        }
      }
      else {
        this.roles.schools = "All"
      }
    },
  },
  computed:{
        disableSubmit(){
        return  !Object.values(this.roles).some((role)=> {
          return role.admin || role.read
         })
      }},
  mounted() {
    // add event listener for beforeunload event
    if (!this.$store.state.role.rolesAndPrivilage.read) {
      this.$router.push("/notFound");
    }
    this.fetchAllClusters();
  },

  watch: {
    addAssessment() {
      console.log(this.addAssessment);
    },
  },
  created() {

    // this.update = this.$route.query.update;
    // this.roleId = this.$route.query.roleId;


    // console.log("vuex role",this.$store.state.role)
    // console.log("vuex role",this.$store.state.user)
  },
  
}
</script>