import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#B2AC88",
        secondary: "#4D695B",
        background: "#FBF5F2",
        success: "#009051",
        accent: "#864F20",
        error: "#B00020",
        progBar: "#A4B10F",
        "btn-color": "#864F20",
        goldIcons : '#FFD700',
        silverIcon :'#C0C0C0',
        bronzeIcon : '#CD7F32',
        customGreen: '#4D695B',
        textGreyColor:'#4D4D4D', 
        btnLiteColor:'#dec5af',
        green: "#06C270",
        lightGreen: "#E6F9F1",
        secondaryAccent:'#caecef',
        sufaceAccent: '#fbf5f2',
        qpColor: '#005b64',
        secondaryColor: '#82d2da',
        
       },
    },
  },
});
