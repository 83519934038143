<template>
  <div class="white w-100">
    <!-- <v-card class="red fit-content ">     -->
    <div  v-if="showGraph" class="z-index-2 w-100" ref="chart1"></div>
    <div  class="w-100 text-center data-not-ava" v-else>
      Data Not Available
    </div>
  <!-- </v-card> -->
 </div>
</template>
<script>
export default {
  props: ["graphData","message"],

  data() {
    return {
      chart: null,
      chartData: [],
      showGraph: false,
      selectedRowIndex: -1,
     

    }},
  mounted() {
    google.charts.load('current', {
      packages: ['corechart']
    });
    // google.charts.setOnLoadCallback(this.drawCharts);
  },
  watch: {
    graphData: {
      immediate: true,
      handler(newVal) {      
        this.Data = newVal;        
        this.watchMethod(newVal)        
      }
    }
  },
  methods: {
    checkvalue(){
      if(this.chartData.length > 1){
        this.chartData.forEach((obj)=>{
          if(obj[1] != 0){
            this.showGraph =  true
          }
        })
      }
      else {
        this.showGraph =  false
      }},
    watchMethod(value) {
      this.chartData = [];
      value.forEach( (val)=> {
          this.chartData.push(val)
      });
      this.checkvalue();
 
     if(this.showGraph){
      google.charts.setOnLoadCallback(this.drawCharts);
     }

    },
    drawCharts() {
      this.drawChart1();  
    },
    drawChart1() {
      const data = new google.visualization.DataTable();
      data.addColumn('string', 'Topping');
      data.addColumn('number', 'Slices');

      
      data.addRows(this.graphData);
      const options ={
        title: "Levels",
        titleTextStyle: {
          fontSize: 14, // Increase the font size of the title
          fontName: "GraphikMedium",
          bold: false, // Make the title bold if desired
          alignment: "start", // Align the title to the start (left)
          padding: { top: 0, left: 0 }, // Add padding to the top and left of the title
        },
        hAxis: null,
        vAxis: {
          minValue: 0,
          gridlines: { color: "transparent" }, // Remove background lines
          textPosition: "none", // Remove y-axis values
        },
        areaOpacity: 1, // Set the opacity of the area chart to 1
        colors: ["#64a3de", "#486178","#6ea3c4","#9fb9e3","#9fa8d1"], // Set multiple colors for the pie chart
        width: "100%",
        height: "135",

        legend: {
          position: "left",
          textStyle: {
          fontSize: 12, // Adjust the font size as desired
          margin: { top: 10 },
        }, // Set the legend position to the left side
        },
        chartArea: {
          top: 32,
          bottom: 12,
          left: 10,
          right: 0,
          width: "100%",
          height: "100%",
        },
        pieSliceTextStyle: {
          fontSize: '14px', // Adjust the font size as desired
          color: 'black',
       
        },
        // slices: {
        //   0: { textStyle: { color: "blue", bold: true } }, // Custom styling for "Assessed" label
        //   1: { textStyle: { color: "purple", italic: true } }, // Custom styling for "Un-assessed" label
        // },
      }


      this.chart = new google.visualization.PieChart(this.$refs.chart1);
      

      google.visualization.events.addListener(this.chart, 'select', this.selectHandler);
      this.chart.draw(data, options);
    },  
  
    selectHandler() {
        const selectedItem = this.chart.getSelection()[0];
        if(selectedItem == undefined){
          this.chart.setSelection([]);
           this.selectedRowIndex = -1;
           this.$emit("data-updated",null );
        }
        // this.$emit('data-updated', selectedItem.row);
        console.log("selected item", selectedItem);

// this.$emit('data-updated',this.chartData[selectedItem.row][0]);

if (selectedItem) {
  const selectedRow = selectedItem.row;
  console.log("selected row", selectedItem);
  // console.log("selected row index1",this.selectedRowIndex)

  const isSelected = selectedItem.row === this.selectedRowIndex;
  console.log("selected row index2", this.selectedRowIndex);

  console.log("is select", isSelected);

  if (isSelected) {
    this.chart.setSelection([]);
    this.selectedRowIndex = -1;
  } else {
    // this.chart.setSelection([{ row: selectedRow, column: null }]);
    this.selectedRowIndex = selectedRow;
  }

  this.$emit("data-updated",this.chartData[selectedRow][0]);
}
      }

      
 
  }
};
</script>
