import axios from 'axios'
import AuthService from '@/services/AuthService';
const instance = axios.create({
    baseURL: process.env.VUE_APP_PSYCHOMETRY_API_URL
});

export default {
    createQuestion: async function (formData) {
        //console.log("creating data",formData)
        try {
            const response = await instance.post('question', formData, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getQuestions: async function () {

        try {
            const response = await instance.get('questions', {
                headers: {
                  Authorization: AuthService.getToken(),
                },
              })
            return response;
        } catch (error) {
            return error.response;
        }
    },


   

    
}