<template>
    <GChart class="columnChart" type="ColumnChart" :data="chartData" :options="chartOptions" />
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
name: "ReportBar",
components: {
    GChart
},
props:["dataPts"],

data() {
    return {
        chartData:this.dataPts,

        // chartData: [
        //     ['Words', 'Frequency', { role: 'style' }],
        //     ['umm', 10, '#FF008A'],
        //     ['so', 20, '#FF008A'],
        //     ['well', 24, '#FF008A'],
        //     ['actually', 31, '#FF008A'],
        //     ['I mean', 21, '#FF008A'],
        //     ['hmm', 31, '#FF008A'],
        //     ['okay', 31, '#FF008A'],
        //     ['right', 16, '#FF008A'],
        // ],
        chartOptions: {
            title: '',
            hAxis: {
                title: 'Frequently Used Words',
            },
            vAxis: {
                title: 'Frequency',
            },
            legend: 'none'
        },
    }
}
}
</script>

<style scoped>
.columnChart{
height: 90% !important;
/* padding-bottom: 2rem !important; */
}
</style>


