import axios from "axios";
import AuthService from "@/services/AuthService";
const instance = axios.create({
  baseURL: process.env.VUE_APP_QUESTIONS_API_URL,
});

export default {
  createQuestion: async function (formData) {
    //console.log("creating data",formData)
    try {
      const response = await instance.post("question", formData, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  getFilteredQuestions: async function (pgNum, sortCol, sortOrder, itemsPerPage, searchQuery, questionType, level, grades, difficultyLevel, complexityLevel, knowledgeLevel, taxonomyLevel, skill, subject,reportedQuestion) {
    try {

      const res = await instance.get(`filteredQuestions?pgNum=${pgNum}&&sortCol=${sortCol}&&sortOrder=${sortOrder}&&itemsPerPage=${itemsPerPage}&&searchQuery=${searchQuery}&&questionType=${JSON.stringify(questionType)}&&level=${JSON.stringify(level)}&&grades=${JSON.stringify(grades)}&&difficultyLevel=${JSON.stringify(difficultyLevel)}&&complexityLevel=${JSON.stringify(complexityLevel)}&&knowledgeLevel=${JSON.stringify(knowledgeLevel)}&&taxonomyLevel=${JSON.stringify(taxonomyLevel)}&&skill=${JSON.stringify(skill)}&&subject=${JSON.stringify(subject)}&&reportedQuestion=${JSON.stringify(reportedQuestion)}`, {
        headers: {
          Authorization: AuthService.getToken(),
        }
      })
      //console.log("filteredQuestionRes :", res);
      return res.data
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  exportQuestions: async function (sortCol, sortOrder, searchQuery, questionType, level, grades, difficultyLevel, complexityLevel, knowledgeLevel, taxonomyLevel, skill, subject,reportedQuestion) {
    try {

      const res = await instance.get(`exportQuestions?sortCol=${sortCol}&&sortOrder=${sortOrder}&&searchQuery=${searchQuery}&&questionType=${JSON.stringify(questionType)}&&level=${JSON.stringify(level)}&&grade=${JSON.stringify(grades)}&&difficultyLevel=${JSON.stringify(difficultyLevel)}&&complexityLevel=${JSON.stringify(complexityLevel)}&&knowledgeLevel=${JSON.stringify(knowledgeLevel)}&&taxonomyLevel=${JSON.stringify(taxonomyLevel)}&&skill=${JSON.stringify(skill)}&&subject=${JSON.stringify(subject)}&&reportedQuestion=${JSON.stringify(reportedQuestion)}`, {
        headers: {
          Authorization: AuthService.getToken(),
        }
      })
     // console.log("filteredQuestionRes :", res);
      return res.data
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  updateQuestion: async function (data, id) {
    try {
      console.log("data from updata level controller", data, id);
      const response = await instance.put("question?id=" + id, data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getQuestions: async function (pageSize, page) {
    try {
      const response = await instance.get(
        "questions?pageSize=" + pageSize + "&page=" + page
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },

  deleteBulkQuestion: async function (ids) {
    try {
      const response = await instance.delete(
        "questions",
        {
          data: {
            ids: ids,
          },
        },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  doFetchFileContentFromDriveUrl: async function (id) {
    try {
      const response = await instance.get('fetch-file',
        {
          params: {
            id: id
          },
          responseType: 'blob'
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
};
