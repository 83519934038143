<template>
  <div class="pie-chart-container">

    <Pie :data="chartData" :options="chartOptions"/>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  components: {
    Pie
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        plugins: {
          legend: {
            display: false,
           
          },
        },

      })
    }
  }
};
</script>
<style scoped>
.pie-chart-container {
  width: 200px; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  margin: auto; /* Center the chart */
}
</style>
