<template>
    <GChart class="lineChart" type="LineChart" :data="chartData" :options="chartOptions" />
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
    name: "LineChart",
    components: {
        GChart
    },
    data() {
        return {
            chartData: [
                ['Time', 'WPS', { role: 'style' }],
                [10, 8, '#005B64'],
                [20, 13, '#005B64'],
                [30, 9, '#005B64'],
                [40, 10, '#005B64'],
                [50, 8, '#005B64'],
                [60, 12, '#005B64'],
            ],
            chartOptions: {
                title: '',
                hAxis: {
                    title: 'Time',
                },
                vAxis: {
                    title: 'WPS',
                },
                legend: 'none'
            },
        }
    }
}
</script>

<style scoped>
.lineChart {
    height: 90% !important;
}
</style>

