import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});
export default {
    getLeagues: async function () {
        try {
            const response = await instance.get('leagues')
            return response;
        } catch (error) {
            return error.response;
        }
    },




    updateLeagues: async function (data,id) {
               try {
            const response = await instance.put('league?id='+id, data,
            {
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },


    

   createLeague : async function (data) {
        try {
            const response = await instance.post('leagues',
                data
                ,{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },



    deleteLeague : async function (id) {
        console.log("delete selected id",id)
        try {
            const response = await instance.delete('leagues',
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteBulkLeagues : async function (ids) {
        console.log("delete selected id",ids)
        try {
            const response = await instance.delete('leagues',{
                data:{
                    ids:ids
                }
            },
             { headers: {
                'Authorization': AuthService.getToken()
              }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

 


}