<template>
    <GChart class="lineChart" type="LineChart" :data="chartData" :options="chartOptions" />
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
    name: "LineChart",
    components: {
        GChart
    },
    data() {
        return {
            chartData: [
                ['Time', 'Person', { role: 'style' }],
                [10, 1, '#005B64'],
                [20, 1, '#005B64'],
                [30, 1, '#005B64'],
                [40, 1, '#005B64'],
                [50, 1, '#005B64'],
                [60, 1, '#005B64'],


            ],
            chartOptions: {
                title: '',
                hAxis: {
                    title: 'Time',
                },
                vAxis: {
                    title: 'Person',
                },
                legend: 'none'
            },
        }
    }
}
</script>

<style scoped>
.lineChart {
    height: 100% !important;
    padding-bottom: 1.25rem !important;
}
</style>

