<template>
    <div class="w-100 d-flex flex-row">

        <div class="cgGraph bg-white">
            <div class="w-100 d-flex flex-row justify-space-between">

                <h3>Cumulative Graph</h3>

                <div class="filterGraph">
                    <v-select v-model="selectCumGraph" :items="items" dense outlined></v-select>
                </div>

            </div>

            <canvas :class="{ chartVis: selectCumGraph === 'Facial Expressions' }" class="radarChart"
                id="mySensitiveChart"></canvas>
            <canvas :class="{ chartVis: selectCumGraph === 'Sensitive Contents' }" class="radarChart" id="myChart"></canvas>
           

            <!-- <canvas v-if="selectCumGraph === 'Facial Expressions'" class="radarChart" id="myChart"></canvas>
            <canvas v-if="selectCumGraph === 'Sensitive Contents'" class="radarChart" id="mySensitiveChart"></canvas> -->

            <!-- <CumulativeGraph v-if="selectCumGraph === 'Facial Expressions'" :labels="label" :dataPts="cumulativeData" />
            <CumulativeGraph v-if="selectCumGraph === 'Sensitive Contents'" :labels="sensitiveLabel"
                :dataPts="sensitiveCumulativeData" /> -->
        </div>

        <div class="btChart bg-white">
            <div class="w-100 d-flex flex-row justify-space-between">
                <h3>Bubble Timeline Chart</h3>
                <div class="filterGraph">
                    <v-select v-model="selectBubGraph" :items="items" dense outlined></v-select>
                </div>
            </div>

            <BubbleChart :class="{ chartVis: selectBubGraph === 'Facial Expressions' }" class="bubbleChart"
                :dataArr="sensitiveBubbleData" />
            <BubbleChart :class="{ chartVis: selectBubGraph === 'Sensitive Contents' }" class="bubbleChart"
                :dataArr="bubbleData" />
     

            <!-- <BubbleChart v-if="selectBubGraph === 'Facial Expressions'" :dataArr="bubbleData" />
            <BubbleChart v-if="selectBubGraph === 'Sensitive Contents'" :dataArr="sensitiveBubbleData" /> -->

        </div>

    </div>
</template>

<script>
// import CumulativeGraph from "./DemoGraphs/CumulativeGraph.vue";
import BubbleChart from "./DemoGraphs/BubbleChart.vue"

export default {
    components: {
        // CumulativeGraph,
        BubbleChart
    },
    props: ["avgEmotions"],
    data() {
        return {
            selectCumGraph: 'Facial Expressions',
            selectBubGraph: 'Facial Expressions',
            items: ['Facial Expressions', 'Sensitive Contents'],
            label: ['Joy', 'Sorrow', 'Anger', 'Surprise', 'Under Exposed', 'Blurred', 'Headwear'],
            sensitiveLabel: ['Adult', 'Racy', 'Violence', 'Medical', 'Spoof'],
            resultIndex: { "UNKNOWN": 0, "VERY_UNLIKELY": 1, "UNLIKELY": 2, "POSSIBLE": 3, "LIKELY": 4, "VERY_LIKELY": 5 },
            yaxisLabel: { "Joy": 1, "Blurred": 2, "Surprise": 3, "Headwear": 4, "Sorrow": 5, "Anger": 6, "Under Exposed": 7 },
            sensitiveYaxisLabel: { "Adult": 1, "Racy": 2, "Violence": 3, "Medical": 4, "Spoof": 5 },

            cumulativeData: [],
            fillCumulativeData: [],
            sensitiveCumulativeData: [],
            fillSensitiveCumulativeData: [],
            bubbleData: [],
            sensitiveBubbleData: [],
            videoDetails: {},
        }
    },
    async mounted() {
        this.cumulativeData = new Array(this.label.length + 1).fill(0)
        this.fillCumulativeData = new Array(this.label.length + 7).fill(1)
        this.sensitiveCumulativeData = new Array(this.sensitiveLabel.length + 1).fill(0)
        this.fillSensitiveCumulativeData = new Array(this.sensitiveLabel.length + 5).fill(1)

        this.getVideoDetails();
        await this.getAnalysisReport();
        this.getCumGraph();
        this.getSensitiveCumGraph();
    },
    methods: {
        getVideoDetails() {
            this.videoDetails = this.$store.state.selectedVideo ? this.$store.state.selectedVideo : {}

            if (Object.keys(this.videoDetails).length === 0) {
                const videoData = localStorage.getItem("videoInfo")
                const jsonObj = JSON.parse(videoData);
                this.$store.commit("setVideoDetails", jsonObj)
                this.videoDetails = jsonObj
            }
            console.log("video Details :", this.videoDetails)

            const avgEmotions = this.videoDetails.avgEmotions ? this.videoDetails.avgEmotions : {}


            // console.log("avgEmotions :", avgEmotions)

            for (let i = 0; i < this.label.length; i++) {
                const lb = this.label[i]
                const r = avgEmotions[lb];

                // console.log("lb :", lb, "r :", r)

                if (r in this.resultIndex) {

                    this.cumulativeData[i] = this.resultIndex[r];
                    this.fillCumulativeData[i] = this.resultIndex[r];
                }
            }

            this.cumulativeData[this.label.length] = this.cumulativeData[0]

            console.log("cumulativeData :", this.cumulativeData)



            for (let i = 0; i < this.sensitiveLabel.length; i++) {
                const lb = this.sensitiveLabel[i]
                const r = avgEmotions[lb];

                if (r in this.resultIndex) {
                    this.sensitiveCumulativeData[i] = this.resultIndex[r];
                    this.fillSensitiveCumulativeData[i] = this.resultIndex[r];

                }
            }

            console.log("this_sensitive_cumulative :", this.sensitiveCumulativeData)
            console.log("this_fill_sensitive_cumulative_data :", this.fillSensitiveCumulativeData)

            this.sensitiveCumulativeData[this.sensitiveLabel.length] = this.sensitiveCumulativeData[0]

        },

        getAnalysisReport() {
            this.videoDetails = this.$store.state.selectedVideo ? this.$store.state.selectedVideo : {}

            if (Object.keys(this.videoDetails).length === 0) {
                const videoData = localStorage.getItem("videoInfo")
                const jsonObj = JSON.parse(videoData);
                this.$store.commit("setVideoDetails", jsonObj)
                this.videoDetails = jsonObj
            }

            const analysisReport = this.videoDetails.analysisReport ? this.videoDetails.analysisReport : {}

            // this.bubbleData.splice(0)
            // this.sensitiveBubblleData.splice(0)

            this.bubbleData.push(['ID', 'Time', 'Index', 'Emotion', 'Likely'])
            this.sensitiveBubbleData.push(['ID', 'Time', 'Index', 'Emotion', 'Likely'])

            for (let i in analysisReport) {

                for (let j in analysisReport[i]) {
                    if (j in this.yaxisLabel) {
                        this.bubbleData.push(['', parseInt(i), this.yaxisLabel[j], j, this.resultIndex[analysisReport[i][j]]])
                    }
                    else if (j in this.sensitiveYaxisLabel) {
                        this.sensitiveBubbleData.push(['', parseInt(i), this.sensitiveYaxisLabel[j], j, this.resultIndex[analysisReport[i][j]]])
                    }
                }
            }
        },

        getCumGraph() {
            const ctx = document.getElementById('myChart');

            new Chart(ctx, {
                type: 'radar',
                data: {
                    labels: this.label,
                    datasets: [{
                        label: 'Cumulative Data',
                        data: this.cumulativeData,
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 0.6)',
                        pointBackgroundColor: 'rgb(255, 99, 132)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'
                    },
                    {
                        label: '',
                        data: this.fillCumulativeData,
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0)',
                        borderColor: 'rgba(255, 99, 132, 0)',
                        pointBackgroundColor: 'rgba(255, 99, 132, 0)',
                        pointBorderColor: 'rgba(54, 162, 235, 0)',
                        pointHoverBackgroundColor: 'rgba(54, 162, 235, 0)',
                        pointHoverBorderColor: 'rgba(54, 162, 235, 0)',

                    },
                    {
                        label: '',
                        data: [0, 1, 2, 3, 4, 5],
                        fill: true,
                        backgroundColor: 'rgba(54, 162, 235, 0)',
                        borderColor: 'rgba(54, 162, 235, 0)',
                        pointBackgroundColor: 'rgba(54, 162, 235, 0)',
                        pointBorderColor: 'rgba(54, 162, 235, 0)',
                        pointHoverBackgroundColor: 'rgba(54, 162, 235, 0)',
                        pointHoverBorderColor: 'rgba(54, 162, 235, 0)'
                    }
                    ]
                },

                options: {
                    plugins: {
                        legend: {
                            display: false // Hide the default legend
                        },
                        tooltip: {
                            enabled: false // Disable the tooltip (hovering effect)
                        }
                    },
                    scales: {
                        r: {
                            ticks: {
                                min: -1,
                                max: 5,
                                stepSize: 1,

                                callback: function (value, index, values) {
                                    // Define your string values
                                    const stringValues = ["Unknown", "Very Unlikely", "Unlikely", "Possible", "Likely", "Very Likely"];
                                    return stringValues[value];
                                }
                            }
                        }
                    },

                }
            });
        },

        getSensitiveCumGraph() {
            const ctx = document.getElementById('mySensitiveChart');

            new Chart(ctx, {
                type: 'radar',
                data: {
                    labels: this.sensitiveLabel,
                    datasets: [{
                        label: 'Cumulative Data',
                        data: this.sensitiveCumulativeData,
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 0.6)',
                        pointBackgroundColor: 'rgb(255, 99, 132)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'
                    },
                    {
                        label: '',
                        data: this.fillSensitiveCumulativeData,
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0)',
                        borderColor: 'rgba(255, 99, 132, 0)',
                        pointBackgroundColor: 'rgba(255, 99, 132, 0)',
                        pointBorderColor: 'rgba(54, 162, 235, 0)',
                        pointHoverBackgroundColor: 'rgba(54, 162, 235, 0)',
                        pointHoverBorderColor: 'rgba(54, 162, 235, 0)',

                    },
                    {
                        label: '',
                        data: [0, 1, 2, 3, 4, 5],
                        fill: true,
                        backgroundColor: 'rgba(54, 162, 235, 0)',
                        borderColor: 'rgba(54, 162, 235, 0)',
                        pointBackgroundColor: 'rgba(54, 162, 235, 0)',
                        pointBorderColor: 'rgba(54, 162, 235, 0)',
                        pointHoverBackgroundColor: 'rgba(54, 162, 235, 0)',
                        pointHoverBorderColor: 'rgba(54, 162, 235, 0)'
                    }
                    ]
                },


                options: {
                    plugins: {
                        legend: {
                            display: false // Hide the default legend
                        },
                        tooltip: {
                            enabled: false // Disable the tooltip (hovering effect)
                        }
                    },
                    scales: {
                        r: {
                            ticks: {
                                min: -1,
                                max: 5,
                                stepSize: 1,

                                callback: function (value, index, values) {
                                    // Define your string values
                                    const stringValues = ["Unknown", "Very Unlikely", "Unlikely", "Possible", "Likely", "Very Likely"];
                                    return stringValues[value];
                                }
                            }
                        }
                    }
                }
            });
        },

    }
}

</script>

<style scoped>
.cgGraph {
    width: 45%;
    height: 58vh;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.btChart {
    width: 55%;
    height: 58vh;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0.5rem;
    border-radius: 0.5rem;
    position: relative;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.filterGraph {
    width: 12.5rem;
}

.radarChart {
    padding-bottom: 2.5rem;
    /* padding-left: 2rem; */
    padding-right: 2rem;
    padding-top: 1rem;
    /* margin-left: 4rem; */
    /* width: 100% !important; */
    height: 85% !important;
    position: absolute;
    top: 60px;
    left: 1.5rem;
}

.chartVis {
    opacity: 0;
}

.bubbleChart {
    position: absolute;
    width: 98%;
    top: 60px;
    left: 0;
}
</style>