<template>
    <GChart type="PieChart" class="pieChart" :data="chartData" :options="chartOptions"></GChart>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
    name: "PieChart",
    components: {
        GChart
    },
    props: ["pieData"],
    data() {
        return {
            chartData: this.pieData,
            // chartData: [
            //     ["Type", "Percentage"],
            //     ['Correct', 80],
            //     ['Incorrect', 20],
            // ],

            chartOptions: {
                title: 'Answering pattern of other users',
                colors: ["#BBD5F9", "#E7CCF6"],
                titleTextStyle: {
                    fontSize: 12
                }
            },
        }
    }
}
</script>

<style scoped>
.pieChart {
    height: 12.5rem;
    padding-bottom: 0.5rem;
}
</style>