<template>
  <div v-if="preloader">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div> 
  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position: fixed; z-index: 200; top: 0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text underline-on-hover cursor" @click="$router.push('/static-assessment')">Assessments</p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
        >mdi-chevron-right
        </v-icon>
        <p class="breadcrumb-text underline-on-hover cursor" @click="backToCampaignUserListingPage">{{campaignName}}</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
        <p class="breadcrumb-text">Test Report</p>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <div>
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32"/>
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad px-8 background">
      <div class="d-flex align-center mb-8">
        <h3 class="mr-auto">{{ userName }}: Mesta Result</h3>
      </div>
      
      <div class="pa-4 bg-white">
        <div class="total-score-container">
          <div class="d-flex justify-space-between">
            <h4 class="mb-2">Total Score - {{displayedselectedSkill.overallScore.totalSecuredScore}} / {{displayedselectedSkill.overallScore.totalScore}}</h4>
            <!-- <span>Rank #13</span> -->
          </div>
          <div class="result-details d-flex">
            <div class="d-flex">
              <span class="mr-1">Grade - </span>
              <span class="font-weight-bold">{{ this.grade}}</span>
            </div>
            <div class="d-flex">
              <span class="mr-1">Language - </span>
              <span class="font-weight-bold">{{this.prefferedLanguage}}</span>
            </div>
            <div class="d-flex">
              <span class="mr-1">Time taken by this user - </span>
              <span class="font-weight-bold">{{ this.timeTakenByUser}} mins</span>
            </div>
            <!-- <div class="d-flex">
              <span class="mr-1">Average time taken by others - </span>
              <span class="font-weight-bold">30 mins</span>
            </div> -->
          </div>
        </div>
        <v-container fluid>
          <v-row>
            <v-col class="px-0" cols="3">
              <aside>
                <v-card
                  class="scroll-view core-skills-container"
                  height="calc(100vh - 312px)"
                  id="myScroll"
                  outlined
                  elevation="0"
                  style="position: sticky; top: 300px"
                >
                <v-btn-toggle v-model="selectedSubjectName" @change="getQuestionsForDisplay" mandatory class="d-flex flex-column pa-2">           
                  <template v-for="(subject, index) in displayedselectedSkill?.subjectsScore">
                    <v-btn  
                      depressed
                      outlined
                      class="rounded-xl pa-2 my-1 tab-btn" 
                      :key="index" :value="subject.subject" 
                      >
                      <div class="d-flex flex-row w-100 justify-space-between align-center">
                        <div>
                          {{ subject.subject }}
                        </div>
                        <div>
                          <div class="text-body-2 text--secondary">
                            Score
                          </div>
                          <div class="text-left text-body-2 font-weight-bold text--secondary">
                            {{subject.securedScore }}/ {{ subject?.totalSubjectScore }}
                          </div>
                        </div>
                      </div>
                      </v-btn>           
                  </template>
                </v-btn-toggle>
                </v-card>
              </aside>
            </v-col>
            <v-col cols="9">
              <div class="pa-3 test-heading">
                <h4>{{selectedSubjectName}}</h4>
              </div>
              <v-card
                class="scroll-view"
                height="calc(100vh - 312px)"
                id="myScroll"
                elevation="0"
              >
                <v-list>
                  <v-list-item
                    v-for="(item, index) in questionsToDisplay"
                    :key="index"
                  >
                  <div class="w-100 d-flex flex-column">
                    <div class="d-flex flex-row">
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-row font-weight-bold">
                          <div>Q.{{ item.questionNumber }}</div>
                          <div class="pl-4" v-html="item[prefferedLanguage.toLowerCase()]?.questionStatement"></div>
                        </div>
                        <div class="my-4" v-if="item[prefferedLanguage.toLowerCase()]?.questionAssetUrl != 'NA'">
                          <v-img :width="100" :src="item[prefferedLanguage.toLowerCase()]?.questionAssetUrl"/>
                        </div>
                        <div class="pl-8 pt-2 my-1 ml-2">
                           <div class="mb-3 language-chips">
                            <v-chip
                            v-if="item.difficultyLevel != 'NA'"
                            small text-color="grey darken-2"
                            class="mr-2" outlined>
                              {{ item.difficultyLevel }}
                            </v-chip>
                            <v-chip
                              v-if="item.knowledgeLevel && item.knowledgeLevel != 'NA'"
                              small text-color="grey darken-2"
                              class="mr-2" outlined>
                              {{ item.knowledgeLevel }}
                            </v-chip>
                            <v-chip v-if="level != 'NA'" small class="mr-2" text-color="grey darken-2" outlined>
                              {{level}}
                            </v-chip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex flex-column w-100 ml-1 mb-4">
                      <div class="pl-9">
                        <div v-if="!imageInOption(item[prefferedLanguage?.toLowerCase()]?.questionOptions)"
                        v-for="(option, optionIndex) in item[prefferedLanguage?.toLowerCase()]?.questionOptions" :key="optionIndex" 
                        :class="item.correctAnswer?.includes(option.optionKey)? 'correct-option d-flex':' other-option d-flex'">
                          <div class="mr-1">{{option.optionKey}}.</div>
                          <div v-html="option.optionValue"></div>
                        </div>
                        <div class="d-flex flex-row" v-if="imageInOption(item[prefferedLanguage?.toLowerCase()]?.questionOptions)">
                          <div
                          v-for="(option, i) in item[prefferedLanguage?.toLowerCase()]?.questionOptions"
                          :key="i" class="ma-4">
                          <v-img :width="100" :src="option?.optionValue"/>
                        </div>
                        </div>
                      </div>
                      <div class="px-0 pl-9 w-100 my-4">
                        <v-divider></v-divider>
                      </div>
                      <div class="d-flex pl-9 questions-info-container">
                        <div>
                          Response Analytics -

                          <span v-if="!item.myAnswer" class="mr-6"
                            >Not Attempted</span
                          >

                          <span v-else class="mr-6">Attempted</span>
                        </div>

                        <div>Answered -</div>

                        <div v-if="!item.myAnswer" class="mr-6 skipped-answer">Skipped</div>
                        <div
                          class="mr-6"
                          v-else
                          :class="
                            item.isAnswerCorrect ? 'pl-1 correct-answer' : 'pl-1 incorrect-answer'
                          "
                        >
                          {{
                            item.isAnswerCorrect
                              ? item.myAnswer + " (Correct)"
                              : item.myAnswer + " (Incorrect)"
                          }}
                        </div>
                      </div>
                      
                      <div class="my-3 customDivider"></div>
                    </div>
                  </div>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
    import AuthService from "../services/AuthService";
    import StaticAssessmentController from "@/controllers/StaticAssessmentController";
    import AssessmentController from "@/controllers/AssessmentController";
  
    export default {
        name: "UserTestReport",
        data() {
            return {
              preloader: true,
              currentSelectedLanguage: null,
              selectedOptions: [],
              availableQuesLanguages: ['Easy','P1','Pre-Primary','Foundational','Musk Know'],
              timeTakenByUser:'0',
              prefferedLanguage:'',
              userName:'',
              selectedSkill: {
                questions: [
                  
                ],
                overallScore:{},
                subjectsScore:[]
              },
              selectedSubjectName: "",
              questionsToDisplay: [],
              studentId: "",
              level: "",
              grade: "",
              campaignId: "",
              campaignName: "",
              campaignType: "",
              assessmentId: "",
          }
        },
        mounted() {
        },
        created(){
          this.studentId = this.$route.query.studentId;
          this.grade = this.$route.query.grade;
          this.campaignId = this.$route.query.campaignId;
          this.campaignName = this.$route.query.campaignName;
          this.campaignType =  this.$route.query.campaignType;
          this.assessmentId = this.$route.query.assessmentId;
          this.pageSize = 10;
          this.page = 1;
          this.fetchUserReport(this.studentId, this.campaignId, this.grade, this.assessmentId);
        },
        computed: {
          displayedselectedSkill() {
            console.log("selectedskills===>", this.selectedSkill);
            return this.selectedSkill;
          }
        },
        methods: {
            logout() {
                AuthService.logout();
                this.$router.push("/login");
            },
            async fetchUserReport(studentId, campaignId, grade, assessmentId) {
                this.isLoading = true;
                const response = await StaticAssessmentController.getUserTestReport(studentId, campaignId, grade, assessmentId);
                if(response.status == 200) {
                  const data =  response.data.data;
                  console.log("data ==> ", data)
                    this.selectedSkill.questions = data.questionResponse??[];
                    this.selectedSkill.questions = this.selectedSkill.questions.map(({questionId, ...rest})=>{
                      return {
                          questionNumber: questionId.split("_Q")[1],
                          ...rest
                        }
                    })
                    this.prefferedLanguage = Array.isArray(data.prefferedLanguage)? data.prefferedLanguage[0]: data.prefferedLanguage ?? '';

                    let questionPromises = this.selectedSkill.questions.map(async question => {
                      let questionOfSpecificLanguage = question[this.prefferedLanguage.toLowerCase()];
                      question.isAnswerCorrect = this.isCorrectResponse(question?.myAnswer, question?.correctAnswer);

                      if(questionOfSpecificLanguage.questionAssetUrl?.startsWith('https://drive.google.com/')){
                        questionOfSpecificLanguage.questionAssetUrl = await this.resolveAssetURL(questionOfSpecificLanguage.questionAssetUrl);
                      }
                      
                      let optionsPromises = questionOfSpecificLanguage?.questionOptions.map(async option=>{
                        if(option.optionValue?.startsWith('https://drive.google.com/')){
                          option.optionValue = await this.resolveAssetURL(option.optionValue);
                        }
                      })
                      await Promise.all(optionsPromises);
                    })
                    await Promise.all(questionPromises);

                    this.selectedSubjectName = data.subjectsScore[0].subject;
                    this.questionsToDisplay = this.selectedSkill.questions.filter(question=>question.subject.includes(this.selectedSubjectName))
                    this.userName = `${data.firstName} ${data.lastName}`                    
                    this.selectedSkill.overallScore = data.overallScore;
                    this.selectedSkill.subjectsScore = data.subjectsScore;
                    this.timeTakenByUser = data.timeTakenByUser??'';
                    this.level = data.level;
                    // this.newQuestionCount =  response.data.assessments?.[0]?.questions?.length;
                    console.log("this.timeTakenByUser",this.timeTakenByUser);
                    this.preloader = false;
                    this.isLoading = false;
                } else {
                    this.preloader = false;
                    this.isLoading = false;
                }
              },
              async resolveAssetURL(url){
                  let fileId = null
                  if(url.indexOf('&id=') > -1) {
                    fileId = url.split('&id=').pop()
                  } else {
                    // Regular expression pattern to extract file ID
                    const pattern = /\/file\/d\/([^\\/]+)\//;
                    fileId = url.match(pattern)[1];
                  }
                  const response = await AssessmentController.doFetchFileContentFromDriveUrl(fileId);
                  if(response.status === 200) {
                    return window.URL.createObjectURL(new Blob([response.data]));
                  }
              },
              imageInOption(questionOptions){
                return questionOptions.some(option=>(option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')));
              },
              getQuestionsForDisplay(){
                this.questionsToDisplay = this.selectedSkill.questions.filter(question=>question.subject.includes(this.selectedSubjectName))
              },
              isCorrectResponse(myAnswer, correctAns) {
                if (myAnswer && correctAns) {
                  correctAns = correctAns.split(',').filter(answer => /^[A-Ea-e]$/.test(answer)).join(',');
                  var sortedStr = correctAns.split(",").sort().join("");
                  myAnswer.sort();
                  return myAnswer.join("") === sortedStr ? true : false;
                } else {
                  return false;
                }
              },
              selectChip(chip) {
                this.currentSelectedLanguage = chip; // Set the selected language
              },
              backToCampaignUserListingPage(){
                this.$router.push({
                  path: "/mestacampaignuser",
                  name: "mestaCampaignUser",
                  query: {
                    id: this.campaignId,
                    campaignName: this.campaignName,
                    campaignType: this.campaignType,
                  },
                });
              }
        }
        } 
</script>
<style scoped>
  .total-score-container {
    border: 1px solid #000;
    padding: 12px 24px;
    border-radius: 8px;
  }
  .result-details {
    gap: 16px;
  }
  .language-chips {
    display: flex;
    gap: 8px;
    .v-chip.v-chip--outlined.v-chip.v-chip {
      background-color: #e0e0e0 !important;
    }
  }
  .language-chip {
    margin: 4px;
    padding: 4px 8px;
    font-size: 0.875rem;
  }
  .questions-other-info {
    gap: 12px;
  }
  .questions-info-container {
    span {
      font-size: 14px;
    }
  }
  .option-text {
    font-size: 14px;
  }
  .test-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D8D5C3;
    margin-bottom: 16px;
  }
  .tab-btn {
    font-size: 14px;
    border-color: inherit !important;
    width: 100% !important;
    height : auto !important;
    padding: 10px !important;
    border-left-width: thin !important;
  }
  .correct-option {
    color: #06C270;
    padding-right: 12px;
    margin-top: 4px;
  }
  
  .other-option {
    padding-right: 12px;
    margin-top: 4px;
  }
  
  .correct-answer {
    font-size: 14px;
    color: #06C270;
    padding-left: 4px;
  }
  
  .incorrect-answer {
    font-size: 14px;
    color: #EE4D37;
    padding-left: 4px;
  }
  .skipped-answer{
    font-size: 14px;
    color: #F08D32;
    padding-left: 4px;
  }
</style>