<template>
  <v-dialog v-model="addTeacherDialog" class="cdz" persistent max-width="80vw">
    <v-btn :disabled="sendingInvitesLoading" class="close-dialog-btn" icon large @click="closeDialog">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <section class="bg-white pa-4">
      <v-row>
        <v-col cols="6">
          <v-card-title class="font-weight-bold pl-0">
            {{ campaignName }} | Select Audience
          </v-card-title>
          <v-card-subtitle class="pl-0">
            ({{ audiances.length }}) Teachers Are Eligible For This Campaign
          </v-card-subtitle>
        </v-col>
        <v-col cols="6" class="d-flex justify-end align-center">
          <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="searchAudeience"
            hide-details class="search-bar border-button rounded-pill mx-1" dense clearable></v-text-field>
          <section>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn v-on="on" :loading="sendingInvitesLoading" v-show="selectedUsers.length > 1" rounded
                  class="accent" @click="sendInvites(selectedUsers)">
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              <span>Invite {{ selectedUsers.length }} Teachers</span>
            </v-tooltip>

          </section>
        </v-col>
      </v-row>

      <v-data-table disable-sort v-if="addTeacherDialog" :loading="isLoading" v-model="selectedUsers" :headers="headers"
        :items="filteredAudiences" show-select :single-select="singleSelect" item-key="userId"
        :items-per-page="itemsPerPage" :footer-props="{
          'items-per-page-options': [5, 50, 500, -1]
        }">
        <template v-slot:[`item.fullname`]="{ item }">
          {{
            item.personalInfo.firstName +
            " " +
            item.personalInfo.lastName
          }}
        </template>

        <template v-slot:[`item.levels`]="{ item }">
          <span v-if="item.responsibleTeachingAt">
            {{ [...new Set(item.responsibleTeachingAt.map(t => t.level))].join(", ") }}
          </span>
        </template>

        <template v-slot:[`item.subjects`]="{ item }">
          <span v-if="item.responsibleTeachingAt">
            {{ item.responsibleTeachingAt.map(t => t.subject).join(", ") }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn v-on="on" @click="sendInvites([item])" small outlined
                  class="text-capitalize rounded-xl btn-color white--text">Invite</v-btn>
              </template>
              <span>Invite</span>
            </v-tooltip>


          </div>
        </template>
      </v-data-table>
    </section>
    <v-snackbar v-model="sendInvitesSuccessSnackbar" :timeout="1000">
      Campaign Audience Updated . Please wait while the changes are reflected..
    </v-snackbar>
  </v-dialog>
</template>
<script>
import CampaignController from "@/controllers/CampaignController";
export default {
  name: "AddTeachersToCampaign",
  props: {
    addTeacherDialog: {
      type: Boolean,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
    campaignName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localAddTeacherDialog: this.addTeacherDialog,
      selectedUsers: [], // Array to hold selected user IDs
      headers: [
        { text: "Name", value: "fullname" },
        { text: "Phone No", value: "personalInfo.contactInfo.phoneNumber" },
        { text: "Email", value: "personalInfo.contactInfo.email" },
        { text: "Level", value: "levels" },
        { text: "Subjects", value: "subjects" },
        { text: "School", value: "schoolName" },
        { text: "Acton", value: "actions" },
      ],
      isLoading: true,
      audiances: [],
      totalCount: 0,
      teachingSubjects: {},
      searchAudeience: "",
      singleSelect: false,
      options: {},
      sendingInvitesLoading: false,
      sendInvitesSuccessSnackbar: "",
      itemsPerPage: 5
    };
  },
  methods: {
    async sendInvites(selected) {
      // Logic to send invites to selected users
      selected = selected.map((item) => {
        return { ...item, emails: [item.personalInfo.contactInfo.email], updatedOn: new Date(Date.now()).toISOString() }
      })
      this.sendingInvitesLoading = true
      this.sendInvitesSuccessSnackbar = false;
      const response = await CampaignController.createCampaignAudiance(
        selected,
        this.campaignId,
        true
      );

      if (response.data.flag) {
        this.sendingInvitesLoading = false;
        this.sendInvitesSuccessSnackbar = true;
        this.selectedUsers = [];
        this.load();
      } else {
        this.sendingInvitesLoading = false;
        alert(response.data.error);
      }
    },
    async load() {
      try {
        console.log('loading...')
        // const { page, itemsPerPage } = this.options
        this.isLoading = true;
        this.audiances = [];
        const response = await CampaignController.getEligibleTeachers(this.campaignId);
        this.audiances = response.data?.users ?? [];
        this.responsibleTeachingAt = {};
        this.teachingSubjects = {};
        for (const user of this.audiances) {
          const lvl = [];
          const sub = [];
          for (const res of user.responsibleTeachingAt) {
            if (!lvl.includes(res.level)) lvl.push(res.level);
            if (!sub.includes(res.subject)) sub.push(res.subject);
          }
          this.responsibleTeachingAt[user.userId] = lvl;
          this.teachingSubjects[user.userId] = sub;
        }
        this.isLoading = false
      } catch (error) {
        console.error('Error loading audiances:', error);
      }
    },
    closeDialog() {
      this.addTeacherDialog = false;
      this.audiance = [];
      this.selectedUsers = [];
    }
  },
  computed: {
    filteredAudiences() {
      return this.audiances.filter(audience => {
        const fullName = audience.personalInfo.firstName + " " + audience.personalInfo.lastName;
        return fullName.toLowerCase().includes(this.searchAudeience.toLowerCase()) || audience.personalInfo.contactInfo.email.toLowerCase().includes(this.searchAudeience.toLowerCase());
      });
    }
  },
  watch: {
    addTeacherDialog(val) {
      this.localAddTeacherDialog = val;
      if (val) {
        this.load();
      }
    },
    localAddTeacherDialog(val) {
      this.$emit('update:addTeacherDialog', val);
    }
  },
  created() {
    this.load();
  }
}
</script>
<style scoped>
.close-dialog-btn {
  position: absolute;
  right: 10vw;
}
</style>