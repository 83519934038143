import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_NOTIFICATION_API_URL,
});
export default {


   messageToHR: async function (message,text,email,subject
   
   ) {
     try {
       const response = await instance.post(
         "/sendEmail",
         {
           email:email,
           emailBody:{
               subject:subject,
               text:text,
               body:message
           }
       },
         
       );
       return response;
     } catch (error) {
       console.log(error.response);
   
       return error.response;
     }
   },
}