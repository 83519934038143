<template>
  <div>
    <v-col>
      <v-card height="64px" width="100%"
        class="ml-4 pl-1 pt-5 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <v-span @click="$router.push('/')"
            class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</v-span>

          <v-span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></v-span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <v-span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
              </v-span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/schoolteachersappraisal')">No. of teachers to be Appraised</v-list-item>
              <v-list-item @click="$router.push('/schoolteacherspip')">No. of teachers on PIP</v-list-item>
              <v-list-item @click="$router.push('/schoolteacherstbd')">No. of teachers on TBD</v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <div class="size-menu d-flex flex-row justify-start w-fit align-center fixLog">
          <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          </v-badge>
          <div>
            <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="24" height="24" />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4">

          <v-container class="funnel-contianer pr-6 ml-1" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh">
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      TEACHERS
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Registered/Invited</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="invited" propertyEnd="mainsStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.invited -
                              funnelTeachersData.mainsStarted,
                            funnelTeachersData.invited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <!-- <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.screeningStarted -
                              funnelTeachersData.screeningPassed,
                            funnelTeachersData.screeningStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.screeningPassed -
                              funnelTeachersData.mainsStarted,
                            funnelTeachersData.screeningPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div> -->
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      MAINS
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.mainsStarted -
                              funnelTeachersData.mainsPassed,
                            funnelTeachersData.mainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="mainsPassed"/>
                    <!-- <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.mainsPassed -
                              funnelTeachersData.demoSubmitted,
                            funnelTeachersData.mainsPassed
                          )
                        }}%
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </div>
              <!-- <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      DEMO
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Submitted</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="demoSubmitted" propertyEnd="interviewSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.demoSubmitted -
                              funnelTeachersData.interviewSubmitted,
                            funnelTeachersData.demoSubmitted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div> -->
              <!-- <div class="rounded-lg px-0 py-0 pl-1 w-19">
                <v-card class="cardBorder rounded-lg pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                    <p class="font-weight-bold pl-2 funnelText">CAMPAIGN SUMMARY</p>
                  </v-card-title>

                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    For Appraisal : {{ funnelTeachersData.mainsPassed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    For PIP : {{ funnelTeachersData.mainsFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    For TBD : {{ funnelTeachersData.screeningFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                </v-card>
              </div> -->
            </div>
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-1 pr-1" fluid>
            <v-col cols="4" md="4" sm="4">
              <div class="text-h6 font-weight-bold highEmphasis">
                Teachers to be Appraised
              </div>
            </v-col>

            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined class="border-button mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn>
              <v-btn depressed outlined rounded class="border-button mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        </div>

        <v-card elevation="0" class="ml-6 mr-4 mt-0">
          <v-card-title class="text-subtitle-1 pa-0">
            <table class="tbl">
              <thead class="t-head">
                <tr class="t-row">
                  <th class="head">Rank</th>
                  <th class="head">Full Name</th>
                  <th class="head">Campaign</th>
                  <!-- <th class="head">School Name</th> -->
                  <!-- <th class="head">Stage</th> -->
                  <th class="head">Status</th>
                  <th class="head">Mains Net Score</th>
                </tr>
              </thead>
              <tbody class="t-body">
                <tr class="t-row" v-for="(teacher, index) in displayedClusterTeachers" :key="teacher.id">
                  <td class="t-data">{{ index + 1 }}</td>
                  <td class="t-data">
                    {{ `${teacher.firstName} ${teacher.lastName}` }}
                  </td>
                  <td class="t-data">{{ teacher.campaignName }}</td>
                  <!-- <td class="t-data">{{ teacher.schoolName }}</td> -->
                  <!-- <td class="t-data">
                    {{ teacher.lastStatus?.stageValue === "Mains"
                      ? teacher.lastStatus?.stageValue
                      : "-"
                    }}
                  </td> -->
                  <td class="t-data">
                    <v-chip class="pass-color" outlined text-color="#06C270">
                      <div class=" dot-pass"></div>{{ teacher.status }}
                    </v-chip>
                    <!-- <template v-else>-</template> -->
                  </td>
                  <td class="t-data">
                    {{ teacher.mainsTotal }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-title>
        </v-card>

      </div>

    </v-col>
  </div>
</template>
    
<script >

import AuthService from "@/services/AuthService";
// import { collection, getDocs, onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  collectionGroup,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";

import FunnelChartCommon from "../FunnelChartCommon.vue";

export default {
  name: "CampaignResult",
  components: {
    FunnelChartCommon
  },
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      jobSeekerList: [],
      schoolClusterTeacher: [],
      topTeacherInCluster: [],
      invited: 0,
      screeningAttempted: 0,
      screeningResult: 0,
      mainsAttempted: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      limit:10,
      schoolChartData: {},
      emptySchoolChartData: {
        totalInvited: 0,
        totalScreeningStarted: 0,
        totalScreeningPassed: 0,
        totalScreeningFailed: 0,
        totalMainsStarted: 0,
        totalMainsPassed: 0,
        totalMainsFailed: 0,
      },
      schooolTeacherAppraisal: 0,
      schooolTeacherPip: 0,
      schooolTeacherTBD: 0,
      roleClusters: "",
      roleSchool: "",

      funnelTeachersData:{
        invited:0,
        screeningStarted:0,
        screeningPassed:0,
        screeningFailed:0,
        mainsStarted:0,
        mainsPassed:0,
        mainsFailed:0,
        demoSubmitted:0,
        interviewSubmitted:0,
      },

    };
  },

  computed: {
    displayedClusterTeachers() {
      return this.topTeacherInCluster.slice(0,this.limit);
    },
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async newTeacherFunnelChartDataListener(){
      const dashboardTeachersRef=doc(db, "dashboards", this.$store.state.role.id+"-vgos-teachers-dashboard", "results", "campaignresults")

      onSnapshot(dashboardTeachersRef, (doc)=>{
        const resultData=doc.data();
        if (resultData){
          this.funnelTeachersData={
            invited: resultData?.invited ? resultData.invited : 0,
            screeningStarted: resultData?.screeningStarted ? resultData.screeningStarted : 0,
            screeningPassed: resultData?.screeningPassed ? resultData.screeningPassed : 0,
            screeningFailed: resultData?.screeningFailed ? resultData.screeningFailed : 0,
            mainsStarted: resultData?.mainsStarted ? resultData.mainsStarted : 0,
            mainsPassed: resultData?.mainsPassed ? resultData.mainsPassed : 0,
            mainsFailed: resultData?.mainsFailed ? resultData.mainsFailed : 0,
            demoSubmitted: resultData?.demoSubmitted ? resultData.demoSubmitted : 0,
            interviewSubmitted: resultData?.interviewSubmitted ? resultData.interviewSubmitted : 0,
          }
        }
      })
    },

    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;

      console.log("my cluster>>", this.roleCluster);
      console.log("my school>>", this.roleSchool);
    },
    // async setupSnapshotListenerSchoolHrFunnel() {
    //   const id = collection(
    //     db,
    //     "dashboards",
    //     "management-dashboard-001",
    //     "schools"
    //   );
    //   // console.log("inside snapshot", id);
    //   if (!id) {
    //     console.log("isnide if dashboard");
    //     this.schoolChartData = this.emptySchoolChartData
    //     console.log("isnide snap", this.schoolChartData);
    //   }

    //   const q = query(id, where("name", "==", this.roleSchool));
    //   // const data = await getDocs(id);
    //   // console.log("data",data)
    //   // var data;
    //   console.log("query??", q);
    //   if (!q.exists) {
    //     console.log("isnide if query");
    //     this.schoolChartData = this.emptySchoolChartData
    //     console.log("isnide snap", this.schoolChartData);
    //   }
    //   console.log("get Docs schools?>>", q);




    //   onSnapshot(q, (snapshot) => {
    //     snapshot.forEach((doc) => {
    //       console.log("data teachers###", doc.data())
    //       if (
    //         doc.data().totalInvited === undefined ||
    //         doc.data().totalScreeningStarted === undefined ||
    //         doc.data().totalScreeningPassed === undefined ||
    //         doc.data().totalScreeningFailed === undefined ||
    //         doc.data().totalMainsFailed === undefined ||
    //         doc.data().totalMainsPassed === undefined ||
    //         doc.data().totalMainsStarted === undefined
    //       ) {

    //         console.log("inside snapshot teacher>>>")
    //         this.schoolChartData = this.emptySchoolChartData
    //         console.log("isnide snap", this.schoolChartData);
    //       } else {
    //         this.schoolChartData = doc.data();
    //       }


    //     });

    //   });

    // },
    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },

    async teacherSchoolSnapshotListener() {
      const roleSchool = this.$store.state.role.schools;
      const capRoleSchool = roleSchool[0].toUpperCase() + roleSchool.slice(1).toLowerCase();
      console.log("roleSchool>>", roleSchool);
      console.log("capRoleSchool>>", capRoleSchool);
      const campaignsRef = collection(db, "campaigns");
      const teacherClusterQuery = query(
        campaignsRef,
        where("audienceType", "==", "TEACHER"),
        where("schools", "array-contains-any", [roleSchool,capRoleSchool])
      );
      onSnapshot(teacherClusterQuery, (snapshot) => {
        snapshot.docs.forEach(async (doc) => {
          const campaignName = doc.data().name;
          if (doc.data().audienceType.toLowerCase() === "teacher") {
            try {
              const jobSeekerRef = collection(campaignsRef, doc.id, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);
              
              // Process each job seeker document in the audience sub-collection
              for (const d of jobSeekDocs.docs) {
                const jobSeekerData = d.data();
                const jobSeekerSchoolName = jobSeekerData.school || jobSeekerData.schoolName || "Unassigned";
                const status = jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length-1].status;
                if (
                  roleSchool.toLowerCase() === jobSeekerSchoolName.toLowerCase() &&
                  status === "MAINS PASSED"
                ) {
                      // Ensure coreSkillsSubjectScreening exists and is iterable
                        try {
                
      
                          // Prepare the job seeker data for display
                          const temp = {
                            firstName: jobSeekerData?.personalInfo?.firstName || "",
                            lastName: jobSeekerData?.personalInfo?.lastName || "",
                            campaignName: campaignName,
                            schoolName: jobSeekerSchoolName,
                            mainsTotal: Number(jobSeekerData?.mainsTotal) || '-',
                            status: jobSeekerData.campaignStatus ? jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length - 1].status : "INVITED",
                            campaignId: doc.id,
                            userId: jobSeekerData.userId
                          };
                  
                          this.jobSeekerList.push(temp);
                        } catch (error) {
                          console.error("Error fetching screening or mains documents:", error);
                        }
                  }
                }
                this.jobSeekerList.sort((a, b) => {
                const mainsTotalA = isNaN(Number(a.mainsTotal)) ? 0 : Number(a.mainsTotal);
                const mainsTotalB = isNaN(Number(b.mainsTotal)) ? 0 : Number(b.mainsTotal);
                return mainsTotalB - mainsTotalA;
                });
              this.topTeacherInCluster = this.jobSeekerList;
              // console.log(this.topTeacherInCluster);
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          }
        });
      });
    },
    getLastStatus(campaignStatus) {
      if (!campaignStatus.length) return { stageValue: "", statusValue: "" };
      
      const lastStatus = campaignStatus[campaignStatus.length - 1].status;
      const statusMap = {
        "INVITED": { stageValue: "Mains", statusValue: "Yet to Attempt" },
        "SCREENING STARTED": { stageValue: "Mains", statusValue: "Yet to Attempt" },
        "SCREENING PASSED": { stageValue: "Mains", statusValue: "Yet to Attempt" },
        "SCREENING FAILED": { stageValue: "Mains", statusValue: "Yet to Attempt" },
        "MAINS STARTED": { stageValue: "Mains", statusValue: "In Progress" },
        "MAINS PASSED": { stageValue: "Mains", statusValue: "Pass" },
        "MAINS FAILED": { stageValue: "Mains", statusValue: "Fail" }
      };

      const { stageValue, statusValue } = statusMap[lastStatus] || { stageValue: "", statusValue: "" };
      return { stageValue: stageValue, statusValue: statusValue };
    },
  },

  created() {
    this.limit = this.$route.params.id;
    console.log(this.userRoleId)
    this.teacherSchoolSnapshotListener()
    this.getClusterOrSchool()
    this.newTeacherFunnelChartDataListener();
  },

};
</script>
    
<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}
</style>