<template>
    <div>
        <v-card width="380px" height="100%" class="filterDialogClass">
            <div class="d-flex flex-row justify-space-between ma-2">
                <h2 class="px-2">Notifications</h2>
                <v-btn class="elevation-0 rounded-pill tranparent white"
                    @click="$emit('close-dialog')"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <div v-for="(notificationDataDescription, index) in notificationDataDescriptions" :key="index" class="ml-4">
                {{ notificationDataDescription }}
            </div>
        </v-card>
    </div>
</template>

<script>
import { collection, getDocs, onSnapshot, doc, query, where } from "firebase/firestore";
import { db } from "../firebase";

export default {
    name: "Notification",

    data() {
        return {
            notificationData: [],
            notificationDataDescriptions: [],
        }
    },

    methods: {
        async notificationListener() {

            const notificationRef = collection(db, "roles", this.$store.state.role.id, "notifications")
            // console.log("Getting the notification data here...??????", notificationRef);

            onSnapshot(notificationRef, (querySnapshot) => {
                this.notificationData.splice(0);
                this.notificationDataDescriptions.splice(0);
                querySnapshot.forEach((notification) => {
                    this.notificationData.push(notification.data());
                    this.notificationDataDescriptions.push(notification.data().description);
                    // console.log(this.notificationData, this.notificationDataDescriptions);
                })
            })
        }
    },

    created() {
        this.notificationListener();
    }
}

</script>




