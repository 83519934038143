<template>
  <div>
    <v-card height="56px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="pl-0 ml-0 mt-1">
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Masters
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>
      </v-card-title>

      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img class="cursor" @click="notificationDialog = true" src="../../assets/bell 1.svg" />
          </v-hover>
        </v-badge>

        <div>
          <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>

    <div fluid class="pad px-8 d-flex flex-wrap">
      <!-- <v-row class="d-flex flex-row">
        <v-col cols="2">
          <div class="text-h6 ml-2">Masters</div>
        </v-col>
      </v-row> -->

      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.subjectCategory.read">
          <div class="masterBox" @click="$router.push('/subject-category')">
            <img class="masterImage" src="../../assets/SubjectCategory.svg" alt="Subject Category" />

            <div class="titleBox">Subject Categories</div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.board.read">
          <div class="masterBox" @click="$router.push('/boards')">

            <img class="masterImage" src="../../assets/Boards.svg" alt="Boards" />

            <div class="titleBox">Boards</div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.skills.read">
          <div class="masterBox" @click="$router.push('/skills')">
            <img class="masterImage" src="../../assets/Skills.svg" alt="Skills" />
            <div class="titleBox">Skills</div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.Level.read">
          <div class="masterBox" @click="$router.push('/levels')">

            <img class="masterImage" src="../../assets/levels.svg" alt="Levels" />

            <div class="titleBox">Levels</div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.Grade.read">
          <div class="masterBox" @click="$router.push('/grades')">
            <img class="masterImage" src="../../assets/Grade.svg" alt="Grade" />

            <div class="titleBox">Grades</div>
          </div>
        </v-col>

        <!-- <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.learningObjective.read">
          <div class="masterBox" @click="$router.push('/lo-banks')">
            <img class="masterImage" src="../../assets/LearningObjectives.svg" alt="Learning Objectives" />

            <div class="titleBox">Learning Objectives</div>
          </div>
        </v-col> -->

        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.subjects.read">
          <div class="masterBox" @click="$router.push('/subjects')">
            <img class="masterImage" src="../../assets/Subject.svg" alt="Subject" />

            <div class="titleBox">Subjects</div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.cluster.read">
          <div class="masterBox" @click="$router.push('/clusters')">
            <img class="masterImage" src="../../assets/Cluster.svg" alt="Cluster" />

            <div class="titleBox">Clusters</div>
          </div>
        </v-col>


        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.Brand.read">
          <div class="masterBox" @click="$router.push('/brands')">
            <img class="masterImage" src="../../assets/Brand.svg" alt="Brand" />
          </div>
          <div class="titleBox">Brands</div>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.school.read">
          <div class="masterBox" @click="$router.push('/schools')">
            <img class="masterImage" src="../../assets/School.svg" alt="School" />

            <div class="titleBox">Schools</div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.league.read">
          <div class="masterBox" @click="$router.push('/league')">
            <img class="masterImage" src="../../assets/League.svg" alt="League" />

            <div class="titleBox">League</div>
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3" v-if="$store.state.role.league.read">
          <div class="masterBox" @click="$router.push('/skills-weightage-thresholds')">
            <img class="masterImage" src="../../assets/weightage.png" alt="League" />

            <div class="titleBox">Skills - Weightage and Thresholds</div>
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="3"
          v-if="$store.state.role.league.read"
        >
          <div class="masterBox" @click="$router.push('/demoTopics')">
            <img
              class="masterImage"
              src="../../assets/topic.png"
              alt="League"
            />

            <div class="titleBox">Demo Topics</div>
          </div>
        </v-col>
      </v-row>
    </div>


    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>
  </div>
</template>



<script>
import AuthService from "../../services/AuthService";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "@/components/MastersDropdownComponent.vue";

export default {
  name: "MastersView",
  components: {
    Notification, MastersDropdownComponent
  },

  data() {
    return {
      notificationDialog: false,
      
    };
  },

  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
  },
};
</script>



<style>
.masterRow {
  height: 210px;
  margin: 16 16px 16px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border: 2px solid black; */
}

.masterBox {
  height: auto;
  width: 100%;
  /* margin: 8px; */
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.masterBoxEmpty {
  height: 193px;
  width: 25%;
  margin: 8px;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
}

.imageBox {
  height: 141px;
  overflow: hidden;
}

.titleBox {
  height: 52px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 8px 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.masterImage {
  width: 100%;
  /* height: 100%; */
}
</style>