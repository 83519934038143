import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import axios from "axios";
import CKEditor from "ckeditor4-vue";
import InstantSearch from "vue-instantsearch";

Vue.use(InstantSearch);
Vue.prototype.$ALGOLIA_APP_ID = process.env.VUE_APP_ALGOLIA_APP_ID;
Vue.prototype.$ALGOLIA_APP_TOKEN = process.env.VUE_APP_ALGOLIA_APP_TOKEN;
Vue.prototype.$QUESTION_INDEX = process.env.VUE_APP_QUESTION_INDEX;
Vue.prototype.$USERS_INDEX = process.env.VUE_APP_USERS_INDEX;
Vue.prototype.$FRONTEND_DOMAIN = process.env.VUE_APP_FRONTEND_DOMAIN;
Vue.prototype.$DEEPLINK = process.env.VUE_APP_DEEPLINK;
Vue.prototype.$PACKAGE_NAME = process.env.VUE_APP_PACKAGE_NAME;

Vue.use(CKEditor);
Vue.config.productionTip = false;
axios.defaults.withCredentials = false;
// axios.defaults.baseURL = 'https://lmsbackend.knoggles.ai/api/v1/';
new Vue({
  vuetify,
  store,
  router,
  created() {
    this.$store.watch(
      (state) => state.chartSearch,
      (newValue, oldValue) => {
        console.log("chartSearch changed:", newValue);
        // Perform any desired actions with the new value
      }
    );
  },
  render: (h) => h(App),
}).$mount("#app");
