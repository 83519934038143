<template>
  <div class="w-100">
    <div v-if="showGraph" class="w-100" ref="chart1"></div>
    <div v-else class="w-100 text-center data-not-ava">Data Not Available</div>
  </div>
</template>

<script>
export default {
  props: ["graphData", "message"],
  data() {
    return {
      chart: null,
      chartData: [],
      showGraph: false,
      selectedRowIndex: -1,
    };
  },
  computed: {},
  mounted() {},
  watch: {
    graphData: {
      immediate: true,
      handler(newVal) {
        this.chartData = newVal;
        this.checkvalue();
        if (this.showGraph) {
          google.charts.setOnLoadCallback(this.drawCharts);
        }
      },
    },
  },
  methods: {
    drawCharts() {
      this.drawChart1();
    },
    checkvalue() {
      if (this.chartData.length > 1) {
        this.chartData.forEach((obj) => {
          if (obj[1] != 0) {
            this.showGraph = true;
          }
        });
      } else {
        this.showGraph = false;
      }
    },

    drawChart1() {
      const data = new google.visualization.DataTable();
      data.addColumn("string", "Topping");
      data.addColumn("number", "Slices");
      data.addRows(this.chartData);

      const options = {
        title: "Completion Status",
        titleTextStyle: {
          fontSize: 14, // Increase the font size of the title
          fontName: "GraphikMedium",
          bold: false, // Make the title bold if desired
          alignment: "start", // Align the title to the start (left)
          padding: { top: 0, left: 0 },
        },
        hAxis: null,
        vAxis: {
          minValue: 0,
          gridlines: { color: "transparent" }, // Remove background lines
          textPosition: "none", // Remove y-axis values
        },
        areaOpacity: 1, // Set the opacity of the area chart to 1
        colors: ["#9fa8d1", "#9fb9e3"], // Set multiple colors for the pie chart
        width: "100%",
        height: "135",

        legend: {
          position: "left",
          textStyle: {
            fontSize: 12, // Adjust the font size as desired
          }, // Set the legend position to the left side
        },
        chartArea: {
          top: 32,
          bottom: 12,
          left: 10,
          right: 0,
          width: "100%",
          height: "100%",
        },
        pieSliceTextStyle: {
          fontSize: "14px", // Adjust the font size as desired
          color: "black",
        },
      };

      this.chart = new google.visualization.PieChart(this.$refs.chart1);

      google.visualization.events.addListener(
        this.chart,
        "select",
        this.selectHandler
      );
      this.chart.draw(data, options);
    },

    selectHandler() {
      const selectedItem = this.chart.getSelection()[0];
        if(selectedItem == undefined){
          this.chart.setSelection([]);
           this.selectedRowIndex = -1;
           this.$emit("data-updated",null );
        }
        // this.$emit('data-updated', selectedItem.row);
        console.log("selected item", selectedItem);

// this.$emit('data-updated',this.chartData[selectedItem.row][0]);

if (selectedItem) {
  const selectedRow = selectedItem.row;
  console.log("selected row", selectedItem);
  // console.log("selected row index1",this.selectedRowIndex)

  const isSelected = selectedItem.row === this.selectedRowIndex;
  console.log("selected row index2", this.selectedRowIndex);

  console.log("is select", isSelected);

  if (isSelected) {
    this.chart.setSelection([]);
    this.selectedRowIndex = -1;
  } else {
    // this.chart.setSelection([{ row: selectedRow, column: null }]);
    this.selectedRowIndex = selectedRow;
  }

  this.$emit("data-updated",this.chartData[selectedRow][0]);
}
    },
  },
};
</script>
