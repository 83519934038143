import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_USERS_API_URL
});

export default {
    
   createRole : async function (role) {
        try {
            const response = await instance.post('role',role,
            {
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateRole : async function (role,id) {
        try {
            const response = await instance.put('role?id='+id,role,
            {
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleterole : async function (role) {
        console.log("role data from controller",role)
        try {
            const response = await instance.put('deleterole',role,
            {
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    
    deleteUser : async function (data) {
        console.log("deleting user data",data)
        try {
            const response = await instance.delete('roleUser',{data},
            {
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

       
    getRoles: async function () {
        try {
            const response = await instance.get('roles')
            return response;
        } catch (error) {
            return error.response;
        }
    },
    inviteRole : async function (data) {
        console.log('role data',data)
        try {
            const response = await instance.post('sendRoleInvite',data,
            {
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateUsers : async function (data) {
        console.log('role data in update',data)
        try {
            const response = await instance.put('updateRoleGroupUsers',data,
            {
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getAllRoleUsers: async function (searchQuery) {

        try {
            const response = await instance.get('allRoleGroupsUsers',searchQuery, {
                headers: {
                  Authorization: AuthService.getToken(),
                },
              })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getRoleGroupUsers: async function (roleGroupId,category,clusterFilter,schoolFilter) {
        try {
            const response = await instance.get('roleGroupUsers', {
                headers: {
                  Authorization: AuthService.getToken(),
                },
                params: {
                  roleGroupId,
                  category,
                  clusterFilter,
                  schoolFilter
                }
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    checkUserExists: async function (roleId,userEmails) {
        console.log('role id',roleId)
        console.log('userEmails',userEmails)
        try {
            const response = await instance.get('checkUserExists', {
                headers: {
                  Authorization: AuthService.getToken(),
                },
                params: {
                  roleId,
                  userEmails
                }
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    

 

  
  

}




