<template>
    <GChart class="bubbleChart" type="BubbleChart" :data="chartData" :options="chartOptions" />
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
    name: "BubbleChart",
    props: ['dataArr'],
    components: {
        GChart
    },
    data() {
        return {
            chartData: this.dataArr,
            chartOptions: {
                colors: ['#FF0000', '#00FF00', '#A9A9A9', '#D32F2F', '#808080', '#FF4500', '#32CD32'],
                // colorAxis: { colors: ['red', 'green' ] },
                title: '',
                hAxis: {
                    title: 'Time',
                    gridlines: {
                        color: 'transparent' 
                    },
                    // ticks: [0, 10, 20, 30, 40, 50, 60, 70] 
                },
                vAxis: {
                    title: 'Emotion', format: 0, viewWindow: {
                        min: 0,
                        max: 8
                    },
                    ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8] 

                },
                sizeAxis: {
                    minSize: 2,
                    maxSize: 10
                },

            },
        }
    },
    // watch: {
    //     dataArr(value) {
    //         console.log("props value here..?", value)
    //     }
    // }
}
</script>

<style scoped>
.bubbleChart {
    height: 90%;
    padding-bottom: 2rem !important;
    /* color: magenta; */
}
</style>

<!-- chartData: [
['ID', 'Time', 'Emotion'],
['', 10, 100],
['', 10, 136],
['', 30, 184],
['', 40, 278],
['', 50, 200],
['', 60, 170],
['', 70, 477],
['', 80, 277]

], -->