import { render, staticRenderFns } from "./SchoolTeachersPIP.vue?vue&type=template&id=22ec6341&scoped=true&"
import script from "./SchoolTeachersPIP.vue?vue&type=script&lang=js&"
export * from "./SchoolTeachersPIP.vue?vue&type=script&lang=js&"
import style0 from "./SchoolTeachersPIP.vue?vue&type=style&index=0&id=22ec6341&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ec6341",
  null
  
)

export default component.exports