<template>
   <!-- author: suraj -->
   <div class="background">
     <v-dialog v-model="successDialog" max-width="366px" persistent>
     <v-card>
       <v-container fluid class="pa-8">
         <v-card-text class="text-center">
           <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
           <p class="text-h5 py-4">
           Your Password Is Created Successfully
           </p>
           <v-btn
             class="accent"
             large
             width="157px"
             rounded
             @click="invited"
             >OK</v-btn
           >
         </v-card-text>
       </v-container>
     </v-card>
   </v-dialog>
       
     <v-app-bar color="transparent" class="rounded-xl" elevation="0" absolute height="120">
       <v-list-item class="ml-8">
         <v-list-item-icon>
           <v-img src="../assets/logo.svg" height="48px" width="178px"></v-img>
         </v-list-item-icon>
       </v-list-item>
     </v-app-bar>
     <v-row align="center" justify="center" style="height: 100vh" dense>
       <v-col
         cols="12"
         lg="12"
         md="12"
         class="fill-height d-flex flex-column justify-center align-center"
       >
         





         <v-card  width="426" height="auto">
           <div class="text-center pa-8">
             <img
               src="../assets/icon.svg"
               class="pt-3"
               width="70px"
               height="70px"
             />
             <v-card-title class="justify-center">
               <h3>Create New Password</h3>
             </v-card-title>
             <v-card-subtitle>
               Use 8 or more characters with a mix of small and capital letters, numbers & special Character
             </v-card-subtitle>
 
             <v-text-field
               label="Password"
               placeholder=" Password"
               :type="show2 ? 'text' : 'password'"
               @click:append="show2 = !show2"
               :rules="[rules.required, rules.min]"
               :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
               outlined
               v-model="password"
               class="rounded-xl"
               @keydown.enter="login"
             ></v-text-field>

             <v-text-field
               label="Confirm Password"
               placeholder=" Password"
               :type="show2 ? 'text' : 'password'"
               @click:append="show2 = !show2"
               :rules="[rules.required, rules.min]"
               :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
               outlined
               v-model="cnfPassword"
               class="rounded-xl"
               @keydown.enter="login"
             ></v-text-field>
 
             <v-btn
                 @click.prevent="resetPassword"
                 color="#000000DE"
                 class="white--text mb-2 py-4"
                 rounded
                 large
                 width="100%"
                 :loading="loading"
               
               >
                 create Password
               </v-btn>
            
     
 
           </div>
         </v-card>









       </v-col>
     </v-row>
   </div>
 </template>
   
 <script>
 import AuthService from "@/services/AuthService";
 import "../styles.css";
 export default {
   components: {},
   name: "ResetPasswordView",
   data() {
     return {
       cnfpasswd: false,
       forgetBool: false,
       successDialog: false,
       loading: false,
       roleId: "",
       ctList: false,
       cnfPasswordView: false,

       valid: false,
       email: "",
       password: "",
       cnfPassword: "",
       emailRules: [
         (v) => !!v || "E-mail is required",
         (v) =>
           /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
             v
           ) || "E-mail must be valid",
       ],
       show2: false,
    
       rules: {
         required: (value) => !!value || "Required.",
         min: (v) => v.length >= 8 || "Min 8 characters",
         emailMatch: () => `The email and password you entered don't match`,
       },
     };
   },
   methods: {


     invited(){
       this.successDialog = false;
       this.logout();
       this.$router.push('/login');
     },
     logout() {
     AuthService.logout();
     this.$router.push("/login");
   },
     async resetPassword() {
       if(this.valid){
         if(this.cnfPassword == this.password){
       this.loading = true;
       const response = await AuthService.resetPassword({
         email:this.email,
         password: this.password, 
         roleId: this.roleId,
         name: this.name
       });
       this.loading = false;
       if(response.data.flag){
         this.successDialog = true
       }
       else{
         alert(response.data.result)
       }
       console.log("admin response",response);
     }else{
       alert("Password do not match")
     }
     }

       
     },
   },
   created() {
 
   },
 };
 </script>