<template>
  <div>
    <canvas ref="pdfCanvas"></canvas>
  </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';

// Set the workerSrc to the path where the pdf.worker.mjs file is located
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

export default {
  name: 'PDFPreviewer',
  props: {
    pdfPath: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.loadPDF();
  },
  methods: {
    async loadPDF() {
      try {
        const pdf = await pdfjsLib.getDocument(this.pdfPath).promise;
        const page = await pdf.getPage(1);

        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = this.$refs.pdfCanvas;
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        await page.render(renderContext).promise;
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    }
  }
};
</script>

<style scoped>
canvas {
  border: 1px solid #ccc;
  display: block;
  margin: 0 auto;
}
</style>
