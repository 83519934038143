<template>
    <GChart class="barChart" type="BarChart" :data="chartData" :options="chartOptions" />
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
    name: "BarChart",
    components: {
        GChart
    },
    props:["dataPts"],
    data() {
        return {

            chartData: this.dataPts,

            // chartData: [
            //     ['Emotions', 'Percentage', { role: 'style' }],
            //     ['Happiness', 8.94, '#82D2DA'],
            //     ['Sadness', 10.49, '#82D2DA' ],
            //     ['Anger', 19.30, '#82D2DA' ],
            //     ['Confidence', 21.45, '#82D2DA'],

            // ],
            chartOptions: {
              
                title: '',
                hAxis: {
                    title: 'Magnitude',
                },
                vAxis: {
                    title: 'Emotions',
                },
                legend: 'none'
            },
        }
    }
}
</script>

<style scoped>
.barChart {
    height: 100% !important;
    padding-bottom: 1rem !important;
}
</style>

