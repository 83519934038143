<template>
    <div class="nlpAudio">

        <div class="nlpUpperDiv">
            <div class="speechPara bg-white">
                <h4>Speech in the Video</h4>
                <p>{{ caption }}</p>
            </div>
            <div class="emotionsGraph bg-white">
                <h4>Emotions & Sentiments Graph</h4>
                <BarChart :dataPts="audioAnalysis" />
            </div>

            <div class="lineGraph bg-white">
                <h4>Person Detected Line Graph</h4>
                <LineChart />
            </div>
        </div>

        <div class="nlpLowerDiv">
            <div class="rateGraph bg-white">
                <h4>Speech Rate Graph</h4>
                <RateChart />
            </div>
            <div class="freqGraph bg-white">
                <h4>Keyword Frequency Bar Graph</h4>
                <ColumnChart :dataPts="requiredWordCnt" />
            </div>
        </div>

    </div>
</template>

<script>
import BarChart from "./DemoGraphs/BarChart.vue"
import LineChart from "./DemoGraphs/LineChart.vue"
import RateChart from "./DemoGraphs/RateChart.vue"
import ColumnChart from "./DemoGraphs/ColumnChart.vue"

export default {
    components: {
        BarChart,
        LineChart,
        RateChart,
        ColumnChart
    },
    data() {
        return {
            videoDetails: {},
            caption: "",
            audioAnalysis: new Array(2).fill(0),
            wordFrequency: {},
            wordCntFirst: [],
            requiredWordCnt: [],
        }
    },
    mounted() {
        this.getVideoDetails();
        this.getAudioAnalysis();
        this.getWordFrequency();
    },
    methods: {
        getVideoDetails() {
            this.videoDetails = this.$store.state.selectedVideo ? this.$store.state.selectedVideo : {}
            if (Object.keys(this.videoDetails).length === 0) {
                const videoData = localStorage.getItem("videoInfo")
                const jsonObj = JSON.parse(videoData);
                this.$store.commit("setVideoDetails", jsonObj)
                this.videoDetails = jsonObj
            }
            console.log("video Details :", this.videoDetails)
            this.caption = this.videoDetails.caption ? this.videoDetails.caption : ""

            // console.log("caption :", this.caption)
            // console.log("videoCaption :", this.videoDetails.caption)
            this.getAudioAnalysis();
            this.getWordFrequency();
        },

        getAudioAnalysis() {
            const audioReport = this.videoDetails.audioAnalysisReport
            this.audioAnalysis[0] = ['Emotions', 'Magnitude', { role: 'style' }]
            let sentiment = "Neutral"
            if (audioReport?.sentimentScore > 0) {
                sentiment = "Positive"
            }
            else if (audioReport?.sentimentScore < 0) {
                sentiment = "Negative"
            }
            this.audioAnalysis[1] = [sentiment, audioReport?.sentimentMagnitude, "#82D2DA"]
        },

        getWordFrequency() {
            const wordList = this.caption.split(" ")
            for (const word of wordList) {
                if (!(word in this.wordFrequency)) {
                    this.wordFrequency[word] = 0;
                }
                this.wordFrequency[word] += 1
            }
            // console.log("Word List :", this.wordFrequency);

            for (const i in this.wordFrequency) {
                this.wordCntFirst.push([this.wordFrequency[i], i])
            }

            this.wordCntFirst.sort()

            // this.wordCntFirst.push(['Words', 'Frequency', { role: 'style' }])

            this.wordCntFirst.reverse()

            // this.requiredWordCnt=this.wordCntFirst.slice(0, 11)

            this.requiredWordCnt.splice(0)

            this.requiredWordCnt.push(["Words", "Frequency", { role: 'style' }])

            for (let i = 1; i < Math.min(this.wordCntFirst.length, 11); i++) {
                this.requiredWordCnt.push([this.wordCntFirst[i][1], this.wordCntFirst[i][0], "#FF008A"])
            }

            // console.log("required_word_cnt :",this.requiredWordCnt);

        }
    }
}

</script>

<style scoped>
.nlpAudio {
    /* border: 1px solid rgba(0, 0, 0, 0.12); */
    height: 58vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.nlpUpperDiv {
    width: 100%;
    height: 48%;
    /* border: 1px solid rgba(0, 0, 0, 0.12); */
    display: flex;
    flex-direction: row;
}

.nlpAudio h4 {
    text-align: center;
}

.speechPara {
    width: 33.3%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-right: 1rem;
    border-radius: 0.75rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 0.5rem;
    overflow-y: scroll;
}

.speechPara::-webkit-scrollbar {
    width: 0.4em;
}

.emotionsGraph {
    width: 33.3%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-right: 1rem;
    border-radius: 0.75rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 0.5rem;
}

.lineGraph {
    width: 33.3%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0.75rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 0.5rem;

}

.nlpLowerDiv {
    width: 100%;
    height: 48%;
    display: flex;
    flex-direction: row;
    /* border: 1px solid rgba(0, 0, 0, 0.12); */
    margin-top: 1rem;

}

.rateGraph {
    width: 50%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-right: 1rem;
    border-radius: 0.75rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 0.5rem;
}

.freqGraph {
    width: 50%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0.75rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 0.5rem;
}
</style>