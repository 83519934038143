<template>
  <div>
  <v-dialog v-model="addUserDialog" class="cdz" max-width="600px">
    <v-card height="500px" width="100%" class="ma-0 pa-1 rounded-t-lg d-flex flex-column">
      <div class="px-4 pt-4 flex-grow-1" id="myScroll" style="overflow-y: auto;">
        <div class="text-align text-center pb-4">Invite Users</div>
        <v-card></v-card>
        <v-row>
          <v-col cols="10" md="10" sm="10" class="d-flex ">
             <v-text-field
              class="rounded-xl pr-0 no-details"
              outlined
              placeholder="Add Candidate"
              v-model="campaignUserEmail"
              @keydown.enter="inviteUsersToCampaign"

            ></v-text-field>
          </v-col>
          <v-col cols="2" md="2" sm="2" class="d-flex pl-0  ">
            <v-btn class="rounded-xl  w-100 btn-color white--text" @click="inviteUsersToCampaign" 
             :disabled="!isValidEmail(campaignUserEmail)">
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-card-subtitle class="ma-0 mt-4 pa-0">Candidate List</v-card-subtitle>

        <!-- user list -->
        <v-card v-if="audiance.length == 0">
          <div class="d-flex align-center justify-center cardBorder py-2">NO CANDIDATE INVITED</div>
        </v-card>
        <v-card v-else v-for="(user, i) in audiance" :key="i" width="100%" height="70px" class="elevation-0 pa-0 mr-4 py-0">
          <v-row class="ma-0 pa-0 h-100 justify-space-between align-center">
            <div class="justify-center d-flex flex-column pa-0" sm="10" cols="10" md="10">
              <div>{{ user.emails[0] }}</div>
            </div>

            <div>
              
              <v-btn x-small @click="removeUser(i)" class="square-btn" :style="{ backgroundColor: '#ffcccc' }">
              <v-icon :style="{ color: '#ff0000' }">
                mdi-close
              </v-icon>
            </v-btn>
            </div>
          </v-row>
          
        </v-card>
        <!-- user list end here -->
      </div>
      <v-card-actions class="fixed-bottom d-flex justify-end">
        <v-btn class="accent--text pa-4" text @click="onCancel">Cancel</v-btn>
        <v-btn class="accent--text pa-4" text @click="onInvite">
         <template v-if="isAddUserLoading">
          <v-progress-circular
            indeterminate
            color="accent"
            size="20"
          ></v-progress-circular>
        </template>
        <template v-else>
          Invite
        </template>
      </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addUserSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">{{message}}</p>
              <v-btn class="accent" large width="157px" rounded @click="addUserSuccessDialog=false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
  import CampaignController from "@/controllers/CampaignController";
  export default{
    name:"AddUserToCampaign",
    props:{
      addUserDialog: {
        type: Boolean,
        required: true,
      },
      campaignId: {
        type: String,
        required: true,
    },
    },
    data(){
      return{
        localAddUserDialog: this.addUserDialog,
        campaignUserEmail:'',
        audiance:[],
        isAddUserLoading:false,
        addUserSuccessDialog:false,
        pdfName:'',
        message:'',
      }
    },
    watch: {
      addUserDialog(val) {
        this.localAddUserDialog = val;
      },
      localAddUserDialog(val) {
        this.$emit('update:addUserDialog', val);
      }
    },
    methods:{
      onCancel() {
      this.addUserDialog = false;
      this.campaignUserEmail = '';
      this.audiance = [];
    },
    isValidEmail(email) {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return re.test(email);
    },
    async onInvite() {

      this.isAddUserLoading = true;
      try {
        const response = await CampaignController.inviteUsersToCampaign(
          this.campaignId,
          this.audiance
        );

        if (response.flag) {
          this.localAddUserDialog = false;
          this.audiance = [];
          this.isAddUserLoading = false;
          this.addUserSuccessDialog = true;
          this.message = response.message;
        } else {
          console.error('Error inviting users:', response.message || response);
        }
      } catch (error) {
        console.error('Error inviting users:', error);
      } finally {
        // this.inviteLoading = false;
      }
    },
    removeUser(index) {
      this.audiance.splice(index, 1);
    },
    async inviteUsersToCampaign(){
      
       if (this.isValidEmail(this.campaignUserEmail)) {
        this.audiance.push({
          pdfName: this.pdfName,
          phones: [],
          emails: [this.campaignUserEmail]
        });
        this.campaignUserEmail = '';
      }
    },
    }
  }
</script>
