// import { TRUE } from 'sass'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: key => localStorage.removeItem(key)
      }
    })
  ],
  state: {
    campaignPaginationStates: {},
    questionForEdit: {},
    assessmentData: {},
    bulkQuestionRes: [],
    levelChart: null,
    assesedChart:[],
    clusterChart: [],
    chartSearch: "",
    role: {},
    updateRole: null,
    userInfo: {
      name: "user",
      email: "dummyemail@gmail.com"
    },
    school:"Vibgyor Roots - PANATHUR",
    cluster:"Cluster 4",
    isNavBar:false,
    user: null,
    userType:localStorage.getItem('userType') || null,
    breadcrumb:"",
    selectedVideo: {},
    currentCraouselIndex: 0,
    userRoleId: '',
    
  },

  getters: {
    getRoleFromStore: state => state.role,
    currentCraouselIndex: state => state.currentCraouselIndex,
    getRoleId: state => state.userRoleId,
    getQuestionForEdit: state =>state.questionForEdit,
    getAssessmentData: state =>state.assessmentData,
  },
  mutations: {
    SET_QUESTION_FOR_EDIT(state, questionForEdit){
      state.questionForEdit = questionForEdit;
    },
    SET_ASSESSMENT_DATA(state, assessmentData){
      state.assessmentData = assessmentData;
    },
    SET_PAGINATION_STATE(state, { campaignId, paginationState }) {
      Vue.set(state.campaignPaginationStates, campaignId, paginationState)
    },
    CLEAR_PAGINATION_STATES(state) {
      state.campaignPaginationStates = {}
    },
    SET_ROLE(state, role){
      state.role = role
    },

    SET_ROLE_ID(state, roleId){
      state.userRoleId = roleId
    },
    SET_CURRENT_CRAOUSEL_INDEX(state, index) {
      state.currentCraouselIndex = index;
    },
    setChartSearch(state, value) {
      state.chartSearch = value;
    },
    setVideoDetails(state, payload){
      state.selectedVideo = payload
    },
    setUserType(state, type) {
      state.userType = type;
      localStorage.setItem('userType', type);
    }
    // other mutations...
  },
  actions: {
    setQuestionForEdit({commit}, questionForEdit){
      commit('SET_QUESTION_FOR_EDIT', questionForEdit);
    },
    setAssessmentData({commit}, assessmentData){
      commit('SET_ASSESSMENT_DATA', assessmentData);
    },
    savePaginationState({ commit }, { campaignId, paginationState }) {
      commit('SET_PAGINATION_STATE', { campaignId, paginationState })
    },
    clearPaginationStates({ commit }) {
      commit('CLEAR_PAGINATION_STATES')
    },
    setCurrentCraouselIndex({ commit }, index) {
      commit('SET_CURRENT_CRAOUSEL_INDEX', index);
    },
    saveUserType({ commit }, type) {
      commit('setUserType', type);
    },
    setRole({commit}, role){
      commit('SET_ROLE', role)
    },
    setRoleId({commit}, roleId){
      commit('SET_ROLE_ID', roleId)
    }
  },
  modules: {
  }
})
