<template>
    <div>
        <div v-if="preloader">
            <template>
                <v-dialog v-model="preloader" hide-overlay persistent width="300">
                    <v-progress-linear indeterminate></v-progress-linear>
                </v-dialog>
            </template>
        </div>

        <div v-else>
            <v-card height="64px" width="100%"
                class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
                style="position: fixed; z-index: 200; top: 0; width: 98%;
            padding-top: 16px;">
                <v-card-title class="pl-0 ml-0">
                    <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/campaign')">
                        Campaigns
                    </p>
                    <v-icon class="breadcrumb-arrow-unselected"
                        style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                    <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.back()">
                        {{ campaignName }}
                    </p>
                    <v-icon class="breadcrumb-arrow-unselected"
                        style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                    <p class="breadcrumb-text" style="margin-top: 2px">User Report</p>
                    <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
                </v-card-title>
                <div class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center"
                    style="position: fixed; right: 0">
                    <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
                        <v-hover>
                            <img @click="notificationDialog = true" class="cursor" width="24px"
                                src="../assets/bell 1.svg" />
                        </v-hover>
                    </v-badge>

                    <div>
                        <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
                    </div>
                    <div class="d-flex flex-column d-justify-evenly user-details">
                        <v-card-title class="ma-0 pa-0 elipsis">
                            {{ $store.state.userInfo.name }}
                        </v-card-title>
                        <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                            {{ $store.state.userInfo.email }}
                        </v-card-subtitle>
                    </div>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                                <v-icon class="">mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-btn @click="logout">logout</v-btn>
                    </v-menu>
                </div>
            </v-card>

            <div class="repUpDiv">
                 <v-row> 
                    <v-col class="repUpDivLeft mr-2 ml-2">
                     <div class="d-flex w-100">
                        <div class="conDiv">
                        <div class="d-flex flex-row jusify-center">
                            <v-avatar size="69">
                                <img v-if="proctoringPic !== ''" :src="proctoringPic" alt="Report User Pic">
                                 <img v-else  src="../assets/avtar.png" alt="Report User Pic"> 
                            </v-avatar>
                                              
                            <div class="d-flex flex-column justify-space-between ml-4">
                                <p class="i-patch">{{ userName }}</p>
                                <span>{{ userPhone }}</span>
                                <span class="i-patch">{{ userEmail }}</span>
                            </div>
                        </div>

                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <div class="mt-4 dual-elipsiss" >{{ displaySchoolName }}</div>                                            
                                </span>
                            </template>
                            <span class="mt-4">{{ schoolName }}</span>
                        </v-tooltip>
                        <div class="d-flex flex-wrap">
                        <v-chip small class="mt-2 mr-1" v-for="(level, index) in levels" :key="index">{{ level
                            }}</v-chip>
                        </div>

                    </div>
                    <div class="d-flex flex-column gap-8p pl-4 flex-grow-1" :class="userType == 'teacher'? 'flex-grow-1': ''">
                            <div class="sub-chip d-flex flex-column pa-3 cursor" v-for="(league, index) in leagueData" :class="index == subChipIndex ? 'active-br': ''" @click="setChipIndex(index)" :key="league">
                             <span class="fs-12">{{league.subjectName}}</span>
                             <span class="fs-10">Score - {{ ((league.score / league.maximumMarks)*10).toFixed(1) }}/10</span>
                            </div>
                        </div>
                     <div class="d-flex pa-2 meter-container-br" :class="userType == 'teacher' ? 'justify-center':'justify-space-between'">
                   
                        <div class="metChartDiv pa-2">
                        <p class="mb-2 d-flex metChartBelow text-no-wrap recom-labe1-typo">System Recommendation</p>
                            <MeterChart :data="meterChartData['system']" :chartName="'chart-one'" ref="chart-one" />
                            <p class="chip-replace mt-2"  :class="shortlistVal(meterChartData['system'].value)?.remarkClass"  label>{{ shortlistVal(meterChartData['system'].value)?.text
                        }}</p>

                    </div>
                    <div v-if="userType !== 'teacher'" class="metChartDiv pa-2">
                        <p class="mb-2 d-flex metChartBelow text-no-wrap recom-labe1-typo" >Interview Recommendation</p>
                        <MeterChart :data="meterChartData['interview']" :chartName="'ChartTwo'" ref="chart-two" />
                      <p class="chip-replace mt-2"  :class="shortlistVal(meterChartData['interview'].value)?.remarkClass"  label>{{ shortlistVal(meterChartData['interview'].value)?.text
                        }}</p>

                    </div>
                    <v-divider v-if="userType !== 'teacher'" class="mr-2"  vertical></v-divider>
                    <div v-if="userType !== 'teacher'" class="metChartDiv final-br pa-2">
                        <p class="mb-2 d-flex metChartBelow text-no-wrap recom-labe1-typo">Final Recommendation</p>

                        <MeterChart
                        ref="chart-three" 
                        :data="meterChartData['final']"
                        :chartName="'Chart-three'"  />
                        <p class="chip-replace mt-2"  :class="shortlistVal(meterChartData['final'].value, true)?.lableClass"  label>{{ shortlistVal(meterChartData['final'].value, true)?.text
                        }}</p>

                    </div>
                     </div>
                     </div>
                    </v-col>
                    <v-col :cols="userType !== 'teacher' ? '2':'4'" class="repUpDivLeft-brown-bdr">
                      <div class="d-flex flex-column pa-2 justify-space-between gap-8p">
                        <p class="c-date">Psychometry</p>
                        <p class="league-score-rank">This action will download the psychometric report</p>
                        <div class="d-flex justify-end">
                            <v-btn class="btn-color white--text text-subtitle-2 br-40" :loading="downloadLoader"
                            :disabled="disableDownload" @click="generateReport">Download Report</v-btn>
                        </div> 
                      </div>
                    </v-col>
                 </v-row>
           

            </div>

            <div class="user-reprot-left-sec">
                
                <div class="repLoDivLeft">
                    <div class="repUpDivRight">
                    <div class="pentagon-first"
                    :class="{'pentagon-teacher':userType == 'teacher'}"
                        @click="$router.push({ path: `/testreport/${campaignId}/${userId}`, query: { isSelect: 0 } })">
                        <div class="pentCont-first">
                            <div class="pentRow league-title">
                                <p>Screening Test</p>
                            </div>
                            <p class="fs-12">Score - <span class="ff-bold">{{ screeningTestData.netScore >= 0 ?   screeningTestData.netScore?.toFixed(1) : 'N/A'}}/ 10</span></p>
                        </div>
                    </div>

                    <div class="pentagon" 
                    :class="{'pentagon-teacher':userType == 'teacher'}"
                        @click="$router.push({ path: `/testreport/${campaignId}/${userId}`, query: { isSelect: 1 } })">
                        <div class="pentCont">
                            <div class="pentRow league-title">
                                <p>Mains test</p>
                            </div>
                            <p class="fs-12">Score - <span class="ff-bold">{{ mainsTestData.netScore >= 0 ?  mainsTestData.netScore?.toFixed(1) : 'N/A'}}/10</span></p>
                        </div>
                    </div>

                    <div class="pentagon" 
                    :class="{'pentagon-teacher':userType == 'teacher'}"
                        @click="$router.push({ path: `/demoreport/${campaignId}/${userId}`})">
                        <div class="pentCont">
                            <div class="pentRow league-title">
                                <p>Demo Video</p>
                            </div>
                            <p class="fs-12">Score - <span class="ff-bold">{{ demoTestData.netScore >= 0 ?  demoTestData.netScore?.toFixed(1) : 'N/A'}}/{{demoTestData.total}}</span></p>  
                        </div>
                    </div>
                    <div class="pentagon" v-if="userType !== 'teacher'"
                    @click="$router.push({ path: `/interviewReport/${campaignId}/${userId}` })"> 
                    <div class="pentCont">
                        <div class="pentRow league-title"> 
                            <p>Interview</p>
                        </div >
                          <p class="fs-12">score - <span class="ff-bold">{{  parseInt(interviewAvgScore) >= 0 ?  interviewAvgScore?.toFixed(1) : 'N/A'}}</span></p>
                    </div>
                </div>
                </div>
                <div class="common-border bg-white" v-if="radarChartData.datasets.length > 0">
                    <div class="d-flex justify-space-between pa-4" >
                        <p>Cumulative Graph</p>    <v-btn-toggle
                        v-model="chartType"
                        mandatory
                        dense
                        rounded
                      >
                        <v-btn
                          width="130px"
                          :class="
                          chartType == 0
                              ? 'btn-color white--text text-subtitle-2 '
                              : '#000000 btn-color--text text-subtitle-2'
                          "
                        >
                          RADAR CHART
                        </v-btn>
                        <v-btn
                          width="100px"
                          :class="
                          chartType == 1
                              ? 'btn-color white--text text-subtitle-2'
                              : '#000000 btn-color--text text-subtitle-2'
                          "
                        >
                          SCORE
                        </v-btn>
                      </v-btn-toggle>
                    </div>
            
                
                    <div class="cumChartDiv" v-if="chartType == 0">
                        <MultiLayerRadarChart  :chartData="radarChartData" :id=1 />
                    </div>
                    <div v-if="chartType == 1">
                        <div class="pa-2 mt-2">
                            <v-row>
                                <v-col cols="4" ></v-col>
                                <v-col >Screening</v-col>
                                <v-col>Mains</v-col>
                                <v-col>Demo</v-col>
                                <v-col v-if="userType !== 'teacher'">Interview</v-col>
                            </v-row>
                            <v-row v-for="(subject, index) in allSubjectScore" :key="index"  class="score-data-table align-center"
                            justify="center">
                                <v-col class="league-name " cols="4"  >{{subject.name}}</v-col>
                                <v-col >  {{subject.screeningMarks | marksformatter }}</v-col>
                                <v-col > {{subject.mainsMarks | marksformatter}} </v-col>
                                <v-col > {{subject.demoMarks   | marksformatter}}</v-col>
                                <v-col v-if="userType !== 'teacher'">{{subject.interviewMarks   | marksformatter}}</v-col>
                            </v-row>
                         
                        </div>
                    </div>
                </div>
                <div class="common-border bg-white chart-height d-flex justify-center align-center"  v-else>
                     <p class="pa-2  league-title">Chart Data not available</p>
                </div>
            </div>

                <div class="repLoDivRight">
                    <div class="league-container" >
                        <v-card width="100%" class="rounded-l" outlined v-if="leagueData?.length > 0">
                            <div class="d-flex justify-space-between"><p class="league-title pt-3 pl-3">League</p> <p class="league-info-label fs-12 pt-2 pr-3">*League is based on MAINS score</p></div>
                            <v-row class="mt-4">
                                <v-col class="pa-1 fs-14 text-start">Subject</v-col>
                                <v-col class="pa-1 fs-14 text-center">Rank</v-col>
                                <v-col class="pa-1 fs-14 text-end">Score</v-col>
                                <v-col class="pa-1 fs-14 text-end">League</v-col>
                            </v-row>
                            <hr>
                            <v-row v-for="(league, index) in leagueData" :key="index" align="center"
                        justify="center" class="pl-2 pt-0">
                            <v-col class="pa-1 fs-14"  >{{league.subjectName}}</v-col>
                            <v-col  class="text-center league-score-rank pa-1 fs-14"> Rank - #{{league.rank}}</v-col>
                            <v-col  class="text-right league-score-rank pa-1 fs-14" >Score -  {{ ((league.score / league.maximumMarks)*10).toFixed(1) }}/10</v-col>
                            <v-col class="text-right pa-1 pr-2 fs-14" >
                                <v-tooltip top >
                                    <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">                                        
                                        <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.641 20.5703H5.35494C4.40978 20.5703 3.64062 21.3395 3.64062 22.2846C3.64062 23.2298 4.40978 23.999 5.35494 23.999H23.641C24.5862 23.999 25.3553 23.2298 25.3553 22.2846C25.3553 21.3395 24.5862 20.5703 23.641 20.5703Z" :fill="crownColorMap[league.league.toLowerCase()]"/>
                                        <path d="M26.4972 3.42669C25.2366 3.42669 24.2114 4.45187 24.2114 5.71245C24.2114 6.55934 24.68 7.29189 25.3668 7.68732C24.0445 10.8188 21.9816 12.74 20.0536 12.5663C17.9095 12.3914 16.1609 9.8565 15.1963 5.61529C16.4329 5.30211 17.3541 4.18781 17.3541 2.85525C17.3541 1.27921 16.0729 -0.00195312 14.4969 -0.00195312C12.9208 -0.00195312 11.6397 1.27921 11.6397 2.85525C11.6397 4.18786 12.5608 5.30217 13.7974 5.61529C12.8328 9.8565 11.0842 12.3914 8.94018 12.5663C7.02017 12.74 4.94808 10.8188 3.62696 7.68732C4.31381 7.29189 4.7824 6.55928 4.7824 5.71245C4.7824 4.45187 3.75721 3.42669 2.49664 3.42669C1.23612 3.42669 0.210937 4.45187 0.210937 5.71245C0.210937 6.88507 1.10236 7.84279 2.24066 7.97304L4.44188 19.427H24.552L26.7531 7.97304C27.8915 7.84279 28.7829 6.88507 28.7829 5.71245C28.7829 4.45187 27.7577 3.42669 26.4972 3.42669Z" :fill="crownColorMap[league.league.toLowerCase()] || '#000'"/>
                                    </svg>
                                    </span>
                                    </template>
                                    {{league.league ?? 'NA' }}
                                </v-tooltip>
                                

                            </v-col>
                        </v-row>
                        </v-card>
                        <v-card width="100%" class="rounded-l" outlined  v-else>
                            <div class="d-flex justify-center align-center">
                        <p class="league-title pa-12">League data not available</p>
                        </div>
                        </v-card>
                    </div>
                  
                    <div class="league-container">
                   
                    <v-card outlined class="mt-2" v-if="userReportData?.videoData?.length > 0">     
                        <div class="d-flex flex-column common-border">
                            <p class="pa-2  league-title" >Cumulative Demo video Score</p>
                            <div class="d-flex gap-8p ml-2 mb-2">
                                <div  class="progress-circular-item" v-for="(item, index) in circularProgressChart" :key="index">
                                    <v-progress-circular
                                    class="v-progress-circular__overlay"
                                    :value="item.score * 10"
                                    :rotate="-90"
                                    :size="100"
                                    :width="15"
                                    :color="item.score > 5 ? 'rgba(95, 156, 74, 1)': 'rgba(227, 146, 54, 1)'"
                                    >
                                 
                                     <span class="circular-progress-inner-text" v-if="item.score"> 
                                     {{ Number.isInteger(item.score) ? item.score : (item.score).toFixed(2) ?? 0}}  / 10
                                    </span>
                                    <span class="circular-progress-inner-text" v-else > 0 /10 </span>
                                    </v-progress-circular>
                                   <p class="mt-2">{{item.name}}</p>
                                </div>
                            </div> 
                        </div>
                    </v-card>
                    <v-card width="100%" class="rounded-l mt-3" outlined  v-else>
                            <div class="d-flex justify-center align-center">
                        <p class="league-title pa-12">Demo video data not available</p>
                        </div>
                        </v-card>
                    <p class="league-title pt-2" v-if="interviewData?.length > 0">Interviewer’s Remark</p>
                    <v-container class="pa-0" v-if="interviewData?.length > 0">
                        <v-row class="pa-0" >
                            <v-col cols="4" class="pl-0" v-for="(interview, index) in interviewData" :key="index">
                                <v-card class="h-100 rounded-l" elevation="1" outlined >
                                 <div class="pa-4">
                                    <div class="interviewer-name">
                                        <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                {{interview.interviewerEmail ? interview.interviewerEmail : '---' }}
                                            
                                            </span>
                                            </template>
                                            <div >{{interview.interviewerEmail ? interview.interviewerEmail : '---' }}</div>
                                        </v-tooltip>
                                        </div>
                                    <div class="interview-recommendation font-weight-bold "    :class="shortlistVal(interview.avgScore*10)?.remarkClass" >{{ interview.recommendation }} - {{interview.avgScore}}/10</div>
                                    <div class="interview-remark">   {{interview.remark | strippedContent}}  
                                    </div>
                                 </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card width="100%" class="rounded-l mt-3" outlined  v-else>
                            <div class="d-flex justify-center align-center">
                        <p class="league-title pa-12">Interviewer’s remark data not available</p>
                        </div>
                        </v-card>   
                    </div> 
                </div>
                
            </div>
        </div>
        <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="800"
        :filename="psychometryReportFileName"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a3"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        ref="html2Pdf"
        color="true"
        @progress="onProgress($event)"
        >
            <section slot="pdf-content">
                <div class="dev-box" style="background: rgba(251, 245, 242, 1); font-size:14px !important; font-family: GraphikRegular; page-break-inside: avoid !important; padding:16px;">
                    <div style="font-size: 20px">Psychometry</div>
                    <div style="border: 1px solid rgba(0, 0, 0, 0.12); display: flex;  flex-direction: row; background: #FFFFFF; border-radius: 8px; padding: 4px;">
                        <div style="display: flex;  flex-direction: row; width: 25%; align-items:center">
                            <v-avatar size="69" style="margin: 8px">
                                <img v-if="proctoringPic !== ''" :src="proctoringPic" alt="Report User Pic">
                                 <img v-else  src="../assets/avtar.png" alt="Report User Pic"> 
                            </v-avatar>
                            <div style="display: flex;  flex-direction: column; width:100%">
                            <div style="margin-top:8px; font-size:14px;" class="reportTableText"> {{ userName }} </div>
                            <div style="margin-top:8px; white-space:wrap;"> {{ userEmail }} </div>
                            <div style="margin-top:8px;"> {{ userPhone }} </div>
                            </div>
                        </div>
                        <v-divider vertical></v-divider>
                        <div style="width: 35%; padding:16px">
                            <v-row>
                            <v-col cols="3" style="padding-right: 0px; padding-left:16px">
                                Level -
                            </v-col>
                            <v-col cols="9" style="padding: 4px 0px;">
                                <v-chip style="font-size:14px" v-for="(level, key) in levels" :key="key">{{ level }}</v-chip>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="3" style="padding: 8px 0px 8px 16px;">
                                Subjects -
                            </v-col>
                            <v-col cols="9" style="display: flex;  flex-direction: row; padding: 0px;">
                                <v-chip
                                v-for="(subject, index) in subjects"
                                :key="index" style="font-size:14px; margin-right:2px;">
                                {{ subject }}
                                </v-chip>
                            </v-col>
                            </v-row>
                        </div>   
                        <v-divider vertical></v-divider>      
                        <div style="width: 20%; display: flex;  flex-direction: column; justify-content: space-around; padding: 0px 16px">
                            <div style="display: flex;  flex-direction: row; justify-content: space-between; padding-top: 4px;">
                            <div style="font-weight:400;">
                                Total Experience-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.totalExperience?.years !== undefined?
                                    `${interviewDetails?.candidateDetails?.totalExperience?.years} yrs` : "" }}  
                                {{ interviewDetails?.candidateDetails?.totalExperience?.months !== undefined?
                                    `${interviewDetails?.candidateDetails?.totalExperience?.months} mo` : "-"}}
                            </div>
                            </div>
                            <div style="display: flex !important; flex-direction: row flex !important; justify-content: space-between; padding-top: 4px;">
                            <div style="font-weight:400;">
                                Relevant Experience-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.relevantExperience?.years !== undefined?
                                    `${interviewDetails?.candidateDetails?.relevantExperience?.years} yrs` : "" }}
                                {{ interviewDetails?.candidateDetails?.relevantExperience?.months !== undefined?
                                    `${interviewDetails?.candidateDetails?.relevantExperience?.months} mo` : "-"}}
                            </div>
                            </div>
                            <div style="display: flex !important;  flex-direction: row !important; justify-content: space-between; padding-top: 4px;">
                            <div style="font-weight:400;">
                                Notice Period-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.noticePeriod !== undefined?
                                    interviewDetails?.candidateDetails?.noticePeriod : "-"}}
                            </div>
                            </div>
                        </div>
                        <v-divider vertical></v-divider>      
                        <div style="width: 20%; display: flex;  flex-direction: column; justify-content: space-around; padding: 0px 16px">
                            <div style="display: flex;  flex-direction: row; justify-content: space-between;">
                            <div style="font-weight:400;">
                                Current CTC-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.currentCTC !== undefined?
                                    `${interviewDetails?.candidateDetails?.currentCTC} LPA` : "-"}}
                            </div>
                            </div>
                            <div style="display: flex;  flex-direction: row; justify-content: space-between;">
                            <div style="font-weight:400;">
                                Expected CTC-
                            </div>
                            <div class="reportTableText" style="font-size:14px; white-space:nowrap;">
                                {{interviewDetails?.candidateDetails?.expectedCTC !== undefined?
                                    `${interviewDetails?.candidateDetails?.expectedCTC} LPA` : "-"}}
                            </div>
                            </div>
                    </div>
                    </div>
                    <div style="background: #FFFFFF; border-radius: 8px; margin-top:16px; padding:8px;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px">
                        Self-awareness
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.selfAwareness?.score)" style="display: flex;  flex-direction: row; border-radius: 8px; padding:4px;">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; justify-content: center; width: 25%; white-space: nowrap; padding:0px 12px; font-size:14px;">
                        <div style="margin: 4px 0px">
                            Self Motivation
                        </div>
                        <div class="margin: 4px 0px">
                            Self-concept
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left:auto; padding: 0px 16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.selfAwareness?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left: auto">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding: 8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                            {{ normalizedStrandsScore?.selfAwareness?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div class="mt-2 pa-2" style="background-color: #FFFFFF; border-radius: 8px; margin-top:16px; padding:8px !important;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px !important">
                        Social Awareness
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.socialAwareness?.score)" style="display: flex;  flex-direction: row; border-radius: 8px; padding:4px">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; width: 25%; white-space: nowrap; padding:0px 12px; font-size:14px">
                        <div style="margin: 4px 0px">
                            Adaptability
                        </div>
                        <div style="margin: 4px 0px">
                            Behavior Monitoring	
                        </div>
                        <div style="margin: 4px 0px">
                            Emotional Regulation
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left: auto; padding: 0px 16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.socialAwareness?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto;">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding:8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                            {{ normalizedStrandsScore?.socialAwareness?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div style="background-color: #FFFFFF; border-radius: 8px; margin-top:16px; padding:8px !important;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom: 4px !important">
                        Self Management
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.selfManagement?.score)" style="display: flex;  flex-direction: row; border-radius: 8px; padding:4px;">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; width: 25%; white-space: nowrap; padding:0px 12px; font-size:14px">
                        <div style="margin: 4px 0px">
                            Organizational Awareness
                        </div>
                        <div style="margin: 4px 0px">
                            Emotional Perception
                        </div>
                        <div style="margin: 4px 0px">
                            Empathetic Concern 
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left:auto; padding: 0px 16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.selfManagement?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card  style="font-size: 14x; padding: 8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                            {{ normalizedStrandsScore?.selfManagement?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div style="background-color: #FFFFFF; border-radius: 8px; padding:8px; margin-top:16px;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px;">
                        Responsible decision making
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.responsibleDecisionMaking?.score)" style="display: flex;  flex-direction: row; border-radius:8px; padding:4px">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; white-space: nowrap; width: 25%; padding:0px 12px; font-size:14px">
                        <div style="margin: 4px 0px">
                            Ethical Dilemma
                        </div>
                        <div style="margin: 4px 0px">
                            Reasoning Skills
                        </div>
                        <div style="margin: 4px 0px">
                            Critical thinking
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left: auto; padding: 0px 16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.responsibleDecisionMaking?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding:8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                           {{ normalizedStrandsScore?.responsibleDecisionMaking?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div style="background-color: #FFFFFF; border-radius: 8px; margin-top:16px; padding:8px"> 
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px;">
                        Relationship Skills
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.relationshipSkills?.score)" style="display: flex;  flex-direction: row; align-items: center; border-radius:8px; padding:4px">
                        <div class="reportTableText" style="display: flex; flex-direction: column; width: 25%; white-space: nowrap; padding:0px 12px; font-size:14px">
                        <div style="margin: 4px 0px">
                            Collaboration
                        </div>
                        <div style="margin: 4px 0px">
                            Emotional Maturity
                        </div>
                        <div style="margin: 4px 0px">
                            Interpersonal Skills
                        </div>
                        <div style="margin: 4px 0px">
                            Intrapersonal Skills
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; margin-left:auto; padding: 16px;">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.relationshipSkills?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto;">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding: 8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                           {{ normalizedStrandsScore?.relationshipSkills?.feedback }} 
                        </v-card>
                        </div>
                    </div>
                    </div>
              
                    <div style="background-color: #FFFFFF; border-radius: 8px; margin-top: 16px; padding: 8px;">
                    <div style="font-size: 20px; font-family: GraphikRegular; font-weight:600; padding-bottom:4px;">
                        Personality
                    </div>
                    <div :class="selectBackgroundColor(normalizedStrandsScore?.personality?.score)" style="display: flex;  flex-direction: row;  border-radius:8px; padding:4px;">
                        <div class="reportTableText" style="display: flex;  flex-direction: column; width: 25%; white-space: nowrap; padding:0px 12px;; font-size:14px">
                        <div style="margin: 4px 0px">
                            Conscientiousness
                        </div>
                        <div style="margin: 4px 0px">
                            Emotional Stability
                        </div>
                        <div style="margin: 4px 0px">
                            Extraversion
                        </div>
                        <div style="margin: 4px 0px">
                            Openness
                        </div>
                        <div style="margin: 4px 0px">
                            Teacher Effectiveness
                        </div>
                        </div>
                        <v-divider vertical></v-divider> 
                        <div style="display: flex;  flex-direction: row; align-items: center; margin-left:auto; padding:16px">
                        <div v-for="rate in 4":key="rate"
                            :class="selectRating(rate, normalizedStrandsScore?.personality?.score)">
                            {{ rate }}
                        </div>
                        </div>
                        <div style="display: flex;  flex-direction: column; width: 50%; margin-left:auto">
                        <div style="padding: 4px 4px; color: rgba(97, 97, 97, 0.87);">Feedback</div>
                        <v-card style="font-size: 14px; padding: 8px; margin-bottom:8px; height:100%; width:98%; border: 1px solid #0000002E">
                            {{ normalizedStrandsScore?.personality?.feedback }}
                        </v-card>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </VueHtml2pdf>  
    </div>
</template>

<script>
import AuthService from "../services/AuthService";
import MeterChart from "../components/ReportGraphs/MeterChart.vue"
import ColChart from "../components/ReportGraphs/ColChart.vue"
import MultiLayerRadarChart from "../components/ReportGraphs/MultiLayerRadarChart.vue"
import CampaignController from '@/controllers/CampaignController';
import axios from "axios"
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.js";
import VueHtml2pdf from "vue-html2pdf";

export default {
    components: {
        MeterChart,
        ColChart,
        MultiLayerRadarChart,
        VueHtml2pdf,
    },
   filters: {
     marksformatter: function(value){
        var decimalRegex = /^\d*\.?\d+$/;
        var isDecimal = decimalRegex.test(value);

        if(value == '-'){
            return value
        } else if( value > 0 && value < 10 && !isDecimal) {
        return `0${Number.parseFloat(value).toFixed(1)}/10` 
        } else {
           return `${Number.parseFloat(value).toFixed(1)}/10`
        }
     },
     strippedContent: function(string) {
           return string.replace(/<\/?[^>]+>/ig, " "); 
        },
   },
 
    data() {
        return {
            downloadLoader:false,
            disableDownload:true,
            subjects:[],
            userData: null,
            normalizedStrandsScore: {},
            psychometryScoreMap : new Map([
                ["Self-Management", new Map([
                    [this.withinScoreRange(0, 7), {score: 1, feedback:"Likely to need significant assistance with adaptability, behavior monitoring, and emotional regulation. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(8,15), {score: 2, feedback:"Likely to demonstrate some adaptability, behavior monitoring, and emotional regulation skills, with room for improvement."}],
                    [this.withinScoreRange(16,21), {score: 3, feedback:"Likely to show proficiency in adapting to change, consistent behavior monitoring, and effective emotional regulation."}],
                    [this.withinScoreRange(22,25), {score: 4, feedback:"Likely to demonstrate exceptional adaptability, effective behavior monitoring, and strong emotional regulation skills."}]
                ])],
                ["Relationship Skills", new Map([
                    [this.withinScoreRange(0,7), {score: 1, feedback:"Likely to need significant assistance in building effective relationships, engaging in collaborative efforts, and demonstrating emotional maturity. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(8,15), {score: 2, feedback:"Likely to demonstrate some ability in collaboration, interpersonal skills, and emotional maturity, with inconsistent application."}],
                    [this.withinScoreRange(16,21), {score: 3, feedback:"Likely to show proficiency in collaboration, interpersonal skills, and emotional maturity, with potential for further growth."}],
                    [this.withinScoreRange(22,25), {score: 4, feedback:"Likely to build and maintain effective collaborations, demonstrate emotional maturity, and engage positively in interpersonal dynamics."}]
                ])],
                ["Social Awareness", new Map([
                    [this.withinScoreRange(0,9), {score: 1, feedback:"Likely to need significant assistance with organizational awareness, emotional perception, and empathy. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(10,19), {score: 2, feedback:"Likely to demonstrate basic organizational awareness, emotional perception, and empathy, with inconsistencies in application."}],
                    [this.withinScoreRange(20,25), {score: 3, feedback:"Likely to show good organizational awareness, emotional perception, and empathetic concern, fostering positive interactions."}],
                    [this.withinScoreRange(26,30), {score: 4, feedback: "Likely to display exceptional organizational awareness, accurate emotional perception, and genuine empathetic concern."}]
                ])],
                ["Responsible decision making", new Map([
                    [this.withinScoreRange(0,5), {score: 1, feedback:"Likely to need significant assistance with ethical reasoning, reasoning skills, and critical thinking. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(6,11), {score: 2, feedback:"Likely to show potential in ethical reasoning, reasoning skills, and critical thinking, but application is inconsistent in complexity."}],
                    [this.withinScoreRange(12,16), {score: 3, feedback:"Likely to demonstrate proficiency in ethical decision-making, reasoning, and critical thinking, with occasional need for further analysis."}],
                    [this.withinScoreRange(17,20), {score:4, feedback:"Likely to consistently apply ethical reasoning, critical thinking, and effective decision-making skills in complex situations."}]
                ])],
                ["Personality", new Map([
                    [this.withinScoreRange(0, 7), {score: 1, feedback:"Likely to need significant assistance with developing personality traits that support effective interactions and performance. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(8,15), {score:2 , feedback:"Likely to show potential in exhibiting positive personality traits, but consistency and depth of application vary."}],
                    [this.withinScoreRange(16,21), {score: 3, feedback:"Likely to demonstrate positive personality traits that support effective interactions and performance, with areas for enhancement."}],
                    [this.withinScoreRange(22,25), {score:4, feedback:"Likely to exhibit conscientiousness, emotional stability, extraversion, openness to experience, and positively influence interactions."}]
                ])],
                ["Self-Awareness", new Map([
                    [this.withinScoreRange(0,9), {score: 1, feedback: "Likely to demonstrate limited self-awareness and understanding of personal emotions, impacting overall self-awareness growth. Further assessment needed if due to unattempted questions."}],
                    [this.withinScoreRange(10,19), {score: 2, feedback: "Likely to display basic self-awareness and understanding of personal emotions, with potential for further development."}],
                    [this.withinScoreRange(20,25), {score: 3, feedback: "Likely to show strong self-motivation, awareness of strengths and weaknesses, and consistent pursuit of personal goals."}],
                    [this.withinScoreRange(26,30), {score: 4, feedback: "Likely to demonstrate proactive initiative, optimism, and a deep understanding of self-concept and emotions."}]
                ])]
            ]),
            interviewDetails: null,
            crownColorMap: {
                gold: 'rgba(219, 212, 36, 1)',
                silver: 'rgb(192, 192, 192)',
                bronze: 'rgba(174, 173, 145, 1)',
                bonze: 'rgba(174, 173, 145, 1)'
            },
            leagueData: [],
            preloader: true,
            userReportData: {},
            chartType: 0,
            userName: '',
            userType:'',
            userEmail: '',
            userPhone: '',
            schoolName: '',
            levels: [],
            screenUserScore: null,
            screenTotalScore: null,
            screenRank: null,
            screenPercentile: null,
            mainsUserScore: null,
            demoUserScore: null,
            demoTotalScore: null,
            demoRank: null,
            demoPercentile: null,
            circularProgressChart: [],
            knowledgeLevel: null,
            confidenceLevel: null,
            behavioralLevel: null,
            fluencyLevel: null,
            duration: null,
            videoName: '',
            videoUrl: '',
            campaignId: '',
            userId: '',
            isDemo: false,
            subjectsMarks: [['Subjects', 'Screening', 'Mains']],
            interviewSubMap: new Map(),
            skillsMarks: [['Subjects', 'Screening', 'Mains']],
            cumulativeData: { labels: [], mainsData: [], screeningData: [], demoData: [], interviewData: [] },
            radarChartData: {labels: [], datasets: [] },
            selectedCumulativeData: {labels:[], data:[], label:""},
            colorsArray: ["#FFCCCC", "#FFD9D9", "#FFE6E6", "#FFEFD5", "#FFF5E1", "#FFFAF0", "#F0FFF0", "#E0FFEB", "#CCFFCC", "#B3FFB3"],
            recommendationColor: "background",
            recommendationTextColor:"#FF2600",
            recommendationText: "N/A",
            proctoringPic: "",
            meterColor: ["#D40028", "#F33709", "#F8B300", "#FADC00", "#8AD321", "#44BC37"],
            selectedTest: 0,
            screeningTestData: {},
            mainsTestData: {},
            demoTestData: {},
            interviewData:[
            ],
            myChart:"",
            allSubjectScore: [],
            meterChartData: {
                system: {
                            data: [20, 40, 60, 80, 100],
                            value: 0,
                            backgroundColor:  ['rgba(215, 86, 81, 1)', 'rgba(233, 182, 94, 1)', 'rgba(234, 228, 103, 1)', 'rgba(186, 206, 106, 1)', 'rgba(95, 159, 104, 1)'],
                            borderWidth: 0,
                           
                        },
                interview: {
                            data: [20, 40, 60, 80, 100],
                            value: 0,
                            backgroundColor:  ['rgba(215, 86, 81, 1)', 'rgba(233, 182, 94, 1)', 'rgba(234, 228, 103, 1)', 'rgba(186, 206, 106, 1)', 'rgba(95, 159, 104, 1)'],
                            borderWidth: 0,
                           
                        },
                final: {
                            data: [40, 60, 100],
                            value: 0,
                            backgroundColor:  ['rgba(215, 86, 81, 1)', 'rgba(234, 228, 103, 1)', 'rgba(95, 159, 104, 1)'],
                            borderWidth: 0,
                           
                        },
            },
            interviewPercent:0,
            interviewAvgScore:0,
            subChipIndex: 0,
            campaignName:""

        }
    },

    computed:{
        psychometryReportFileName(){
            let userName = this.userName.split(" ");
            let firstName = userName[0];
            let lastName = userName[1];
            return `${firstName}_${lastName}_${this.levels[0]}_psychometry_report`
        }
    },

    async mounted() {
        this.campaignId = this.$route.query.campaignId;
        this.userId = this.$route.query.userId;
        await this.getReportData();
        this.getCampaignDetails();
        await this.getPsychometryResult();
    },
    methods: {
        async getPsychometryResult(){
            try{
                let response = await CampaignController.getResult(this.userId, this.campaignId);
                await this.getInterviewDetails()
                if ( response?.data?.data ) {
                    response = response.data.data;
                    if( response.length > 1) {
                        let mainsData  = response.filter(item=>item.testType=='MAINS').pop();
                        let psychometryData = mainsData.skills.filter(item=>item.skillName=='Psychometry').pop();
                        let questionsData = psychometryData.questions;
                                    
                        let strandsData = Object.groupBy( questionsData , ({strand})=>strand)
                        let strandsScore = {};
                        for (const strand in strandsData){
                            strandsScore[strand] = this.strandScoreSum(strandsData[strand]);
                        }
                        for (const strand of Object.keys(strandsScore)){
                            if(this.psychometryScoreMap.has(strand)){
                                const score = strandsScore[strand];
                                for (const [checkScoreRange,newScore] of this.psychometryScoreMap.get(strand)){
                                    if (checkScoreRange(score)){
                                        this.$set(this.normalizedStrandsScore, this.camelCase(strand), newScore);
                                        break;
                                    }
                                }
                            }
                        }
                        this.disableDownload = false;
                    }
                }
            }
            catch(error){
                console.log(error);
            }
            
        },
        async getInterviewDetails(){
            try {
                const response = await CampaignController.getInterviewReport(this.userId, this.campaignId);
                if (response.data?.result) {
                    this.interviewDetails = response.data?.result?.interviewDetails;
                } else {
                    console.log('response data error...', response.data.error)
                }
            } catch (err) {
                console.log(err);
            }
        },
        withinScoreRange(min, max){
            return score => { return (min <= score && score <= max)}
        },
        strandScoreSum(strand){
            let strandScores = strand.map(question => 
                question.myAnswer != undefined?
                 (question.questionOptions.filter(opt=>opt.optionKey === question?.myAnswer[0]).pop()?.optionScore) : 0
            );
            return strandScores.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
        },
        camelCase(str) {
            str = str.toLowerCase().replace(/-/g, ' ');
            return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
                return index === 0 ? word.toLowerCase() : word.toUpperCase();
            }).replace(/\s+/g, '');
        },
        onProgress(event) {
            if (event === 100) {
                this.downloadLoader = false;
            }
        },
        generateReport() {
            this.downloadLoader = true;
            this.$refs.html2Pdf.generatePdf();
        },
        selectRating(rating, ratingValue) {
            switch (rating) {
                case 1:
                return ratingValue >= 1
                    ? "interviewRate1 white--text"
                    : "interviewRate";
                case 2:
                return ratingValue >= 2
                    ? "interviewRate4"
                    : "interviewRate";
                case 3:
                return ratingValue >= 3
                    ? "interviewRate6"
                    : "interviewRate";
                case 4:
                return ratingValue >= 4
                    ? "interviewRate9"
                    : "interviewRate";
                default:
                return "interviewRate";
            }
        },
        selectBackgroundColor(score){
            let backgroundColorMap = new Map([
                [1, "backgroundRed"],
                [2, "backgroundOrange"],
                [3, "backgroundYellow"],
                [4, "backgroundGreen"]
            ])
            return backgroundColorMap.get(score);
        },
        async getCampaignDetails(){
                const campaignRef = doc(db,"campaigns",this.campaignId);
                const campaignSnap = await getDoc(campaignRef);
                const campaignData = campaignSnap.data()
                this.campaignName = campaignData.name;
                return campaignData
            },
        setChipIndex(index){
            this.subChipIndex = index
            this.meterChartData['system'].value = this.leagueData[index].percentile;
            this.meterChartData['interview'].value = this.interviewSubMap.get(this.leagueData[index].subjectName)
            this.meterChartData['final'].value =  (this.leagueData[this.subChipIndex].percentile + this.meterChartData.interview.value) / 2;
            this.$refs['chart-one'].myGauge.update()
            this.$refs['chart-two'].myGauge.update()
            this.$refs['chart-three'].myGauge.update()
        },
        getNewSpiderChartData(val) {
            if (val===0){
                // this.selectedCumulativeData.labels.splice(0)
                // this.selectedCumulativeData.labels.push(...this.cumulativeData.labels)

                // this.selectedCumulativeData.splice(0)
                // this.selectedCumulativeData.data.push(...this.cumulativeData.mainsData)
                // this.selectedCumulativeData.data=this.cumulativeData.mainsData

                // this.selectedCumulativeData.label="Mains"

                this.selectedCumulativeData.labels=this.cumulativeData.labels
                this.selectedCumulativeData.data=this.cumulativeData.mainsData
                this.selectedCumulativeData.label="Mains"
            } else {
                // this.selectedCumulativeData.labels.splice(0)
                // this.selectedCumulativeData.labels.push(...this.cumulativeData.labels)

                // this.selectedCumulativeData.splice(0)
                // this.selectedCumulativeData.data.push(...this.cumulativeData.screeningData)

                this.selectedCumulativeData.labels=this.cumulativeData.labels
                this.selectedCumulativeData.data=this.cumulativeData.screeningData
                this.selectedCumulativeData.label="Screening"
            }
        },
        logout() {
            AuthService.logout();
            this.$router.push("/login");
        },

        getColor() {

                if (this.mainsUserScore < 4) {
                    this.recommendationText = "Not Recommended"
                    this.recommendationTextColor = "#FF2600"
                    this.recommendationColor="#FFE9E5"
                } else if (this.mainsUserScore >= 4 && this.mainsUserScore < 6) {
                    this.recommendationText = "Below Standard"
                    this.recommendationTextColor = "#E39236"
                    this.recommendationColor="#FCF3E9"
                } else if (this.mainsUserScore >= 6 && this.mainsUserScore < 7.5) {
                    this.recommendationText = "Moderately Recommended"
                    this.recommendationTextColor = "#97AD39"
                    this.recommendationColor="#E5ECC5"
                } else if (this.mainsUserScore >= 7.5 && this.mainsUserScore < 9) {
                    this.recommendationText = 'Highly Recommended'
                    this.recommendationTextColor = "#5F9C4A"
                    this.recommendationColor="#D2E6CB"
                } else {
                    this.recommendationText = "Strongly Recommended"
                    this.recommendationTextColor = "#56903E"
                    this.recommendationColor="#CFE2D2"
                }
            
        },
        nullCheck(item) {
        if(item == null || item == undefined || item == '' ){
            return "-"
        } else { 
            return item
        }
        },
        calculateInterviewAvgAndPercent(data){
         data.forEach(element => {
            this.interviewAvgScore += parseFloat(element.avgScore);    
        });
        this.interviewAvgScore = this.interviewAvgScore / data.length
        this.interviewPercent = this.interviewAvgScore * 10;
        this.meterChartData['interview'].value = this.interviewPercent || 0;
        
        },
        shortlistVal(value, isFinal = false){
            if(isFinal) {
                if(value < 40 ){
                    return {text:'Not Recommended',
                    lableClass: 'not-recommended',
                    remarkClass:  'not-recommended-remark' 
                    };
                } else if(value >= 40 && value < 60){
                    return   {text:'On Hold',
                    lableClass: 'below-standard',
                    remarkClass:  'below-standard-remark' 
                    };
                } else {
                    return  {text:'Recommended',
                    lableClass: 'strongly-recommended',
                    remarkClass:  'strongly-recommended-remark' 
                    }
                }
            } else {
                if(value < 40 ){
                    return {text:'Not Recommended',
                    lableClass: 'not-recommended',
                    remarkClass:  'not-recommended-remark' 
                    };
                } else if(value >= 40 && value < 60){
                    return   {text:'Below Standard',
                    lableClass: 'below-standard',
                    remarkClass:  'below-standard-remark' 
                    };
         
                } else if(value >= 60 && value < 75){
                    return  {text:'Moderately Recommended',
                    lableClass: 'moderately-recommended',
                    remarkClass:  'moderately-recommended-remark' 
                    };
                } else if(value >= 75 && value < 90){
                    return  {text:'Highly Recommended',
                    lableClass: 'highly-recommended',
                    remarkClass:  'highly-recommended-remark' 
                    };
                } else if(value >= 90){
                    return  {text:'Strongly Recommended',
                    lableClass: 'strongly-recommended',
                    remarkClass:  'strongly-recommended-remark' 
                    };
                }
            }
                   
        },
        shortlistClass(value){
            if(value < 40 ){
                return 'Not Recommended';
            } else if(value >= 40 && value < 60){
                return 'Below Standard';
            } else if(value >= 60 && value < 75){
                return 'Moderately Recommended';
            } else if(value >= 75 && value < 90){
                return 'Highly Recommended';
            } else if(value >= 90){
                return 'Strongly Recommended';
            }
        },
        calculateSubWiseInterviewPercent(){
            let subjects = this.leagueData?.map(item => item.subjectName);
            subjects?.forEach((subject)=> {
                let subPercent = []
                this.interviewData?.forEach((interview)=> {
                    let subjectData = interview.subjects.filter((interviewsub)=> interviewsub.subjectName == subject)   
                    subPercent.push(this.ratingSum(subjectData[0].feedback) / subjectData[0].feedback.length * 100 / 10)
                })
               this.interviewSubMap.set(subject,  this.sumPercentValues(subPercent) / subPercent.length)
            })
        },
        ratingSum(feeback){
           return feeback.reduce((acc, cur)=> {
                return acc + cur.rating
            },0)
        },
        sumPercentValues(data){
          return data.reduce((acc, cur )=> acc+ cur,0)
        },
        async getReportData() {
            try {
                this.skillsMarks.splice(1);
                this.subjectsMarks.splice(1);
                this.cumulativeData.labels.splice(0)
                this.cumulativeData.mainsData.splice(0)
                this.cumulativeData.screeningData.splice(0)

                const response = await CampaignController.getReportData(this.userId, this.campaignId);
                if (response.data?.result) {
                    this.userReportData = response.data.result;
                    this.userType = this.userReportData.userType.toLowerCase();
                    this.subjects = this.userReportData.coreSkillsSubject.map(subject=>subject.subjectName)
                    this.leagueData = this.userReportData.coreSkillsSubject;              
                    this.meterChartData['system'].value = this.leagueData[this.subChipIndex].percentile;
                     this.interviewData = this.userReportData.interViewFeedback;
                     this.calculateSubWiseInterviewPercent()
                    this.calculateInterviewAvgAndPercent(this.interviewData)
                   
                    if(response.data?.result?.proctoringPic){
                        this.proctoringPic = response.data?.result?.proctoringPic
                    } 
                    for (const d of this.userReportData?.graphData) {
                        var maxMarks =d.maximumMarks;
                        if(d.mainsMaximumMarks){
                            maxMarks = d.mainsMaximumMarks ;
                        }
                        if(this.userType !== 'teacher'){
                            this.allSubjectScore.push({name: d.name, mainsMarks: this.nullCheck(((d.mainsMarks/maxMarks)*10).toFixed(2)), screeningMarks:  this.nullCheck(d.screeningMarks), demoMarks:  this.nullCheck(d.demoMarks), interviewMarks:  this.nullCheck(d.interviewMarks)})
                        } else{
                            this.allSubjectScore.push({name: d.name, mainsMarks: this.nullCheck(((d.mainsMarks/maxMarks)*10).toFixed(2)), screeningMarks:  this.nullCheck(d.screeningMarks), demoMarks:  this.nullCheck(d.demoMarks)})
                        }
                        if (d.isCoreSkill) {
                            this.subjectsMarks.push([d.name, d.screeningMarks, ((d.mainsMarks/d.maximumMarks)*10).toFixed(2)])
                        } else {
                            this.skillsMarks.push([d.name, d.screeningMarks, ((d.mainsMarks/d.maximumMarks)*10).toFixed(2)])
                        }
                        this.cumulativeData.labels.push(d.name)
                        this.cumulativeData.mainsData.push(d.mainsNormalizedScoreOutOfTen)
                        this.cumulativeData.screeningData.push(d.screeningMarks)
                        this.cumulativeData.demoData.push(d.demoMarks)  // getting random numbers to populate dummy data remove random num logic if we start to get actual data from the api 
                        this.cumulativeData.interviewData.push( d.interviewMarks) // getting random numbers to populate dummy data remove random num logic if we start to get actual data from the api
                        
                    }
                    this.radarChartData.labels = this.cumulativeData.labels;
                    this.radarChartData.datasets.push({
                        label: 'Screening',
                        data: this.cumulativeData.screeningData,
                        borderColor: 'rgba(139, 114, 255, 0.8)',
                        pointBackgroundColor: 'rgb(255, 99, 132)',
                        pointRadius: 0,
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'
                    }) 
                    this.radarChartData.datasets.push({
                        label: 'Mains',
                         data: this.cumulativeData.mainsData,
                        borderColor: 'rgba(31, 225, 58, 0.8)',
                        pointRadius: 0,
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'   
                    })
                    this.radarChartData.datasets.push({
                        label: 'Demo',
                         data: this.cumulativeData.demoData, 
                        borderColor: 'rgba(255, 223, 77, 1)',
                        pointRadius: 0,
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'   
                    })
                    if(this.userType !== 'teacher'){
                        this.radarChartData.datasets.push({
                        label: 'Interview',
                        data: this.cumulativeData.interviewData, 
                        borderColor: 'rgba(255, 114, 160, 1)',
                        pointRadius: 0,
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'   
                    })
                    }

                    this.userName = this.userReportData.name;
                    this.userEmail = this.userReportData.email;
                    this.userPhone = this.userReportData.phone;
                    this.schoolName = Array.isArray(this.userReportData.schoolName) ? this.userReportData.schoolName.join(',') : this.userReportData.schoolName;
                    this.displaySchoolName = Array.isArray(this.userReportData.schoolName) ? this.userReportData.schoolName.join(',').substr(0, 100) + '..' : this.userReportData.schoolName;
                    if(typeof this.userReportData.levels == 'string'){
                        this.levels.push(this.userReportData.levels)
                    } else {
                        this.levels = this.userReportData.levels
                    }
                    this.screenUserScore = this.userReportData.screeningTest.netScore;
                    this.screenTotalScore = this.userReportData.screeningTest.total;
                    this.screenPercentile = this.userReportData.screeningTest.percentile;
                    this.screenRank = this.userReportData.screeningTest.rank;
                    this.screeningTestData = this.userReportData.screeningTest    
                    this.mainsUserScore = this.userReportData.mainsTest.netScore;
                    this.meterChartData['final'].value =  (this.leagueData[this.subChipIndex].percentile + this.meterChartData.interview.value) / 2;
                    this.mainsTestData = this.userReportData.mainsTest
                    this.demoUserScore = this.userReportData.demoVideoTest.score;
                    this.demoTotalScore = this.userReportData.demoVideoTest.total;
                    this.demoPercentile = this.userReportData.demoVideoTest.percentile;
                    this.demoRank = this.userReportData.demoVideoTest.rank;
                    this.demoTestData = this.userReportData.demoVideoTest
                     this.circularProgressChart.push({ name: 'Knowledge Score', score: this.userReportData.cumulativeDemoTest?.knowledgeLevel}, 
                     {name: 'Confidence Score', score:this.userReportData.cumulativeDemoTest?.confidenceLevel},
                     {name: "Behavioral Score", score: this.userReportData.cumulativeDemoTest?.behaviorlLevel},
                     {name: 'Fluency Score',score:this.userReportData.cumulativeDemoTest?.fluencyLevel})

                    this.videoName = this.userReportData.videoData.name;
                    this.videoUrl = this.userReportData.videoData.videoUrl;
                    this.duration = this.userReportData.videoData.duration;
                    this.photoUrl=this.userReportData?.imageUrl;
                    this.checkPhotoUrl();


                } else {
                    console.log('response data error...', response.data.error)
                    alert("Something went wrong");
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.preloader = false;
            }
        },

        async checkPhotoUrl(){
            try{
                const res=await axios.get(this.photoUrl);
                if (res?.status!==200){
                    this.photoUrl=""
                }
            } catch(err){
                console.log("err :", err);
                this.photoUrl="";
            }
        },
    },
}
</script>
<style scoped>
.pentagon-teacher{
    width: 35.5%;
    padding:  1.3vw 1.3vw 1.3vw 2vw;
}

.dual-elipsiss{
    width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.v-btn-toggle .v-btn.v-btn.v-size--default {
    min-width: 100px;
    min-height: 0;
}
.recom-labe1-typo{
    color: rgba(0, 0, 0, 0.87);
    font-family: GraphikRegular;
    font-size: 12px;
}
.chart-height{
    height: 41vh
}
.interviewer-name{
    text-wrap: nowrap;
    width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.league-info-label{
    color: rgba(0, 0, 0, 0.6);
}
.interview-remark{
    overflow: auto;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    font-size: 12px;
    height: 11vh;
    scrollbar-width: none;
}
.not-recommended,.below-standard, .moderately-recommended, .highly-recommended, .strongly-recommended{
    min-width: 140px;
}
.not-recommended{
    color: #FF2600;
    background-color: #FFE9E5 ;
    border: 1px solid #FF2600;
}
.below-standard{
    color: #E39236;
    background-color: #FCF3E9;
    border: 1px solid #E39236;
}
.moderately-recommended{
    color:#97AD39;
    background-color: #E5ECC5;
    border: 1px solid #97AD39;
}
.highly-recommended{
    color:#5F9C4A;
    background-color: #D2E6CB;
    border: 1px solid #5F9C4A;  
}
.strongly-recommended{
    color: #56903E;
    background-color: #CFE2D2;
    border: 1px solid #56903E;
}
.br-right{
    border-right: 1px solid  rgba(217, 217, 217, 1);
}

.not-recommended-remark{
    color: #FF2600;
}
.below-standard-remark{
    color: #E39236;
}
.moderately-recommended-remark{
    color:#97AD39;
    min-width: 173px;
}
.highly-recommended-remark{
    color:#5F9C4A;
}
.strongly-recommended-remark{
    color: #56903E;
}

.ff-bold{
    font-family: GraphikMedium;
}
.fs-14{
    font-size: 14px; 
}
.fs-12{
    font-size: 12px; 
}
.sub-chip{
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 4px 0px 0px 4px;
}
.fs-10{
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    font-family: GraphikRegular;
 
}
.br-40{
    border-radius: 40px;
}
.final-br{
  border-radius: 8px;
  background-color: #fff; 
}
.active-br, .meter-container-br{
    background-color: rgba(77, 105, 91, 0.09);
}
.meter-container-br{
    border-radius: 0px 8px 8px 0px;
}
.chip-replace{
    font-family: GraphikRegular;
    font-size: 11px;
    padding: 5px;
    text-align: center;
    font-weight: 200;
    border-radius: 8px;
    text-transform: uppercase;
}
.gap-8p{
    gap: 8px
}
.row{
    margin: 0px;
    padding: 5px;
}
.col{
    padding: 7px;
    width: 160px;
}
.v-progress-circular__overlay {
     stroke-linecap: round;
 } 

 .metChartDiv v-chip{
    font-size: 10px;
 }

 .repUpDiv .row {
    padding: 0;
 }
/*
::-webkit-scrollbar {
    display: none;
  }
*/
.repUpDivLeft-brown-bdr {
  border: 1px solid rgba(134, 79, 32, 1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  padding: 0.6rem;
  background-color: white;
}
.interviewRate, .interviewRate1, .interviewRate2, .interviewRate3, .interviewRate4, .interviewRate5, .interviewRate6, .interviewRate7, .interviewRate8, .interviewRate9, .interviewRate10 {
    margin: 0 16px;
    color: white !important;
}
.reportTableText{
    font-family: "GraphikRegular" !important;
    font-weight: 500 !important; 
}
.backgroundRed{
    background-color: #CD406E1A !important;
}
.backgroundOrange{
    background-color: #FEE3CC !important;
}
.backgroundYellow{
    background-color: #FEF9D6 !important;
}
.backgroundGreen{
    background-color: #EBFAF3 !important;
}
</style>