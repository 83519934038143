<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>

  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Users</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center" style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover v-model="hover">
            <img src="../assets/bell 1.svg">
          </v-hover>
        </v-badge>

        <div>

          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad pb-8 px-8">
      <v-row class="py-0 pl-2">
        <v-card elevation="0" class="background mb-2" height="132px" width="200px">
          <v-btn to="/users" width="195px" height="40px" elevation="0"
            class="my-1 white rounded-xl text-subtitle-2 justify-start"><img class="mr-3"
              src="../assets/All Nav SVG.svg" /> ALL ({{
                this.allCount
              }})
          </v-btn>
          <v-btn to="/teachers" width="195px" height="40px" elevation="0"
            class="my-1 white rounded-xl text-subtitle-2 justify-start"><img class="mr-3"
              src="../assets/Section Icons.svg" /> VGOS ({{
                this.vgosCount
              }})
          </v-btn>
          <v-btn to="/jobseekers" active-class="highEmphasis" width="195px" height="40px" elevation="0" depressed outlined
            class="my-1 white rounded-xl text-subtitle-2 justify-start blue-border"><img class="mr-3"
              src="../assets/Job_Seeker.svg" />
            Jobseekers ({{ this.count }})
          </v-btn>
        </v-card>

        <div class="chart-row d-flex flex-row">
          <v-card width="33.3%" elevation="0" class="graph  align-center overflow-hidden">
            <div class="w-100 text-center data-not-ava">
              Data Not Available
            </div>
            <CampTotalPieChart class="z-index-2" @data-updated="handleValue" :graphData="completionStatusGraph"
              message="completion Status" />
          </v-card>


          <v-card width="33.3%" elevation="0" class="graph align-center overflow-hidden">
            <div class="w-100 text-center data-not-ava">
              Data Not Available
            </div>
            <CampTotalPieChartLevel class="z-index-2" @data-updated="handleData" :graphData="levelChartData"
              message="levels" />
          </v-card>

          <v-card width="33.3%" min-height="30%" elevation="0" class="graph pt-4">
            <span class="text-h7 mx-4  my-2 font-weight-medium">Hiring Status</span>
            <v-card height="32px" class="rounded py-1 px-3  mx-4 my-2 border-button" elevation="0" outline="0">Recommended
              for Hiring - <span style="font-size: 8px">2000</span></v-card>
            <v-card height="32px" class="rounded py-1 px-3 mx-4 my-2 border-button" elevation="0" outline="0">Rejected -
              <span>2000</span></v-card>
          </v-card>
        </div>
      </v-row>



      <v-row class="pl-0 mb-2 mr-n2 pr-0 w-100 margin-right-8" justify="space-between">
        <v-col cols="3" class="pt-1 mt-3 pb-0 px-0">
          <div class="text-h6 pt-3 pr-1">All Users - Job Seekers</div>
        </v-col>

        <v-col cols="9" class="px-0 margin-right-8 mx-0 mt-3 d-flex flex-row align-center justify-end">

          <v-card width="326px" class="elevation-0 transparent">
            <v-text-field solo label="Search here..." hide-details prepend-inner-icon="mdi-magnify" v-model="search"
              class="search-bar2 border-button rounded-pill mr-2 pl-0 ml-0" dense clearable></v-text-field>
          </v-card>


          <v-btn depressed rounded outlined class="border-button mx-2"
            @click="filterDialog = true"><v-icon>mdi-tune</v-icon>Filter</v-btn>

          <!-- <v-btn class="primary mx-2" rounded @click="filterDialog = true"><v-icon>mdi-tune</v-icon>Filter</v-btn> -->
          <v-btn class="border-button mx-2" outlined rounded @click="deleteDialog = true"
            :disabled="selected.length == 0"><v-icon>mdi-trash-can-outline</v-icon>Delete</v-btn><v-btn
            class="border-button mx-2" outlined rounded
            :disabled="selected.length == 0"><v-icon>mdi-email-sync-outline</v-icon>Resend Invite</v-btn>
          <v-btn depressed outlined rounded class="border-button mx-2"><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn>
          <v-btn @click="() => { formbtnBool = false; dialog = true; }" class="accent ml-2 margin-right-8" rounded
            depressed>
            <v-icon>mdi-plus</v-icon>
            <div class="pl-1">Create</div>
          </v-btn>
        </v-col>

        <v-dialog v-model="deleteDialog" max-width="366px" class="cdz" persistent>
          <v-card fluid>
            <v-container fluid class="pa-0">
              <v-card-text class="text-center">
                <v-container></v-container>
                <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>
                <p class="text-h5 pt-6 pb-0">Delete User</p>
                <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                  This action will permanently delete the item . This cannot be
                  undone
                </p>

                <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                  <v-btn depressed class="background black--text" large width="157px" rounded @click="() => {
                    deleteDialog = false;
                    formbtnBool = false;
                  }
                    ">CANCEL</v-btn>
                  <v-btn class="accent white--text" :loading="dLoading" depressed large width="157px" rounded
                    :disabled="selected.length == 0" @click="deleteUser(selected)">DELETE</v-btn>
                </div>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>



      <v-data-table fixed-header height="40vh" :loading="isLoading" v-model="selected" :headers="headers" :items="users"
        show-select :single-select="singleSelect" item-key="userId" :options.sync="options" :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
        }" :server-items-length="count" class="usertable rounded-table">
        <template v-slot:[`item.fullname`]="{ item }">
          {{ item.personalInfo.firstName + " " + item.personalInfo.lastName }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <img width="36px" height="36" @click="updateData(item)" class="cursor" src="../assets/edit.svg" />
            <img width="36px" height="36" class="cursor" src="../assets/userdelete.svg" />
          </div>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <!-- <template v-slot:[`item.updated_at`]="{ item }">
          {{ getDate(item.created_at) }}
        </template> -->
      </v-data-table>
      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass scroll-view">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div @click="clearFilter" class="text-body1 font-weight-bold black--text cursor">
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>

          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <!-- <v-tab class="black--text justify-start" active-class="activeTab">Invited on</v-tab> -->

              <v-tab class="black--text justify-start" active-class="activeTab">Status</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Screening test status</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Mains test status</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Role</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Level</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Subject</v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab">Cluster & School</v-tab>
            </div>



            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedStatusFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(value, index) in status" :key="index" elevated :value="value">
                      {{ value }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="screeningTestFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(s, index) in ScreeningTestStatus" :key="index" elevated :value="s">
                      {{ s }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="mainsTestFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(s, index) in ScreeningTestStatus" :key="index" elevated :value="s">
                      {{ s }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="roleTypeFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(s, index) in role" :value="s" :key="index" elevated>
                      {{ s }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="levelTypeFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(data, index) in tableLevels" :key="index" elevated :value="data.name">
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="subjectTypeFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(data, index) in subjectsData" :key="index" elevated :value="data.name">
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- cluster and school -->
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-card-title class="ml-0 pl-0"> Clusters </v-card-title>
                  <v-chip-group v-model="clusterTypeFilter" active-class="secondary" column :multiple="true">
                    <!-- @click="getClustersSchool" -->
                    <v-chip v-for="(data, index) in clustersSnap" :key="index" elevated :value="data">
                      {{ data }}
                    </v-chip>
                  </v-chip-group>

                  <v-card-title class="pb-4 ml-0 pl-0"> Schools </v-card-title>

                  <v-card width="326px" class="elevation-0 transparent">
                    <v-text-field solo label="Search here..." hide-details prepend-inner-icon="mdi-magnify"
                      v-model="searchSchool" class="search-bar2 border-button rounded-pill mx-2" dense
                      clearable></v-text-field>
                  </v-card>
                  <div class="d-flex flex-row justify-center">
                    <v-progress-circular class="mt-10" indeterminate
                      v-if="clustersSchool.length == 0 && clusterTypeFilter.length != 0" :size="76"></v-progress-circular>
                  </div>
                  <v-chip-group v-model="schoolTypeFilter" class="mt-4" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(data, index) in showClusterSchool" height="40px" class="w-100 rounded-lg"
                      :rounded="10" :key="index" elevated :value="data.name">
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div>
            <v-card-actions class="px-6 my-2">
              <v-spacer></v-spacer>
              <v-btn rounded outlined class="accent--text pa-4" @click="filterDialog = false">Cancel</v-btn>
              <v-btn rounded class="accent mx-4 pa-4" :loading="dLoading" @click="
                filterUser(
                  selectedStatusFilter,
                  screeningTestFilter,
                  mainsTestFilter,
                  roleTypeFilter,
                  levelTypeFilter,
                  subjectTypeFilter,
                  schoolTypeFilter
                )
                ">Apply</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" fullscreen hide-overlay class="cdz">
        <v-card class="background">
          <v-card-title class="fixBar background py-1">
            <v-toolbar elevation="0" class="background">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-toolbar-title class="text-h5">
                    {{ formbtn() }} Job Seekers</v-toolbar-title>
                </v-col>

              </v-row>
              <v-spacer></v-spacer>
              <v-btn class="float-right" icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
          </v-card-title>

          <v-card-text class="createUserPad">
            <v-container>
              <v-stepper v-model="e1">
                <v-stepper-header class="text-subtitle-2 secondaryAccent">
                  <v-stepper-step color="#4D695B" :complete="e1 > 1" step="1">
                    PERSONAL INFORMATION
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step color="#4D695B" :complete="e1 > 2" step="2">
                    ACADEMICS
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step color="#4D695B" step="3"> PROFESSIONAL INFO </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!------------------------------------------ STEP 1 ------------------------------------------>
                  <v-stepper-content step="1">
                    <v-form lazy-validation ref="step1">
                      <v-card elevation="0">
                        <v-row>
                          <v-col cols="12">
                            <v-card class="pa-4" elevation="0">
                              <v-row class="py-0">
                                <v-col cols="2" class="py-0"><v-select v-model="personalInfo.title" label="Title *"
                                    :items="['Ms', 'Mrs', 'Mr']" outlined class="rounded-xl"
                                    :rules="[(v) => !!v || 'Title is required']" required attach density="comfortable">
                                  </v-select></v-col>
                                <v-col cols="4" class="py-0"><v-text-field v-model="personalInfo.firstName" :rules="[
                                  (v) => !!v || 'First Name is required',
                                ]" required outlined label="First Name *" rounded
                                    class="rounded-xl"></v-text-field></v-col><v-col cols="3" class="py-0"><v-text-field
                                    v-model="personalInfo.middleName" outlined label="Middle Name" rounded
                                    class="rounded-xl"></v-text-field></v-col><v-col cols="3" class="py-0"><v-text-field
                                    v-model="personalInfo.lastName" outlined label="Last Name*" rounded class="rounded-xl"
                                    :rules="[
                                      (v) => !!v || 'Last Name is required',
                                    ]"></v-text-field></v-col>
                              </v-row>
                              <v-row class="py-0">
                                <v-col class="py-0"><v-text-field v-model="personalInfo.contactInfo.email" outlined
                                    label="Email Address *" rounded class="rounded-xl" required :rules="[
                                      (v) => !!v || 'Email Address is required',
                                    ]"></v-text-field></v-col>
                              </v-row>
                              <v-row class="py-0">
                                <v-col class="py-0"><v-text-field label="Your 10-digit mobile no. *" outlined rounded
                                    class="rounded-xl" counter="10" prefix="+91" :rules="[
                                      (v) =>
                                        !!v || 'Mobile number is required',
                                      (v) =>
                                        (v &&
                                          v.length >= 10 &&
                                          v.length <= 10) ||
                                        'Mobile number must be 10 digit',
                                    ]" @keypress="isNumber($event)" maxLength="10"
                                    v-model="personalInfo.contactInfo.phoneNumber"></v-text-field></v-col>
                              </v-row>
                              <v-row class="py-0">
                                <v-col cols="4" class="py-0">
                                  <v-text-field outlined label="Date of Birth (DDMMYY) *" rounded class="rounded-xl"
                                    type="date" :rules="[
                                      (v) => !!v || 'Date of Birth is required',
                                    ]" v-model="personalInfo.dob"></v-text-field></v-col>
                                <v-col cols="2" class="py-0">
                                  <v-select label="Gender *" :items="['MALE', 'FEMALE', 'OTHERS']" outlined
                                    class="rounded-xl" v-model="personalInfo.gender" :rules="[
                                      (v) => !!v || 'Gender is required',
                                    ]" required>
                                  </v-select>
                                </v-col>
                              </v-row>

                              <v-row class="py-0">
                                <v-col cols="6" class="py-0">
                                  <v-select v-model="personalInfo.addressInfo.countryName
                                    " readonly label="Country" :items="countries" item-value="name" item-text="name"
                                    outlined class="rounded-xl" :rules="[
                                      (v) => !!v || 'Country is required',
                                    ]" required>
                                  </v-select>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                  <v-select v-model="personalInfo.addressInfo.stateName" label="State*" :items="states"
                                    outlined class="rounded-xl" item-value="state" item-text="state"
                                    @change="fetchDistricts" :rules="[(v) => !!v || 'State is required']" required>
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row class="py-0">
                                <v-col cols="6" class="py-0">
                                  <v-select v-model="personalInfo.addressInfo.districtName
                                    " label="District" :items="districts" outlined class="rounded-xl"
                                    item-value="district" item-text="district" @change="fetchCitiesAndTalukas">
                                  </v-select>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                  <v-text-field v-model="personalInfo.addressInfo.tulakName" label="Taluk / Tehsil"
                                    outlined class="rounded-xl">
                                  </v-text-field>
                                </v-col> </v-row><v-row class="py-0">
                                <v-col cols="6" class="py-0">
                                  <v-select v-model="personalInfo.addressInfo.cityName" label="City / Village"
                                    :items="cities" outlined class="rounded-xl" item-value="city" item-text="city">
                                  </v-select>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                  <v-text-field v-model="personalInfo.addressInfo.pincode" outlined label="Pin Code*"
                                    rounded :rules="[
                                      (v) => !!v || 'Pincode is require',
                                      (v) =>
                                        (v && v.length >= 6 && v.length <= 6) ||
                                        'Pincode must be 6 digit',
                                    ]" @keypress="isNumber($event)" maxLength="6" counter="6" class="rounded-xl"
                                    required></v-text-field>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                  <v-text-field v-model="personalInfo.addressInfo.address" outlined label="Address"
                                    rounded class="rounded-xl"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-form>

                    <v-btn depressed rounded color="white--text" class="accent mx-4 my-4" @click="goToStep2">
                      NEXT
                    </v-btn>
                  </v-stepper-content>
                  <!------------------------------------------ STEP 2 ------------------------------------------>
                  <v-stepper-content step="2">
                    <v-form lazy-validation ref="step2">
                      <v-card elevation="0">
                        <v-card class="pa-4" elevation="0">
                          <v-expansion-panels v-model="expandedPanelIndex">
                            <v-expansion-panel v-for="(qualification, index) in academicsInfo" :key="index" elevation="0">
                              <v-expansion-panel-header>
                                <div class="d-flex flex-column" v-if="expandedPanelIndex != index">
                                  <div class="font-weight-regular">
                                    {{
                                      index + 1 + ". " + qualification.programme
                                    }}
                                  </div>
                                  <div class="text-body-2 grey--text pt-2 pb-2">
                                    {{ qualification.institution }}
                                  </div>
                                  <div class="text-body-2 grey--text">
                                    {{
                                      new Date(
                                        qualification.startDate
                                      ).getFullYear() +
                                      " - " +
                                      new Date(
                                        qualification.endDate
                                      ).getFullYear()
                                    }}
                                  </div>
                                </div>
                              </v-expansion-panel-header>

                              <v-expansion-panel-content>
                                <v-row class="py-0">
                                  <v-col class="py-0"><v-text-field v-model="qualification.institution" outlined
                                      label="School/ College/ University *" rounded class="rounded-xl" :rules="[
                                        (v) =>
                                          !!v ||
                                          'School/ College/ University is required',
                                      ]" required></v-text-field></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col class="py-0"><v-text-field v-model="qualification.programme" outlined
                                      label="Degree/ Diploma/ Certification *" rounded class="rounded-xl" :rules="[
                                        (v) =>
                                          !!v ||
                                          'Degree/ Diploma/ Certification is required',
                                      ]" required></v-text-field></v-col> </v-row><v-row class="py-0">
                                  <v-col class="py-0"><v-text-field v-model="qualification.fieldOfStudy" outlined
                                      label="Field of Study" rounded class="rounded-xl"></v-text-field></v-col>
                                </v-row>

                                <v-row class="py-0">
                                  <v-col cols="6" class="py-0"><v-text-field v-model="qualification.startDate" outlined
                                      label="Start Date*" rounded class="rounded-xl" type="date" :rules="[
                                        (v) => !!v || 'Start Date is required',
                                      ]"></v-text-field></v-col>
                                  <v-col cols="6" class="py-0"><v-text-field v-model="qualification.endDate" outlined
                                      label="End Date*" rounded class="rounded-xl" type="date" :rules="[
                                        (v) => !!v || 'End Date is required',
                                      ]"></v-text-field></v-col>
                                </v-row>

                                <v-row class="py-0">
                                  <v-col cols="12" class="py-0">
                                    <v-text-field v-model="extraCarricularActivities" outlined
                                      label="Extra Curricular Activities" rounded class="rounded-xl"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col cols="12" class="py-0">
                                    <v-text-field outlined label="Achievements" rounded class="rounded-xl"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="d-flex justify-space-between">
                                    <v-btn v-if="academicsInfo.length != 1" @click="openDeleteDiolog(index)" text
                                      class="d-flex justify-end red--text">Remove</v-btn>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                          <v-row>
                            <v-col class="mt-4">
                              <v-btn text @click="addAcademicQualification">Add More Qualification</v-btn>

                            </v-col>
                          </v-row>
                        </v-card>
                      </v-card>
                    </v-form>
                    <v-btn rounded outlined class="mx-4 my-4" color="primary" @click="e1 = 1">
                      BACK
                    </v-btn>
                    <v-btn depressed rounded color="btn-color white--text" @click="goToStep3">
                      NEXT
                    </v-btn>
                  </v-stepper-content>
                  <!------------------------------------------ STEP 3 ------------------------------------------>
                  <v-stepper-content step="3">
                    <v-form lazy-validation ref="step3">
                      <v-card elevation="0">
                        <v-card class="pa-4" elevation="0">
                          <v-expansion-panels v-model="expandedPanelIndex">
                            <v-expansion-panel v-for="(professional, index) in professionalInfo" :key="index"
                              elevation="0">
                              <v-expansion-panel-header>
                                <div class="d-flex flex-column" v-if="expandedPanelIndex != index">
                                  <div class="font-weight-regular">
                                    {{
                                      index + 1 + ". " + professional.position
                                    }}
                                  </div>
                                  <div class="text-body-2 grey--text">
                                    {{
                                      new Date(
                                        professional.startDate
                                      ).getFullYear() +
                                      " - " +
                                      new Date(
                                        professional.endDate
                                      ).getFullYear()
                                    }}
                                  </div>
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row class="py-0">
                                  <v-col class="py-0">
                                    <v-card width="100%" elevation="0" class="mb-10 rounded-xl" outlined><v-radio-group
                                        mandatory row v-model="experience" :rules="[
                                          (v) => !!v || 'Please select one',
                                        ]" required>
                                        <v-col class="py-0">
                                          <v-row class="py-0 px-0 ml-2">
                                            <v-radio class="mb-8 py-0" label="I have" value="Experienced">
                                            </v-radio>

                                            <v-col cols="1 center" class="py-0 px-0">
                                              <v-text-field :disabled="experience == 'Fresher' ? true : false"
                                                type="number" outlined @keypress="isNumber($event)" class="rounded-xl"
                                                v-model="professional.experienceYear
                                                  ">
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="1 center" class="py-0 px-0">
                                              <div class="pt-4 ml-4">Years</div>
                                            </v-col>
                                            <v-col cols="1" class="py-0 px-0">
                                              <v-text-field :disabled="experience == 'Fresher' ? true : false"
                                                type="number" @keypress="isNumber($event)" outlined class="rounded-xl"
                                                v-model="professional.experienceMonth
                                                  ">
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="4 center" class="py-0 px-0">
                                              <div class="pt-4 ml-4">
                                                Months of experiences
                                              </div>
                                            </v-col>
                                          </v-row>
                                          <v-row class="pb-4 ml-2">
                                            <v-radio label="Fresher" value="Fresher">
                                            </v-radio></v-row>
                                        </v-col> </v-radio-group></v-card>
                                  </v-col>
                                </v-row>
                                <div v-if="experience == 'Experienced'">
                                  <v-row class="py-0">
                                    <v-col class="py-0"><v-text-field outlined label="Role/ Position *" rounded
                                        class="rounded-xl" counter="100" maxLength="100" :rules="[
                                          (v) =>
                                            !!v || 'Role/ Position is required',
                                        ]" required v-model="professional.position"></v-text-field></v-col>
                                  </v-row><v-row class="py-0">
                                    <!-- <v-col class="py-0"><v-select label="Employment Type" :items="employeeType" outlined
                                        item-value="id" item-text="name" class="rounded-xl"
                                        v-model="professional.employeeTypeId">
                                      </v-select></v-col> -->
                                  </v-row>

                                  <v-row class="py-0">
                                    <v-col class="py-0"><v-text-field v-model="professional.school" outlined
                                        label="School / Institute" rounded class="rounded-xl"></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-checkbox class="py-0" v-model="isCurrentlyWorking"
                                        label="I am currently working on this role / position."></v-checkbox>
                                    </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col cols="6" class="py-0"><v-text-field outlined label="Start Date*" rounded
                                        class="rounded-xl" v-model="professional.startDate" type="date" :rules="[
                                          (v) =>
                                            !!v || 'Start Date is required',
                                        ]"></v-text-field></v-col>
                                    <v-col cols="6" class="py-0"><v-text-field :disabled="isCurrentlyWorking" outlined
                                        label="End Date" rounded class="rounded-xl" v-model="professional.endDate"
                                        type="date" :rules="[
                                          (v) => !!v || 'End Date is required',
                                        ]"></v-text-field></v-col>
                                  </v-row>
                                  <!-- <v-row class="py-0">
                                    <v-col class="py-0"><v-select label="Board" :items="boardsData" item-text="name"
                                        item-value="name" outlined class="rounded-xl" v-model="professional.board">
                                      </v-select></v-col> </v-row><v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete clearable deletable-chips label="Levels" outlined class="rounded-xl"
                                        small-chips :items="tableLevels" multiple item-text="name"
                                        v-model="professional.levels" item-value="name">
                                      </v-autocomplete></v-col> </v-row><v-row class="py-0">
                                    <v-col class="py-0"><v-autocomplete clearable deletable-chips label="Grades" outlined
                                        v-model="professional.grades" class="rounded-xl" small-chips :items="gradesData"
                                        multiple item-text="name" item-value="name">
                                      </v-autocomplete></v-col> </v-row><v-row class="py-0">
                                    <v-col class="py-0"><v-autocomplete clearable deletable-chips label="Subjects"
                                        outlined v-model="professional.subjects" class="rounded-xl" small-chips
                                        :items="subjectsData" multiple item-text="name" item-value="name">
                                      </v-autocomplete></v-col>
                                  </v-row> -->
                                </div>
                                <div>
                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete v-model="boards" clearable deletable-chips
                                        label="Which board do you want to teach?*" outlined class="rounded-xl" small-chips
                                        :items="boardsData" item-text="name" item-value="name" required multiple>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete clearable deletable-chips label="Which level do you want to teach?*"
                                        outlined class="rounded-xl" small-chips :items="tableLevels" multiple
                                        v-model="levels" item-text="name" required item-value="name">
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete multiple required clearable deletable-chips small-chips
                                        label="Which School do you want to teach?*" outlined v-model="school"
                                        required:rules="[v => !!v || 'School is required']" class="rounded-xl"
                                        :items="schools" item-text="name" item-value="name" flat>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>

                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete clearable deletable-chips required
                                        label="Which subject do you want to teach?*" outlined class="rounded-xl"
                                        small-chips v-model="subjects" :items="subjectsData" multiple item-text="name"
                                        item-value="name">
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                </div>
                                <v-row>
                                  <v-col cols="12" class="d-flex justify-space-between">
                                    <v-btn v-if="experience == 'Experienced' &&
                                      professionalInfo.length != 1
                                      " @click="openDeleteDiolog(index)" text
                                      class="d-flex justify-end red--text">Remove</v-btn>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-row>
                            <!-- <v-col class="mt-4" v-if="experience == 'Experienced'"> -->
                            <!-- <v-btn text @click="addProfessionalInfo">Add More Qualification</v-btn> -->
                            <!-- </v-col> -->
                          </v-row>
                        </v-card>
                      </v-card>
                    </v-form>
                    <v-container>
                      <v-row>
                        <v-btn rounded outlined class="ma-4 mt-8" color="btn-color" @click="e1 = 2">
                          BACK
                        </v-btn>

                        <v-btn :loading="isCreatingUser" rounded depressed color="primary"
                          class="my-4 mt-8 btn-color white--text" @click="formbtnBool ? updateUser() : createUser()">
                          {{ formbtn() }} DETAILS
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- delete success dialog -->
      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">User Deleted</p>
              <v-btn class="accent" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Success Dialog -->
      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">
                New User {{ formbtnBool == false ? "Created" : "Updated" }}
              </p>
              <v-btn class="accent" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- dialog for resend invite success-->
      <v-dialog v-model="resendInviteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Invite Sent Successfully</p>
              <v-btn class="accent" large width="157px" rounded @click="resendInviteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!--diolog for delete academic and professional array-->
      <v-dialog v-model="deleteUserDetails" max-width="366px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="#db44371f" size="90"><v-icon size="65"
                  color="#DB4437">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-4 pb-0">
                Are Sure you want to delete this Info ?
              </p>
              <p class="text-disabled grey--text text-subtitle-1" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between" fluid>
                <v-btn class="black--text" color="#0000001a" large width="157px" rounded
                  @click="deleteUserDetails = false">CANCEL</v-btn>
                <v-btn class="primary" large width="157px" rounded @click="removeDataFromSteps()">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Error Dialog -->
      <v-dialog v-model="errorDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Filter Dialog -->
      <!-- <v-dialog v-model="filterDialog" max-width="400px">
        <v-card width="400px" height="100%">
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-body1 font-weight-bold black--text">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card
                height="450px"
                elevation="0"
                id="myScroll"
                class="pt-5 ,pb-5"
              >
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text">
                      Question Type
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedQuestionType"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(questionType, index) in questionTypeList"
                    :key="index"
                    :value="questionType"
                    :class="{
                      'selectedQuestionType-chip':
                        selectedQuestionType.includes(questionType),
                    }"
                    elevated
                    @click="updateQuestionType"
                  >
                    {{ questionType }}
                  </v-chip>
                </v-chip-group>
  
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Level
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedLevel"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(level, index) in levels"
                    :key="index"
                    :value="level"
                    :class="{
                      'selectedLevel-chip': selectedLevel.includes(level),
                    }"
                    elevated
                    @click="updateLevel"
                  >
                    {{ level.name }}
                  </v-chip>
                </v-chip-group>
  
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Difficulty Level
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selecteddifficultyLevel"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(difficultyLevel, index) in levels"
                    :key="index"
                    :value="difficultyLevel"
                    :class="{
                      'selecteddifficultyLevel-chip':
                        selecteddifficultyLevel.includes(difficultyLevel),
                    }"
                    elevated
                    @click="updatedifficultyLevel"
                  >
                    {{ difficultyLevel.name }}
                  </v-chip>
                </v-chip-group>
  
              </v-card>
              <div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    outlined
                    class="pa-4"
                    @click="filterDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn rounded class="primary pa-4">Apply</v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->
    </div>

  </div>
</template>
<script>
import "../styles.css";
import { validationMixin } from "vuelidate";
import AuthService from "../services/AuthService";

import { required, email } from "vuelidate/lib/validators";
import AddressController from "@/controllers/AddressController";
import UsersController from "@/controllers/UsersController";
import LevelController from "@/controllers/LevelController";
import SubjectController from "@/controllers/SubjectController";
import GradeController from "@/controllers/GradeController";
import BoardController from "@/controllers/BoardController";
import SchoolController from "@/controllers/SchoolController";
import algoliasearch from "algoliasearch";
import { collection, getDocs, getDoc, query, where } from "firebase/firestore";
import { onSnapshot, doc } from "firebase/firestore";

import { db } from "../firebase";
import CampTotalPieChart from "./CampTotalPieChart.vue";
import CampTotalPieChartLevel from "./CampTotalPieChartLevel.vue";

export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },
  components: {
    CampTotalPieChart,
    CampTotalPieChartLevel,

  },
  name: "JobSeekersView",
  data() {
    return {
      dobRules: [
        (v) => !!v || "Date of Birth is required",
        // (v) =>
        //   /^\d{4}-\d{2}-\d{2}$/.test(v) ||
        //   "Date of Birth must be in YYYY-MM-DD format",
      ],
      rules: {
        required: (value) => !!value || "Field is required",
      },
      algoliaIndex: null,
      options: {},
      isLoading: true,
      pageSize: 10,
      page: 1,
      count: 0,
      e1: 1,
      roleCluster: "",
      roleSchool: "",
      clustersSchool: [],
      selectedStatusFilter: [],
      screeningTestFilter: [],
      mainsTestFilter: [],
      roleTypeFilter: [],
      levelTypeFilter: [],
      subjectTypeFilter: [],
      clusterTypeFilter: [],
      schoolTypeFilter: [],
      showClusterSchool: [],
      searchSchool: "",
      status: ["active", "Inactive", "Invited"],
      ScreeningTestStatus: ["Passed", "Failed", "Yet to Start"],
      role: ["Teacher", "Job Seekers"],
      dLoading: 0,
      editId: null,
      formbtnBool: false,
      experience: "Experienced",
      levels: [],
      levelId: -1,
      boards: [],
      hover: "",
      graphSchools: [],
      horizontalBarData: [],
      completionStatusGraph: [],
      levelChartData: [],

      schools: [],
      snapCluter: [],
      clustersSnap: [],

      subjects: [],
      clusterSchool: "",
      subjectId: [],
      isCurrentlyWorking: false,
      errorMessage: "Failed",

      dialog: false,
      deleteDialog: false,
      singleSelect: false,
      selected: [],
      experienceYear: -1,
      deleteUserDetails: false,
      experienceMonth: -1,
      filterDialog: false,
      rolePosition: "",
      board: "",
      gradesData: [],
      gradeId: -1,
      startDate: -1,
      endDate: -1,
      extraCarricularActivities: "",
      isCreatingUser: false,
      successDialog: false,
      resendInviteSuccessDialog: false,
      deleteSuccessDialog: false,
      errorDialog: false,
      boardsData: "",
      tableLevels: [],
      subjectsData: [],
      school: '',
      schoolData: [],
      store: "",
      search: "",
      searchVal: "Job Seeker",
      vgosCount: 0,
      jobSeekerCount: 0,
      allCount: "",
      headers: [
        { text: "Full Name", value: "fullname", cellClass: "w-10" },
        {
          text: "Email ID",
          value: "personalInfo.contactInfo.email",
          cellClass: "w-15",
        },
        {
          text: "Phone No",
          value: "personalInfo.contactInfo.phoneNumber",
          cellClass: "w-15",
        },
        { text: "Created On", value: "createdOn", cellClass: "w-15" },
        { text: "Status", value: "status", cellClass: "w-15" },
        //{ text: "Role", value: "userType", cellClass: "w-15" },
        { text: "Last active on", value: "updatedOn", cellClass: "w-15" },
        { text: "Actions", value: "actions", cellClass: "w-10" },
      ],
      personalInfo: {
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        isEmailVerified: false,
        isPhoneVerified: false,
        contactInfo: {
          email: "",
          phoneNumber: "",
        },
        addressInfo: {
          countryName: "India",
          stateName: "",
          districtName: "",
          tulakName: "",
          cityName: "",
          pincode: null,
          address: "",
        },
      },
      academicsInfo: [
        {
          institution: "",
          programme: "",
          startDate: "",
          endDate: "",
          fieldOfStudy: "",
          gradeScore: 0,
          gradeType: "",
          certificateUrl: "",
        },
      ],
      professionalInfo: [
        {
          experienceYear: 0,
          experienceMonth: 0,
          position: "",

          board: 0,
          startDate: Date.now(),
          endDate: Date.now(),
          levels: [],
          grades: [],
          subjects: [],
          school: "",
          otherName: "",
        },
      ],
      expandedPanelIndex: 0, // set the last panel as expanded by default
      countries: [
        {
          name: "India",
        },
      ],
      states: [],
      districts: [],
      cities: [],
      talukas: [],
      users: [],
      employeeType: [
        {
          id: 1,
          name: "Permanent",
        },
        {
          id: 2,
          name: "Contract",
        },
        {
          id: 3,
          name: "Prohibition",
        },
      ],
      algoliaData: [],
      preloader: true,
    };
  },


  watch: {
    clusterTypeFilter(newValue) {
      console.log("cluster New Value", newValue)
      this.getClustersSchool(newValue);
    },
    options: {
      handler() {
        console.log(this.options);
        this.pageSize = this.options.itemsPerPage;
        this.page = this.options.page;
        this.fetchUsers();
      },
      deep: true,
    },
    searchSchool(newValue) {

      if (newValue === '') {
        this.showClusterSchool = this.clustersSchool;
      } else {
        const lowercaseNewValue = newValue.toLowerCase();
        this.showClusterSchool = this.clustersSchool.filter((obj) =>
          obj.name.toLowerCase().includes(lowercaseNewValue)
        );
      }

    },


    search(newValue) {
      var searchValue = "Job Seeker";
      this.searchVal = "";
      console.log(newValue);
      if (this.search == "Un-assessed") {
        this.search = 'inprogress';
      }
      this.pageSize = this.options.itemsPerPage;
      this.page = this.options.page;
      this.options.page = 1;
      this.searchVal = searchValue + " " + newValue;

      this.fetchUsers();
    },
  },

  methods: {


    async filterUser(
      selectedStatusFilter,
      screeningTestFilter,
      mainsTestFilter,
      roleTypeFilter,
      levelTypeFilter,
      subjectTypeFilter,

    ) {
      this.dLoading = true;
      var statusId = "";
      var screeningId = "";
      var mainsId = "";
      var roleId = "";
      var levelId = "";
      var subjectId = "";
      var schoolId = "";
      selectedStatusFilter.forEach((item) => {
        statusId = statusId + item + " ";
      });
      screeningTestFilter.forEach((item) => {
        screeningId = screeningId + item + " ";
      });
      mainsTestFilter.forEach((item) => {
        mainsId = mainsId + item + " ";
      });
      roleTypeFilter.forEach((item) => {
        roleId = roleId + item + " ";
      });
      levelTypeFilter.forEach((item) => {
        levelId = levelId + item + " ";
      });
      subjectTypeFilter.forEach((item) => {
        subjectId = subjectId + item + " ";
      });
      this.schoolTypeFilter.forEach((item) => {
        schoolId = schoolId + item + " ";
      });


      this.filterVal =
        statusId +
        screeningId +
        mainsId +
        roleId +
        levelId +
        subjectId +
        schoolId + "Job Seekers"
      console.log("filter value = ", this.filterVal)

      await this.algoliaIndex
        .search(this.filterVal, {
          hitsPerPage: this.pageSize,
          page: this.page - 1,
        })
        .then(({ hits }) => {
          console.log(hits);
          this.users = hits;
          this.dLoading = false;

        });
      const { nbHits } = await this.algoliaIndex.search(this.filterVal, {
        hitsPerPage: 0,
      });
      this.dLoading = false;

      this.count = nbHits;
      console.log("filter responce", this.filterVal);
      this.filterDialog = false;
      this.filterData = true;
    },
    async getClustersSchool(clusterTypeFilter) {
      if (this.clusterTypeFilter.length > 0) {
        const response = await SchoolController.getClusterSchool(
          this.clusterTypeFilter
        );
        // console.log("school", response);
        if (response.status == 200) {
          var schools = response.data.schools;
          this.clustersSchool = schools;
          this.showClusterSchool = schools;
          //   console.log("school", this.schools);
        } else {
          alert(response.data.error);
        }
      } else {
        this.schools = [];
      }
    },

    clearFilter() {
      this.selectedStatusFilter = []
      this.screeningTestFilter = []
      this.mainsTestFilter = []
      this.roleTypeFilter = []
      this.levelTypeFilter = []
      this.subjectTypeFilter = []
      this.schoolTypeFilter = [],
        this.filterVal = "Job Seeker",
        this.filterDialog = false;
      this.filterData = false;
      this.filterUser(
        this.selectedStatusFilter,
        this.screeningTestFilter,
        this.mainsTestFilter,
        this.roleTypeFilter,
        this.levelTypeFilter,
        this.subjectTypeFilter,
      );
    },


    handleValue(value) {
      this.search = value; // Update the childValue data property with the emitted value
    },
    handleData(data) {
      this.search = this.levelChartData[data][0];
    },
    getClusterAndSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;
      if (this.roleSchool == undefined) {
        AuthService.logout();
        this.$router.push("/login");
      }

      if (this.roleCluster != "All Clusters") {
        this.cluster = this.roleCluster;
        this.getClusterSchool();
      }
      if (this.roleSchool != "All") {
        this.school = this.roleSchool;
      }
    },
    levelChart() {
      this.$store.state.levelChart = null;
      var levelGraph = [["", ""]];
      var secondary_c = 0;
      var primary_c = 0;
      var middle_c = 0;
      var pre_primary_c = 0;
      var senior_secondary_c = 0;
      this.graphSchools.forEach((scl) => {
        primary_c += scl.primary_jobseeker;
        secondary_c += scl.secondary_jobseeker;
        middle_c += scl.middle_jobseeker;
        pre_primary_c += scl.pre_primary_jobseeker;
        primary_c += scl.primary_jobseeker;
        senior_secondary_c +=
          scl.senior_secondary_jobseeker;
      });

      levelGraph.push(["Primary", primary_c]);
      levelGraph.push(["Pre Primary", pre_primary_c]);
      levelGraph.push(["Secondary", secondary_c]);
      levelGraph.push(["Middle", middle_c]);
      levelGraph.push(["Senior Secondry", senior_secondary_c]);
      this.levelChartData = [["Primary", primary_c], ["Pre Primary", pre_primary_c], ["secondary", secondary_c], ["Middle", middle_c], ["Senior Secondry", senior_secondary_c]]

    },
    CompletionStatusData() {
      var completionStatusGraph = [["", ""]];
      var a_jobseeker_count = 0;
      var unaccessed_jobseeker_count = 0;
      this.graphSchools.forEach((scl) => {
        a_jobseeker_count += scl.accessed_jobseeker;
        unaccessed_jobseeker_count += scl.unaccessed_jobseeker;
      });
      if ((a_jobseeker_count && unaccessed_jobseeker_count) == 0) {
        this.$store.state.assesedChart = null
      }
      else {
        this.$store.state.assesedChart = [["Assessed", a_jobseeker_count], ["Un-assessed", unaccessed_jobseeker_count]]
      }
      completionStatusGraph.push(["Assessed", a_jobseeker_count]);
      completionStatusGraph.push(["Un-assessed", unaccessed_jobseeker_count]);
      this.completionStatusGraph = completionStatusGraph;
    },

    dataOnCluster() {
      var horizontalBarData = [["not found", "500", { role: "style" }]];
      var completionStatusGraph = ["", ""];
      var cartClusterData = [];
      var clutersMap = {};
      var a_jobseeker_c = 0;
      var u_jobseeker_c = 0;
      var a_teacher_c = 0;
      var u_teacher_c = 0;
      this.clustersSnap.forEach((clstr) => {
        a_jobseeker_c = 0;
        u_jobseeker_c = 0;
        a_teacher_c = 0;
        u_teacher_c = 0;
        this.graphSchools.forEach((scl) => {

          if (clstr == scl.cluster) {
            a_jobseeker_c += scl.accessed_jobseeker;
            u_jobseeker_c += scl.unaccessed_jobseeker;
            a_teacher_c += scl.accessed_teacher;
            u_teacher_c += scl.unaccessed_teacher;
          }
        });

        horizontalBarData.push([
          clstr,
          a_jobseeker_c + u_jobseeker_c + a_teacher_c + u_teacher_c,
          "color: #6A4F79",
        ]);
      });

      this.horizontalBarData = horizontalBarData;
    },
    async SnapshotOnCluster() {
      var isSchool = false;

      var graphData = {
        unaccessed_teacher: 0,
        inprogress_teacher: 0,
        accessed_teacher: 0,
        unaccessed_jobseeker: 0,
        inprogress_jobseeker: 0,
        accessed_jobseeker: 0,
        primary_teacher: 0,
        pre_primary_teacher: 0,
        middle_teacher: 0,
        secondary_teacher: 0,
        senior_secondary_teacher: 0,
        primary_jobseeker: 0,
        pre_primary_jobseeker: 0,
        middle_jobseeker: 0,
        secondary_jobseeker: 0,
        senior_secondary_jobseeker: 0,
      };


      const querySnapshot = await getDocs(collection(db, "clusters"));
      if (querySnapshot.empty) {
        alert("Cluster Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          this.clustersSnap.push(doc.data().name);
        });

      }

      if (this.roleCluster != "All Clusters" && this.roleSchool == "All") {
        this.clustersSnap = [];
        this.clustersSnap = [this.roleCluster];
      } else if (
        this.roleCluster != "All Clusters" &&
        this.roleSchool != "All"
      ) {
        this.clustersSnap = [];
        this.clustersSnap = [this.roleCluster];
        isSchool = true;
      } else {
        console.log("empty");
      }
      const clustersRef = new Array(this.clustersSnap.length);


      for (var i = 0; i < this.clustersSnap.length; i++) {
        if (isSchool) {
          clustersRef[i] = doc(
            collection(db, "dashboards", "ums-dashboard", this.clustersSnap[i]),
            this.roleSchool
          );
          onSnapshot(
            doc(
              db,
              "dashboards",
              "ums-dashboard",
              this.clustersSnap[i],
              this.roleSchool
            ),
            { includeMetadataChanges: true },
            (doc) => {
              this.graphSchools[0] = doc.data();
              this.dataOnCluster();
              this.CompletionStatusData();
              this.levelChart();
            }
          );
        } else {
          clustersRef[i] = collection(
            db,
            "dashboards",
            "ums-dashboard",
            this.clustersSnap[i]
          );

          onSnapshot(clustersRef[i], (querySnapshot) => {
            // schools = [];

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {

                this.graphSchools.push(change.doc.data());
              }
              if (change.type === "modified") {
                let data = change.doc.data();

                const index = this.graphSchools.findIndex(
                  (school) => school.id === data.id
                );
                this.graphSchools[index] = data;
              }
              if (change.type === "removed") {
                let remove = change.doc.data();
                const removeindex = this.graphSchools.findIndex(
                  (school) => school.id === remove.id
                );
                this.graphSchools.splice(removeindex, 1);
              }
            });

            this.dataOnCluster();
            this.CompletionStatusData();
            this.levelChart();
          });
        }
      }
    },


    getClusterOrSchool() {
      var CData = this.$store.state.role.clusterName
      var SData = this.$store.state.role.schools

      this.clusterSchool = "";
      if ((CData == 'All Clusters')) {
        this.clusterSchool = ""
      }
      else if ((CData != 'All Clusters') && (SData == 'All')) {
        this.clusterSchool = CData
      }
      else {
        this.clusterSchool = SData;
      }
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async getTotalCount() {
      if (this.clusterSchool != "") {
        const { nbHits } = await this.algoliaIndex.search(this.searchVal, {
          hitsPerPage: 0,
        });
        const allHits = await this.algoliaIndex.search(this.clusterSchool, { hitsPerPage: 0 });
        const vgosHits = await this.algoliaIndex.search("Teacher " + this.clusterSchool);
        const jobSeekerCount = await this.algoliaIndex.search("Job Seeker " + this.clusterSchool);

        this.count = jobSeekerCount.nbHits;
        this.allCount = allHits.nbHits;
        this.vgosCount = vgosHits.nbHits;

      } else {
        const { nbHits } = await this.algoliaIndex.search(this.searchVal + " " + this.clusterSchool, {
          hitsPerPage: 0,
        });
        const allHits = await this.algoliaIndex.search(this.clusterSchool);
        const vgosHits = await this.algoliaIndex.search(this.clusterSchool + " " + "Teacher");
        const jobSeekerCount = await this.algoliaIndex.search("Job Seeker " + this.clusterSchool);

        this.count = jobSeekerCount.nbHits;
        this.allCount = allHits.nbHits;
        this.vgosCount = vgosHits.nbHits;
      }

    },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    async updateProfessionalInfo(data, userId) {
      const response = await UsersController.updateProfessionalInfo(
        data,
        userId,
        data.id
      );
      // console.log(response);
    },
    goToStep2() {
      if (this.formbtnBool) {
        if (this.$refs.step1.validate()) {
          // this.updateUser(this.personalInfo, this.editId);
          this.e1 = 2;
        }
      } else {
        if (this.$refs.step1.validate()) {
          this.e1 = 2;
          this.expandedPanelIndex = 0;
          // console.log(this.personalInfo)
        }
      }
    },
    updateData(item) {
      this.editId = item.userId;
      console.log("item data", item); // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      (this.personalInfo.title = item.title),
        (this.personalInfo.firstName = item.personalInfo.firstName),
        (this.personalInfo.middleName = item.personalInfo.middleName),
        (this.personalInfo.lastName = item.personalInfo.lastName),
        (this.personalInfo.contactInfo.email =
          item.personalInfo.contactInfo.email),
        (this.personalInfo.dob = item.personalInfo.dob),
        (this.personalInfo.gender = item.personalInfo.gender),
        (this.personalInfo.contactInfo.phoneNumber =
          item.personalInfo.contactInfo.phoneNumber),
        (this.personalInfo.addressInfo.countryName =
          item.personalInfo.addressInfo.countryName),
        (this.personalInfo.addressInfo.stateName =
          item.personalInfo.addressInfo.stateName),
        (this.personalInfo.addressInfo.cityName =
          item.personalInfo.addressInfo.cityName),
        (this.personalInfo.addressInfo.districtName =
          item.personalInfo.addressInfo.districtName),
        (this.personalInfo.addressInfo.address =
          item.personalInfo.addressInfo.address),
        (this.personalInfo.addressInfo.pincode =
          item.personalInfo.addressInfo.pincode),
        (this.academicsInfo = item.academicsInfo);
      this.professionalInfo = item.professionalInfo;
      // this.professionalInfo.experienceYear = item.experienceYear,
      // this.professionalInfo.experienceMonth = item.experienceMonth,
      // this.professionalInfo.position = item.position,
      // this.professionalInfo.employeeTypeId = item.employeeTypeId,
      // this.professionalInfo.board = item.board,
      this.dialog = true;
      // this.getAcademics(this.editId),
      // this.getProfessionalInfo(this.editId)
    },
    // nextStep() {
    //   if (this.e1 == 3) {
    //     this.createUser();
    //   } else {
    //     this.e1 = this.e1 + 1;
    //   }
    // },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      // console.log("suvject res", response);

      if (response.status == 200) {
        this.subjectsData = response.data.subjects;
      } else {
        alert("subject Not Found!!");
      }
    },
    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        this.tableLevels = response.data.levels;
      } else {
        alert("Levels Not Found!!");
      }

      // console.log("level data", this.levelData.rows);
    },
    async getGrades() {
      const response = await GradeController.getAllGrades();
      //console.log(response);

      // console.log("grade data", response);
      if (response.status == 200) {
        this.gradesData = response.data.grades;
      } else {
        alert("Levels Not Found!!");
      }
    },
    goToStep3() {
      if (this.formbtnBool) {
        // this.updateAcademics();
        this.e1 = 3;
      } else {
        if (this.$refs.step2.validate()) {
          this.e1 = 3;
          this.expandedPanelIndex = 0;
        }
      }
    },
    async createUser() {
      // console.log("create method", this.formbtnBool, "condition bool");
      if (this.formbtnBool) {
        this.dialog = false;
        this.formbtnBool = false;
      } else if (this.$refs.step3.validate()) {
        this.personalInfo.contactInfo.phoneNumber =
          "+91" + this.personalInfo.contactInfo.phoneNumber;
        // console.log("userif conditon data", {
        //   personalInfo: this.personalInfo,
        //   academicsInfo: this.academicsInfo,
        //   professionalInfo: this.professionalInfo,

        // });
        this.isCreatingUser = true;

        const response = await UsersController.createUser({
          userType: "Job Seeker",
          status: "INACTIVE",
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
          teachingInterests: {
            schools: this.school,
            boards: this.boards,
            levels: this.levels,
            subjects: this.subjects
          },
          isTeachingInterestCreated: true,
          assessmentStatus: "inprogress"

        });
        console.log("create res", response);
        if (response.status == 200) {
          this.isCreatingUser = false;
          this.successDialog = true;
          this.dialog = false;
          this.fetchUsers();
        } else {
          this.isCreatingUser = false;
          this.errorMessage = response.data.error;
          this.errorDialog = true;
        }
      }
    },
    async updateUser() {
      // for update personal info
      const response = await UsersController.updateUserInfo(
        {
          userType: "Job Seeker",
          status: "INACTIVE",
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
        },
        this.editId
      );

      if (response.status) {
        this.isCreatingUser = false;
        this.successDialog = true;
        this.dialog = false;
        this.fetchUsers();
      } else {
        this.isCreatingUser = false;
        this.errorMessage = response.data.error;
        this.errorDialog = true;
      }
    },
    async updateAcademics(qualification, usrid) {
      // console.log("data  id acadmic id", qualification, usrid);
      const response = await UsersController.updateAcademics(
        qualification,
        usrid,
        qualification.id
      );
      console.log(response);
    },
    async getAcademics(id) {
      const response = await UsersController.getAcademics(id);
      //console.log("res", response)
      this.academicsInfo = response.data.data;
      // console.log("academic data comming", this.academicsInfo)
    },

    async getProfessionalInfo(id) {
      const response = await UsersController.getprofessionalInfo(id);
      this.professionalInfo = response.data.data;
      // console.log("professional data comming", this.professionalInfo)
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    async deleteUser(items) {
      this.dLoading = true;
      console.log("selected user", items);
      var ids = [];
      items.forEach((doc) => {
        ids.push(doc.userId);
      });
      const response = await UsersController.deleteUser({
        ids: ids,
      });
      if (response.status) {
        this.selected = [];
        this.selected.length = 0;
        ids = [];
        this.dLoading = false;
        setTimeout(() => {
          // This code will execute after a 1 second delay
          this.initAlgoliaClient();
          this.fetchUsers();
          this.getTotalCount();
        }, 5000);
      } else {
        alert(response.data.error);
        this.dLoading = false;

      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },



    addAcademicQualification() {
      this.academicsInfo.push({
        institution: "",
        programme: "",
        startDate: "",
        endDate: "",
        fieldOfStudy: "",
        gradeScore: 0,
        gradeType: "",
        certificateUrl: "",
      });
      this.expandedPanelIndex = this.academicsInfo.length - 1;
    },
    addProfessionalInfo() {
      this.professionalInfo.push({
        experienceYear: 0,
        experienceMonth: 0,
        position: "",
        board: 0,
        startDate: "",
        endDate: "",
        school: "",
      });
      this.expandedPanelIndex = this.professionalInfo.length - 1;
    },
    async fetchUsers() {
      console.log("dbsvhfjdb")
      await this.getUsersFromAlgolia();
      //  this.count = this.users.length;
      this.isLoading = false;
      this.preloader = false;
      this.getTotalCount();
      // const response = await UsersController.getUsers();
      // //console.log("users", response);
      // if (response.data.flag) {
      //   this.users = response.data.users;
      //   this.count = response.data.count;
      //   //   this.users.forEach((item) => {
      //   //   item.fullName = item.first_name + " " + item.last_name;
      //   // });
      //   //console.log(response.data.data);
      // } else {
      //   alert(response.data.error);
      // }
    },
    async fetchCountries() {
      const response = await AddressController.getCountries();

      if (response.data.success) {
        this.countries = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
      //console.log(this.countries);
    },

    async fetchStates() {
      var states = [];
      const querySnapshot = await getDocs(collection(db, "states"));
      if (querySnapshot.empty) {
        alert("States Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          states.push(doc.data());
        });
      }
      this.states = states;
    },
    async fetchDistricts() {
      //console.log(this.personalInfo.state_id);
      const q = query(collection(db, "districts"), where("state", "==", this.personalInfo.addressInfo.stateName));
      const querySnapshots = await getDocs(q);
      this.districts = [];
      querySnapshots.forEach((doc) => {
        this.districts.push(doc.data());
        // console.log(doc.data());

      });
      // console.log("district",this.districts)

      //console.log(this.districts);
      // this.fetchCities();
    },


    async fetchCitiesAndTalukas() {


      const q = query(collection(db, "cities"), where("state", "==", this.personalInfo.addressInfo.stateName), where("district", "==", this.personalInfo.addressInfo.districtName));
      const querySnapshots = await getDocs(q);
      this.cities = [];
      querySnapshots.forEach((doc) => {
        this.cities.push(doc.data());
        // console.log(doc.data());

      });
      // console.log("district",this.cities)

      //console.log(this.cities);
    },
    removeDataFromSteps() {
      this.deleteUserDetails = true;
      if (this.e1 == 2) {
        this.academicsInfo.splice(this.indexValue, 1);
      } else if (this.e1 == 3) {
        this.professionalInfo.splice(this.indexValue, 1);
      }
      this.deleteUserDetails = false;
      this.indexValue = null;
    },
    openDeleteDiolog(index) {
      this.indexValue = index;
      this.deleteUserDetails = true;
    },
    async getBoards() {
      const response = await BoardController.getBoards();
      // console.log(response);

      if (response.status == 200) {
        this.boardsData = response.data.boards;
      } else {
        alert("Boards Not Found!!");
      }

      //  console.log("board log", this.boardsData);
    },
    async getSchool() {
      const response = await SchoolController.getSchool();
      this.schools = response.data.schools;
    },
    async getUsersFromAlgolia() {
      if (this.clusterSchool != "") {
        await this.algoliaIndex
          .search(this.searchVal + " " + this.clusterSchool, {
            hitsPerPage: this.pageSize,
            page: this.page - 1,
          })
          .then(({ hits }) => {
            console.log("hits", hits);
            this.users = hits;
            console.log("jobseeker", this.users)
          });
      } else {
        await this.algoliaIndex
          .search(this.clusterSchool + " " + this.searchVal, {
            hitsPerPage: this.pageSize,
            page: this.page - 1,
          })
          .then(({ hits }) => {
            console.log("hits", hits);
            this.users = hits;
            console.log("jobseeker", this.users)
          });
      }
      // Fetch all records from Algolia index

    },
    initAlgoliaClient() {
      // Initialize Algolia client
      const client = algoliasearch(
        this.$ALGOLIA_APP_ID,
        "a814971ff9eaeb34a7a143e7226d2b31"
      );
      this.algoliaIndex = client.initIndex(this.$USERS_INDEX);
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    filteredLevel() {
      if (this.searchLevels) {
        const regex = new RegExp(this.searchLevels);
        return this.tableLevels.filter((tableLevels) =>
          regex.test(tableLevels.name)
        );
      } else {
        return this.tableLevels;
      }
    },

    filteredBoard() {
      if (this.searchBoards) {
        const regex = new RegExp(this.searchBoards);
        return this.boardsData.filter((boardData) =>
          regex.test(boardData.name)
        );
      } else {
        return this.tableLevels;
      }
    },
    filteredSubject() {
      if (this.searchSubject) {
        const regex = new RegExp(this.searchSubject);
        return this.subject.filter((subject) => regex.test(subject.name));
      } else {
        return this.subject;
      }
    },
  },
  created() {
    // this.fetchCountries();

    this.fetchStates();
    this.initAlgoliaClient();
    this.fetchUsers();
    this.getTotalCount();
    this.getLevels();
    this.getSubjects();
    this.getGrades();
    this.getBoards();
    this.getSchool();
    this.SnapshotOnCluster();
    this.$store.state.breadcrumb = "Job Seekers";
  },
  mounted() {
    if (!this.$store.state.role.teacherOnboarding.read) {
      this.$router.push("/notFound");
    }
    if (this.$store.state.role.createdOn == undefined) {
      this.logout();
    }
    this.getClusterOrSchool();
    this.getClusterAndSchool();

  },
};
</script>
<style scoped>
.graph {
  border-radius: 8px !important;
  margin: 8px;
  height: 132px;
  font-size: 0.8rem;
}

.mar-row {
  margin: 2px !important;
}

.blue-border {
  border-color: blue !important;
}

.pad {
  padding-top: 90px;
}
</style>
