<template>
    <v-container fluid class="pa-8">
        Access Denied
    </v-container>
  </template>
  <script>

  
  export default {
    name: "NotFoundView",
    data() {
      return {
       
      };
    },
    watch: {
      
     
    },
    methods: {},
    created() {
     
    },
  };
  </script>
  <style scoped></style>