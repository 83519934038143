<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>

  <div v-else>
    <v-card
      height="56px"
      width="100%"
      class="ma-4 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
    >
      <v-card-title class="ml-0 pl-0">
        Users <span><v-icon class="ml-2">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div
        class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center"
      >
        <v-badge
          color="red"
          dot
          right
          transition="slide-x-transition"
          class="mr-8"
        >
          <img src="../assets/bell 1.svg" />
        </v-badge>

        <div>
          <img
            src="../assets/avtar.png"
            class="rounded-xl mr-2"
            width="32"
            height="32"
          />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="40px"
              height="40px"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <v-container fluid class="pt-0 pb-8 px-8">
      <v-row class="py-0 pl-2 mt-4 mb-4">
        <v-card
          elevation="0"
          class="background mb-2"
          height="132px"
          width="200px"
        >
          <v-btn
            to="/users"
            active-class="highEmphasis"
            depressed
            width="195px"
            height="40px"
            elevation="0"
            outlined
            class="my-1 white rounded-xl text-subtitle-2 justify-start"
            ><v-icon small class="pr-2"> mdi-bullhorn-outline</v-icon>ALL ({{
              this.count
            }})</v-btn
          >

          <v-btn
            to="/teachers"
            active-class="highEmphasis"
            width="195px"
            height="40px"
            elevation="0"
            depressed
            outlined
            class="my-1 white rounded-xl text-subtitle-2 justify-start"
            ><v-icon small class="pr-2"> mdi-human-male-board</v-icon> Vgos
            Teacher ({{ this.vgosCount }})
          </v-btn>
          <v-btn
            to="/jobseekers"
            active-class="highEmphasis"
            width="195px"
            height="40px"
            elevation="0"
            depressed
            outlined
            class="my-1 white rounded-xl text-subtitle-2 justify-start"
            ><v-icon small class="pr-2"> mdi-briefcase-search-outline</v-icon>
            Job seekers ({{ this.jobSeekerCount }})
          </v-btn>
        </v-card>
        <div class="chart-row d-flex flex-row">
          <v-card width="33.3%" elevation="0" class="graph">
            <HorizontalBarChartVue chart-row:clustersSnap="clustersSnap" />
          </v-card>

          <v-card width="33.3%" elevation="0" class="graph">
            <CampTotalPieChart message="completion Status" />
          </v-card>

          <v-card width="33.3%" elevation="0" class="graph">
            <CampTotalPieChart message="levels" />
          </v-card>
        </div>
      </v-row>

      <v-row class="pl-3 mb-4 w-100" justify="space-between">
        <!-- <v-col cols="2" class="py-0"> 

        </v-col> -->

        <v-col cols="12" class="px-0 d-flex flex-row align-center justify-end">
          <v-row justify="end">
            <v-card width="350px" class="elevation-0 transparent">
              <v-text-field
                solo
                label="Search here..."
                hide-details
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                class="white rounded-pill ml-4"
                dense
                outlined
                clearable
              ></v-text-field>
            </v-card>

            <v-btn
              dense
              rounded
              class="border-button elevation-0 mx-3"
              @click="filterDialog = true"
              ><v-icon>mdi-tune</v-icon>Filter</v-btn
            >
            <!-- <v-btn depressed outlined rounded class="mx-3"><v-icon>mdi-archive</v-icon>VIEW ARCHIVE</v-btn> -->
            <v-btn
              class="border-button mx-3"
              outlined
              rounded
              @click="deleteDialog = true"
              :disabled="selected.length == 0"
              ><v-icon>mdi-trash-can-outline</v-icon>Delete</v-btn
            ><v-btn
              class="border-button mx-3"
              outlined
              rounded
              :disabled="selected.length == 0"
              ><v-icon>mdi-email-sync-outline</v-icon>Resend Invite</v-btn
            >
            <!-- <v-btn
                class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn depressed outlined rounded class="border-button mx-3"
              ><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn
            >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="accent mx-3"
                  rounded
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                  <div class="pl-1">Create</div>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="
                    () => {
                      formbtnBool = false;
                      dialog = true;
                    }
                  "
                >
                  <v-list-item-title>
                    <v-icon class="pr-1">mdi-email-plus-outline</v-icon>CREATE
                    NEW USER
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item>
                    <v-list-item-title>
                      <v-icon>mdi-email-plus-outline</v-icon> INVITE NEW ADMIN
                    </v-list-item-title>
                  </v-list-item> -->
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="deleteDialog" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-container></v-container>
              <v-avatar color="background" size="90"
                ><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar
              >
              <p class="text-h5 pt-6 pb-0">Delete User</p>
              <p
                class="text-disabled grey--text text-subtitle-1 pt-3"
                color="rgba(0, 0, 0, 0.6)"
                disabled
              >
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn
                  depressed
                  class="background black--text"
                  large
                  width="157px"
                  rounded
                  @click="
                    () => {
                      deleteDialog = false;
                      formbtnBool = false;
                    }
                  "
                  >CANCEL</v-btn
                >
                <v-btn
                  class="accent white--text"
                  depressed
                  large
                  width="157px"
                  rounded
                  :disabled="selected.length == 0"
                  @click="deleteUser(selected)"
                  >DELETE</v-btn
                >
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-data-table
        :loading="isLoading"
        v-model="selected"
        :headers="headers"
        :items="users"
        show-select
        :single-select="singleSelect"
        item-key="userId"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
        }"
        :server-items-length="count"
        class="usertable"
      >
        <template v-slot:[`item.fullname`]="{ item }">
          {{ item.personalInfo.firstName + " " + item.personalInfo.lastName }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <img
              width="36px"
              height="36"
              @click="updateData(item)"
              class="cursor"
              src="../assets/edit.svg"
            />
            <img
              width="36px"
              height="36"
              class="cursor"
              src="../assets/userdelete.svg"
            />
          </div>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <!-- <template v-slot:[`item.updated_at`]="{ item }">
          {{ getDate(item.created_at) }}
        </template> -->
      </v-data-table>

      <v-dialog v-model="dialog" fullscreen hide-overlay>
        <v-card class="background">
          <v-card-title>
            <v-toolbar elevation="0" class="background">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-toolbar-title class="text-h5">
                    {{ formbtn() }} User</v-toolbar-title
                  >
                </v-col>
                <v-col cols="12" sm="12" md="12"> </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-btn class="float-right" icon @click="dialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-toolbar>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-stepper elevation="0" class="background" v-model="e1">
                <v-card outlined elevation="0" class="pb-5 background">
                  <v-stepper-header class="text-subtitle-2 white">
                    <v-stepper-step color="#4D695B" :complete="e1 > 1" step="1">
                      PERSONAL INFORMATION
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step color="#4D695B" :complete="e1 > 2" step="2">
                      ACADEMICS
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step color="#4D695B" step="3">
                      PROFESSIONAL INFO
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="4"> VGOS Onboarding </v-stepper-step>
                  </v-stepper-header>
                </v-card>
                <div class="pb-5 text-h6">
                  {{ getStepName(this.e1) }}
                </div>

                <v-card outlined elevation="0">
                  <v-stepper-items>
                    <!------------------------------------------ STEP 1 ------------------------------------------>
                    <v-stepper-content step="1">
                      <v-form lazy-validation ref="step1">
                        <v-card elevation="0">
                          <v-row>
                            <v-col cols="12">
                              <v-card class="pa-4" elevation="0">
                                <v-row class="py-0">
                                  <v-col cols="2" class="py-0"
                                    ><v-select
                                      v-model="personalInfo.title"
                                      label="Title *"
                                      :items="['Ms', 'Mrs', 'Mr']"
                                      outlined
                                      class="rounded-xl"
                                      :rules="[
                                        (v) => !!v || 'Title is required',
                                      ]"
                                      required
                                      attach
                                      density="comfortable"
                                    >
                                    </v-select
                                  ></v-col>
                                  <v-col cols="4" class="py-0"
                                    ><v-text-field
                                      v-model="personalInfo.firstName"
                                      :rules="[
                                        (v) => !!v || 'First Name is required',
                                      ]"
                                      required
                                      outlined
                                      label="First Name *"
                                      rounded
                                      class="rounded-xl"
                                    ></v-text-field></v-col
                                  ><v-col cols="3" class="py-0"
                                    ><v-text-field
                                      v-model="personalInfo.middleName"
                                      outlined
                                      label="Middle Name"
                                      rounded
                                      class="rounded-xl"
                                    ></v-text-field></v-col
                                  ><v-col cols="3" class="py-0"
                                    ><v-text-field
                                      v-model="personalInfo.lastName"
                                      outlined
                                      label="Last Name*"
                                      rounded
                                      class="rounded-xl"
                                      :rules="[
                                        (v) => !!v || 'Last Name is required',
                                      ]"
                                    ></v-text-field
                                  ></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col class="py-0"
                                    ><v-text-field
                                      v-model="personalInfo.contactInfo.email"
                                      outlined
                                      label="Email Address *"
                                      rounded
                                      class="rounded-xl"
                                      required
                                      :rules="[
                                        (v) =>
                                          !!v || 'Email Address is required',
                                      ]"
                                    ></v-text-field
                                  ></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col class="py-0"
                                    ><v-text-field
                                      label="Your 10-digit mobile no. *"
                                      outlined
                                      rounded
                                      class="rounded-xl"
                                      counter="10"
                                      prefix="+91"
                                      :rules="[
                                        (v) =>
                                          !!v || 'Mobile Number is required',
                                      ]"
                                      required
                                      maxLength="10"
                                      v-model="
                                        personalInfo.contactInfo.phoneNumber
                                      "
                                    ></v-text-field
                                  ></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col cols="4" class="py-0">
                                    <v-text-field
                                      outlined
                                      label="Date of Birth (DDMMYY) *"
                                      rounded
                                      class="rounded-xl"
                                      type="date"
                                      :rules="[
                                        (v) =>
                                          !!v || 'Date of Birth is required',
                                      ]"
                                      v-model="personalInfo.dob"
                                    ></v-text-field
                                  ></v-col>
                                  <v-col cols="2" class="py-0">
                                    <v-select
                                      label="Gender *"
                                      :items="['MALE', 'FEMALE', 'OTHERS']"
                                      outlined
                                      class="rounded-xl"
                                      v-model="personalInfo.gender"
                                      :rules="[
                                        (v) => !!v || 'Gender is required',
                                      ]"
                                      required
                                    >
                                    </v-select>
                                  </v-col>
                                </v-row>

                                <v-row class="py-0">
                                  <v-col cols="6" class="py-0">
                                    <v-select
                                      v-model="
                                        personalInfo.addressInfo.countryName
                                      "
                                      readonly
                                      label="Country"
                                      :items="countries"
                                      item-value="name"
                                      item-text="name"
                                      outlined
                                      class="rounded-xl"
                                      :rules="[
                                        (v) => !!v || 'Country is required',
                                      ]"
                                      required
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="6" class="py-0">
                                    <v-select
                                      v-model="
                                        personalInfo.addressInfo.stateName
                                      "
                                      label="State"
                                      :items="states"
                                      outlined
                                      class="rounded-xl"
                                      item-value="state"
                                      item-text="state"
                                      @change="fetchDistricts"
                                      :rules="[
                                        (v) => !!v || 'State is required',
                                      ]"
                                      required
                                    >
                                    </v-select>
                                  </v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col cols="6" class="py-0">
                                    <v-select
                                      v-model="
                                        personalInfo.addressInfo.districtName
                                      "
                                      label="District"
                                      :items="districts"
                                      outlined
                                      class="rounded-xl"
                                      item-value="district"
                                      item-text="district"
                                      @change="fetchCitiesAndTalukas"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="6" class="py-0">
                                    <v-text-field
                                      v-model="
                                        personalInfo.addressInfo.tulakName
                                      "
                                      label="Taluk / Tehsil"
                                      outlined
                                      class="rounded-xl"
                                    >
                                    </v-text-field>
                                  </v-col> </v-row
                                ><v-row class="py-0">
                                  <v-col cols="6" class="py-0">
                                    <v-select
                                      v-model="
                                        personalInfo.addressInfo.cityName
                                      "
                                      label="City / Village"
                                      :items="cities"
                                      outlined
                                      class="rounded-xl"
                                      item-value="city"
                                      item-text="city"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="6" class="py-0">
                                    <v-text-field
                                      v-model="personalInfo.addressInfo.pincode"
                                      outlined
                                      label="Pin Code"
                                      rounded
                                      maxLength="6"
                                      counter="6"
                                      class="rounded-xl"
                                      :rules="[
                                        (v) => !!v || 'Pincode is required',
                                      ]"
                                      required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" class="py-0">
                                    <v-text-field
                                      v-model="personalInfo.addressInfo.address"
                                      outlined
                                      label="Address"
                                      rounded
                                      class="rounded-xl"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-form>

                      <v-btn
                        rounded
                        color="primary"
                        class="mx-4 my-4 btn-color white--text"
                        @click="goToStep2"
                      >
                        NEXT
                      </v-btn>
                    </v-stepper-content>
                    <!------------------------------------------ STEP 2 ------------------------------------------>
                    <v-stepper-content step="2">
                      <v-form lazy-validation ref="step2">
                        <v-card class="white px-0 py-0" elevation="0">
                          <v-card outlined class="px-0 py-0">
                            <v-expansion-panels v-model="expandedPanelIndex">
                              <v-expansion-panel
                                v-for="(qualification, index) in academicsInfo"
                                :key="index"
                                elevation="0"
                              >
                                <v-expansion-panel-header>
                                  <div
                                    class="d-flex flex-column"
                                    v-if="expandedPanelIndex != index"
                                  >
                                    <div class="font-weight-regular">
                                      {{
                                        index +
                                        1 +
                                        ". " +
                                        qualification.programme
                                      }}
                                    </div>
                                    <div
                                      class="text-body-2 grey--text pt-2 pb-2"
                                    >
                                      {{ qualification.institution }}
                                    </div>
                                    <div class="text-body-2 grey--text">
                                      {{
                                        new Date(
                                          qualification.startDate
                                        ).getFullYear() +
                                        " - " +
                                        new Date(
                                          qualification.endDate
                                        ).getFullYear()
                                      }}
                                    </div>
                                  </div>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                  <v-row class="py-0">
                                    <v-col class="py-0"
                                      ><v-text-field
                                        v-model="qualification.institution"
                                        outlined
                                        label="School/ College/ University *"
                                        rounded
                                        class="rounded-xl"
                                        :rules="[
                                          (v) =>
                                            !!v ||
                                            'School/ College/ University is required',
                                        ]"
                                        required
                                      ></v-text-field
                                    ></v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col class="py-0"
                                      ><v-text-field
                                        v-model="qualification.programme"
                                        outlined
                                        label="Degree/ Diploma/ Certification *"
                                        rounded
                                        class="rounded-xl"
                                        :rules="[
                                          (v) =>
                                            !!v ||
                                            'Degree/ Diploma/ Certification is required',
                                        ]"
                                        required
                                      ></v-text-field
                                    ></v-col> </v-row
                                  ><v-row class="py-0">
                                    <v-col class="py-0"
                                      ><v-text-field
                                        v-model="qualification.fieldOfStudy"
                                        outlined
                                        label="Field of Study"
                                        rounded
                                        class="rounded-xl"
                                      ></v-text-field
                                    ></v-col>
                                  </v-row>

                                  <v-row class="py-0">
                                    <v-col cols="6" class="py-0"
                                      ><v-text-field
                                        v-model="qualification.startDate"
                                        outlined
                                        label="Start Date*"
                                        rounded
                                        class="rounded-xl"
                                        type="date"
                                        :rules="[
                                          (v) =>
                                            !!v || 'Start Date is required',
                                        ]"
                                      ></v-text-field
                                    ></v-col>
                                    <v-col cols="6" class="py-0"
                                      ><v-text-field
                                        v-model="qualification.endDate"
                                        outlined
                                        label="End Date*"
                                        rounded
                                        class="rounded-xl"
                                        type="date"
                                        :rules="[
                                          (v) => !!v || 'End Date is required',
                                        ]"
                                      ></v-text-field
                                    ></v-col>
                                  </v-row>

                                  <v-row class="py-0">
                                    <v-col cols="12" class="py-0">
                                      <v-text-field
                                        v-model="extraCarricularActivities"
                                        outlined
                                        label="Extra Curricular Activities"
                                        rounded
                                        class="rounded-xl"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col cols="12" class="py-0">
                                      <v-text-field
                                        outlined
                                        label="Achievements"
                                        rounded
                                        class="rounded-xl"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="d-flex justify-space-between"
                                    >
                                      <v-btn
                                        v-if="academicsInfo.length != 1"
                                        @click="openDeleteDiolog(index)"
                                        text
                                        class="d-flex justify-end red--text"
                                        >Remove</v-btn
                                      >
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>

                            <v-row>
                              <v-col class="mt-4">
                                <v-btn text @click="addAcademicQualification"
                                  >Add More Qualification</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-card>
                      </v-form>
                      <v-btn
                        rounded
                        text
                        class="mx-4 my-4"
                        color="brown-text"
                        @click="e1 = 1"
                      >
                        BACK
                      </v-btn>
                      <v-btn
                        rounded
                        color="btn-color white--text"
                        @click="goToStep3"
                      >
                        NEXT
                      </v-btn>
                    </v-stepper-content>
                    <!------------------------------------------ STEP 3 ------------------------------------------>
                    <v-stepper-content step="3">
                      <v-form lazy-validation ref="step3">
                        <v-card elevation="0">
                          <v-card class="pa-4" elevation="0">
                            <v-expansion-panels v-model="expandedPanelIndex">
                              <v-expansion-panel
                                v-for="(
                                  professional, index
                                ) in professionalInfo"
                                :key="index"
                                elevation="0"
                              >
                                <v-expansion-panel-header>
                                  <div
                                    class="d-flex flex-column"
                                    v-if="expandedPanelIndex != index"
                                  >
                                    <div class="font-weight-regular">
                                      {{
                                        index + 1 + ". " + professional.position
                                      }}
                                    </div>
                                    <div class="text-body-2 grey--text">
                                      {{
                                        new Date(
                                          professional.startDate
                                        ).getFullYear() +
                                        " - " +
                                        new Date(
                                          professional.endDate
                                        ).getFullYear()
                                      }}
                                    </div>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-card
                                        width="100%"
                                        elevation="0"
                                        class="mb-10 rounded-xl"
                                        outlined
                                        ><v-radio-group
                                          mandatory
                                          row
                                          v-model="experience"
                                          :rules="[
                                            (v) => !!v || 'Please select one',
                                          ]"
                                          required
                                        >
                                          <v-col class="py-0">
                                            <v-row class="py-0 px-0 ml-2">
                                              <v-radio
                                                class="mb-8 py-0"
                                                label="I have"
                                                value="Experienced"
                                              >
                                              </v-radio>

                                              <v-col
                                                cols="1 center"
                                                class="py-0 px-0"
                                              >
                                                <v-text-field
                                                  :disabled="
                                                    experience == 'Fresher'
                                                      ? true
                                                      : false
                                                  "
                                                  type="number"
                                                  outlined
                                                  @keypress="isNumber($event)"
                                                  class="rounded-xl"
                                                  v-model="
                                                    professional.experienceYear
                                                  "
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col
                                                cols="1 center"
                                                class="py-0 px-0"
                                              >
                                                <div class="pt-4 ml-4">
                                                  Years
                                                </div>
                                              </v-col>
                                              <v-col cols="1" class="py-0 px-0">
                                                <v-text-field
                                                  :disabled="
                                                    experience == 'Fresher'
                                                      ? true
                                                      : false
                                                  "
                                                  type="number"
                                                  @keypress="isNumber($event)"
                                                  outlined
                                                  class="rounded-xl"
                                                  v-model="
                                                    professional.experienceMonth
                                                  "
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col
                                                cols="4 center"
                                                class="py-0 px-0"
                                              >
                                                <div class="pt-4 ml-4">
                                                  Months of experiences
                                                </div>
                                              </v-col>
                                            </v-row>
                                            <v-row class="pb-4 ml-2">
                                              <v-radio
                                                label="Fresher"
                                                value="Fresher"
                                              >
                                              </v-radio
                                            ></v-row>
                                          </v-col> </v-radio-group
                                      ></v-card>
                                    </v-col>
                                  </v-row>
                                  <div v-if="experience == 'Experienced'">
                                    <v-row class="py-0">
                                      <v-col class="py-0"
                                        ><v-text-field
                                          outlined
                                          label="Role/ Position *"
                                          rounded
                                          class="rounded-xl"
                                          counter="100"
                                          maxLength="100"
                                          :rules="[
                                            (v) =>
                                              !!v ||
                                              'Role/ Position is required',
                                          ]"
                                          required
                                          v-model="professional.position"
                                        ></v-text-field
                                      ></v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0"
                                        ><v-select
                                          label="Employment Type"
                                          :items="employeeType"
                                          outlined
                                          item-value="id"
                                          item-text="name"
                                          class="rounded-xl"
                                          v-model="professional.employeeTypeId"
                                        >
                                        </v-select
                                      ></v-col>
                                    </v-row>

                                    <v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-text-field
                                          v-model="professional.school"
                                          outlined
                                          label="School / Institute"
                                          rounded
                                          class="rounded-xl"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-checkbox
                                          class="py-0 "
                                          v-model="isCurrentlyWorking"
                                          label="I am currently working on this role / position."
                                        ></v-checkbox>
                                      </v-col>
                                    </v-row>
                                    <v-row class="py-0">
                                      <v-col cols="6" class="py-0"
                                        ><v-text-field
                                          outlined
                                          label="Start Date*"
                                          rounded
                                          class="rounded-xl"
                                          v-model="professional.startDate"
                                          type="date"
                                          :rules="[
                                            (v) =>
                                              !!v || 'Start Date is required',
                                          ]"
                                        ></v-text-field
                                      ></v-col>
                                      <v-col cols="6" class="py-0"
                                        ><v-text-field
                                          :disabled="isCurrentlyWorking"
                                          outlined
                                          label="End Date"
                                          rounded
                                          class="rounded-xl"
                                          v-model="professional.endDate"
                                          type="date"
                                          :rules="[
                                            (v) =>
                                              !!v || 'End Date is required',
                                          ]"
                                        ></v-text-field
                                      ></v-col>
                                    </v-row>
                                    <v-row class="py-0">
                                      <v-col class="py-0"
                                        ><v-select
                                          label="Board"
                                          :items="boardsData"
                                          item-text="name"
                                          item-value="name"
                                          outlined
                                          class="rounded-xl"
                                          v-model="professional.board"
                                        >
                                        </v-select
                                      ></v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="Levels"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="tableLevels"
                                          multiple
                                          item-text="name"
                                          v-model="professional.levels"
                                          item-value="name"
                                        >
                                        </v-autocomplete
                                      ></v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0">
                                        <!-- v-model="professional.grades" -->
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="Grades"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="gradesData"
                                          multiple
                                          item-text="name"
                                          item-value="name"
                                        >
                                        </v-autocomplete
                                      ></v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0">
                                        <!-- v-model="professional.subjects" -->

                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="Subjects"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="subjectsData"
                                          multiple
                                          item-text="name"
                                          item-value="name"
                                        >
                                        </v-autocomplete
                                      ></v-col>
                                    </v-row>
                                  </div>
                                  <div v-if="experience != 'Experienced'">
                                    <v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="board"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="boardsData"
                                          item-text="name"
                                          item-value="name"
                                          multiple
                                        >
                                        </v-autocomplete>
                                      </v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="Level"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="tableLevels"
                                          multiple
                                          item-text="name"
                                          item-value="name"
                                        >
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row>

                                    <v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="Subject"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="subjectsData"
                                          multiple
                                          item-text="name"
                                          item-value="name"
                                        >
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row>
                                  </div>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="d-flex justify-space-between"
                                    >
                                      <v-btn
                                        v-if="
                                          experience == 'Experienced' &&
                                          professionalInfo.length != 1
                                        "
                                        @click="openDeleteDiolog(index)"
                                        text
                                        class="d-flex justify-end red--text"
                                        >Remove</v-btn
                                      >
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                            <v-row>
                              <v-col
                                class="mt-4"
                                v-if="experience == 'Experienced'"
                              >
                                <v-btn text @click="addProfessionalInfo"
                                  >Add More Qualification</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-card>
                      </v-form>
                      <v-container>
                        <v-row>
                          <v-btn
                            rounded
                            outlined
                            class="ma-4"
                            color="brown-text"
                            @click="e1 = e1 - 1"
                          >
                            BACK
                          </v-btn>

                          <v-btn @click="e1++" rounded  color="primary" class="my-4">
                            Next
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                      <v-form lazy-validation ref="step4">
                        <v-card elevation="0">
                          <v-card class="pa-4" elevation="0">
                            <v-row class="py-0 px-0">
                              <!-- <v-col cols="6" class="py-0"> -->
                              <!-- level -->
                              <!-- <div class="text-body-1">Subjects</div> -->
                              <!-- <v-autocomplete
                              clearable
                              deletable-chips
                              :readonly="tag == false ? true : false"
                              label="Choose Subjects"
                              outlined
                              v-model="campaignData.subjects"
                              :rules="[(v) => !!v || 'Subject is required']"
                              class="rounded-xl"
                              small-chips
                              :items="subjects"
                              multiple
                              item-text="name"
                              item-value="name"
                              solo
                              flat
                            >
                            </v-autocomplete> -->
                              <!-- </v-col> -->
                              <v-col cols="6" class="py-0">
                                <div class="text-body-1">Cluster</div>
                                <v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Choose Clusters"
                                  outlined
                                  :disabled="roleCluster != 'All Clusters'"
                                  v-model="cluster"
                                  required
                                  :rules="[(v) => !!v || 'Cluster is required']"
                                  class="rounded-xl"
                                  :items="clusters"
                                  item-text="name"
                                  item-value="name"
                                  solo
                                  flat
                                  @change="getClusterSchool"
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col class="py-0" cols="6" md="6" sm="6">
                                <div class="text-body-1">School</div>
                                <!-- :disabled="roleSchool != 'All'" -->
                                <!-- v-model="schools" -->

                                <v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Choose Schools"
                                  outlined
                                  v-model="school"
                                  :disabled="roleSchool != 'All'"
                                  required:rules="[v => !!v || 'School is required']"
                                  class="rounded-xl"
                                  small-chips
                                  :items="schools"
                                  item-text="name"
                                  item-value="name"
                                  solo
                                  flat
                                >
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="py-0">
                                <v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Level"
                                  outlined
                                  class="rounded-xl"
                                  small-chips
                                  :items="tableLevels"
                                  multiple
                                  v-model="levels"
                                  item-text="name"
                                  total-items
                                  @change="getGrades()"
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col class="py-0"
                                ><v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Grades"
                                  outlined
                                  v-model="grades"
                                  class="rounded-xl"
                                  small-chips
                                  :items="gradesData"
                                  multiple
                                  item-text="name"
                                  item-value="name"
                                >
                                </v-autocomplete
                              ></v-col>
                            </v-row>

                            <v-row>
                              <!-- v-model="professional.board" -->

                              <v-col class="py-0"
                                ><v-select
                                  label="Board"
                                  :items="boardsData"
                                  item-text="name"
                                  item-value="name"
                                  outlined
                                  class="rounded-xl"
                                  v-model="board"
                                >
                                </v-select
                              ></v-col>
                              <!-- v-model="professional.subjects" -->

                              <v-col class="py-0"
                                ><v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Subjects"
                                  outlined
                                  class="rounded-xl"
                                  v-model="subjects"
                                  small-chips
                                  :items="subjectsData"
                                  multiple
                                  item-text="name"
                                  item-value="name"
                                >
                                </v-autocomplete
                              ></v-col>
                            </v-row>
                          </v-card>
                        </v-card>
                      </v-form>
                      <v-container>
                        <v-row>
                          <v-btn
                            rounded
                            outlined
                            class="ma-4"
                            color="brown-text"
                            @click="e1 = e1 - 1"
                          >
                            BACK
                          </v-btn>

                          <v-btn
                            :loading="isCreatingUser"
                            rounded
                            depressed
                            color="primary"
                            class="my-4"
                            @click="formbtnBool ? updateUser() : createUser()"
                          >
                            {{ formbtn() }} DETAILS
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-card>
              </v-stepper>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Success Dialog -->
      <v-dialog v-model="successDialog" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 py-4">
                New User {{ formbtnBool == false ? "Created" : "Updated" }}
              </p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="successDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- dialog for resend invite success-->
      <v-dialog
        v-model="resendInviteSuccessDialog"
        max-width="366px"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 py-4">Invite Sent Successfully</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="resendInviteSuccessDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!--diolog for delete academic and professional array-->
      <v-dialog v-model="deleteUserDetails" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="#db44371f" size="90"
                ><v-icon size="65" color="#DB4437"
                  >mdi-trash-can-outline</v-icon
                ></v-avatar
              >

              <p class="text-h5 pt-4 pb-0">
                Are Sure you want to delete this Info ?
              </p>
              <p
                class="text-disabled grey--text text-subtitle-1"
                color="rgba(0, 0, 0, 0.6)"
                disabled
              >
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between" fluid>
                <v-btn
                  class="black--text"
                  color="#0000001a"
                  large
                  width="157px"
                  rounded
                  @click="deleteUserDetails = false"
                  >CANCEL</v-btn
                >
                <v-btn
                  class="primary"
                  large
                  width="157px"
                  rounded
                  @click="removeDataFromSteps()"
                  >DELETE</v-btn
                >
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Error Dialog -->
      <v-dialog v-model="errorDialog" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn
                class="primary"
                large
                width="157px"
                rounded
                @click="errorDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Filter Dialog -->
      <!-- <v-dialog v-model="filterDialog" max-width="400px">
        <v-card width="400px" height="100%">
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-body1 font-weight-bold black--text">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card
                height="450px"
                elevation="0"
                id="myScroll"
                class="pt-5 ,pb-5"
              >
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text">
                      Question Type
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedQuestionType"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(questionType, index) in questionTypeList"
                    :key="index"
                    :value="questionType"
                    :class="{
                      'selectedQuestionType-chip':
                        selectedQuestionType.includes(questionType),
                    }"
                    elevated
                    @click="updateQuestionType"
                  >
                    {{ questionType }}
                  </v-chip>
                </v-chip-group>
  
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Level
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedLevel"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(level, index) in levels"
                    :key="index"
                    :value="level"
                    :class="{
                      'selectedLevel-chip': selectedLevel.includes(level),
                    }"
                    elevated
                    @click="updateLevel"
                  >
                    {{ level.name }}
                  </v-chip>
                </v-chip-group>
  
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Difficulty Level
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selecteddifficultyLevel"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(difficultyLevel, index) in levels"
                    :key="index"
                    :value="difficultyLevel"
                    :class="{
                      'selecteddifficultyLevel-chip':
                        selecteddifficultyLevel.includes(difficultyLevel),
                    }"
                    elevated
                    @click="updatedifficultyLevel"
                  >
                    {{ difficultyLevel.name }}
                  </v-chip>
                </v-chip-group>
  
              </v-card>
              <div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    outlined
                    class="pa-4"
                    @click="filterDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn rounded class="primary pa-4">Apply</v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->
    </v-container>
  </div>
</template>
<script>
import "../styles.css";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AddressController from "@/controllers/AddressController";
import UsersController from "@/controllers/UsersController";
import LevelController from "@/controllers/LevelController";
import SubjectController from "@/controllers/SubjectController";

import BoardController from "@/controllers/BoardController";
import SchoolController from "@/controllers/SchoolController";
import AuthService from "../services/AuthService";
import { onSnapshot, doc } from "firebase/firestore";

import algoliasearch from "algoliasearch";
import { collection, getDocs, getDoc, query, where } from "firebase/firestore";
import { db } from "../firebase";
import CampTotalPieChart from "./CampTotalPieChart.vue";
import HorizontalBarChartVue from "./HorizontalBarChart";
export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },
  components: {
    CampTotalPieChart,
    HorizontalBarChartVue,
  },
  name: "UsersView",
  data() {
    return {
      clusters: [],
      cluster: [],
      schools: [],
      school: [],
      snapCluter: [],

      roleCluster: "",
      roleSchool: "",

      dobRules: [
        (v) => !!v || "Date of Birth is required",
        // (v) =>
        //   /^\d{4}-\d{2}-\d{2}$/.test(v) ||
        //   "Date of Birth must be in YYYY-MM-DD format",
      ],

      rules: {
        required: (value) => !!value || "Field is required",
      },
      algoliaIndex: null,
      options: {},
      isLoading: true,
      pageSize: 10,
      clustersSnap: [],
      page: 1,
      count: 0,
      e1: 1,
      editId: null,
      formbtnBool: false,
      experience: "Experienced",
      levels: [],
      levelId: -1,
      clusterSchool: "",
      hover: "",
      subjects: [],
      subjectId: [],
      isCurrentlyWorking: false,
      errorMessage: "Failed",

      dialog: false,
      deleteDialog: false,
      singleSelect: false,
      selected: [],
      experienceYear: -1,
      deleteUserDetails: false,
      experienceMonth: -1,
      filterDialog: false,
      rolePosition: "",
      employeeTypeId: -1,
      board: "",
      gradesData: [],
      grades: [],
      gradeId: -1,
      startDate: -1,
      endDate: -1,
      extraCarricularActivities: "",
      isCreatingUser: false,
      successDialog: false,
      resendInviteSuccessDialog: false,
      errorDialog: false,
      boardsData: "",
      tableLevels: [],
      subjectsData: [],
      schoolData: "",
      store: "",
      search: "",
      vgosCount: 0,
      jobSeekerCount: 0,

      headers: [
        { text: "Full Name", value: "fullname", cellClass: "w-10" },
        {
          text: "Email ID",
          value: "personalInfo.contactInfo.email",
          cellClass: "w-15",
        },
        {
          text: "Phone No",
          value: "personalInfo.contactInfo.phoneNumber",
          cellClass: "w-15",
        },
        { text: "Created On", value: "createdOn", cellClass: "w-15" },
        { text: "Status", value: "status", cellClass: "w-15" },
        { text: "Role", value: "userType", cellClass: "w-15" },
        { text: "Last active on", value: "updatedOn", cellClass: "w-15" },
        { text: "Actions", value: "actions", cellClass: "w-10" },
      ],
      personalInfo: {
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        isEmailVerified: false,
        isPhoneVerified: false,
        contactInfo: {
          email: "",
          phoneNumber: "",
        },
        addressInfo: {
          countryName: "India",
          stateName: "",
          districtName: "",
          tulakName: "",
          cityName: "",
          pincode: null,
          address: "",
        },
      },
      academicsInfo: [
        {
          institution: "",
          programme: "",
          startDate: "",
          endDate: "",
          fieldOfStudy: "",
          gradeScore: 0,
          gradeType: "",
          certificateUrl: "",
        },
      ],
      professionalInfo: [
        {
          experienceYear: 0,
          experienceMonth: 0,
          position: "",
          employeeTypeId: 0,
          board: 0,
          startDate: Date.now(),
          endDate: Date.now(),
          levels: [],
          grades: [],
          subjects: [],
          school: "",
          otherName: "",
        },
      ],
      expandedPanelIndex: 0, // set the last panel as expanded by default
      countries: [
        {
          name: "India",
        },
      ],
      states: [],
      districts: [],
      cities: [],
      talukas: [],
      users: [],
      employeeType: [
        {
          id: 1,
          name: "Permanent",
        },
        {
          id: 2,
          name: "Contract",
        },
        {
          id: 3,
          name: "Prohibition",
        },
      ],
      algoliaData: [],
      preloader: true,
      // loader: false,
    };
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
        this.pageSize = this.options.itemsPerPage;
        this.page = this.options.page;
        this.fetchUsers();
      },
      deep: true,
    },
    search(newValue) {
      console.log(newValue);
      this.pageSize = this.options.itemsPerPage;
      this.page = this.options.page;
      this.options.page = 1;
      this.fetchUsers();
    },
  },
  clustersSnap: {
    handler() {
      // console.log(this.options);
      // this.pageSize = this.options.itemsPerPage;
      // this.page = this.options.page;
      // this.fetchUsers();
      console.log("hello", this.clustersSnap);
    },
    deep: true,
  },

  methods: {
    async SnapshotOnCluster() {
  console.log("function started");

  var graphData = {
    unaccessed_teacher: 0,
    inprogress_teacher: 0,
    accessed_teacher: 0,
    unaccessed_jobseeker: 0,
    inprogress_jobseeker: 0,
    accessed_jobseeker: 0,
    primary_teacher: 0,
    pre_primary_teacher: 0,
    middle_teacher: 0,
    secondary_teacher: 0,
    senior_secondary_teacher: 0,
    primary_jobseeker: 0,
    pre_primary_jobseeker: 0,
    middle_jobseeker: 0,
    secondary_jobseeker: 0,
    senior_secondary_jobseeker: 0,
  };

  console.log("cluster fetching started");

  const querySnapshot = await getDocs(collection(db, "clusters"));
  if (querySnapshot.empty) {
    alert("Cluster Not Found!!");
    return;
  } else {
    querySnapshot.forEach((doc) => {
      this.clustersSnap.push(doc.data().name);
    });
  }
  console.log("clusters fetched");

  const clustersRef = new Array(this.clustersSnap.length);
  var schools = [];

  console.log("cluster ref creating and snapshot started");

  for (var i = 0; i < this.clustersSnap.length; i++) {
    clustersRef[i] = collection(db, "dashboards", "ums_dashboard", this.clustersSnap[i]);

    await onSnapshot(clustersRef[i], (querySnapshot) => {
      schools = []; // Clear schools array before populating it with new data
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          console.log("hello", data);
          if (data != undefined) {
            schools.push(data);
          }
        } else {
          console.log("Document does not exist");
        }
      });
      console.log("snapshot schools", schools);

      console.log("calculation started");
      schools.forEach((school) => {
        graphData.accessed_jobseeker += school.accessed_jobseeker;
        graphData.accessed_teacher += school.accessed_teacher;
        graphData.inprogress_jobseeker += school.inprogress_jobseeker;
        graphData.inprogress_teacher += school.inprogress_teacher;

        graphData.middle_jobseeker += school.middle_jobseeker;
        graphData.middle_teacher += school.middle_teacher;

        graphData.pre_primary_jobseeker += school.pre_primary_jobseeker;
        graphData.pre_primary_teacher += school.pre_primary_teacher;

        graphData.primary_jobseeker += school.primary_jobseeker;
        graphData.primary_teacher += school.primary_teacher;

        graphData.secondary_jobseeker += school.secondary_jobseeker;
        graphData.secondary_teacher += school.secondary_teacher;
      });
      console.log("calculated schools", graphData);
    });

    console.log("snapshot methods end, sum of school data started");
  }
}
,
    testMethod() {
      console.log("school", this.school);
    },

    async getClusterSchool() {
      console.log("cluster = ", this.cluster);
      const response = await SchoolController.getClusterSchool(this.cluster);
      console.log("school", response);
      if (response.data.flag) {
        var schools = response.data.schools;
        this.schools = schools;
        console.log("school", this.schools);
      } else {
        alert(response.data.error);
      }
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();

      if (response.data.flag) {
        // console.log(response);
        this.clusters = response.data.clusters;
        // console.log("afkdsbxbfabsbdj,a", this.clusters);
      } else {
        alert(response.data.error);
      }
    },
    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;
      if (this.roleSchool == undefined) {
        AuthService.logout();
        this.$router.push("/login");
      }
      if (this.roleCluster != "All Clusters") {
        this.cluster = this.roleCluster;
        this.getClusterSchool();
      }
      if (this.roleSchool != "All") {
        this.school = this.roleSchool;
      }

      // console.log(
      //   "clusterRole=",
      //   this.roleCluster,
      //   "schoolRole",
      //   this.roleSchool
      // );
    },
    getStepName(step) {
      var name = "";
      if (step == 1) {
        name = "Personal Information";
      } else if (step == 2) {
        name = "Academics";
      } else if (step == 3) {
        name = "Professional Info";
      } else if (step == 4) {
        name = "VGOS Onboarding";
      }
      return name;
    },
    resetProgress() {
      this.progress = 0;
      this.buffer = 100;
      this.dialogVisible = false;
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async getTotalCount() {
      if (this.clusterSchool != "") {
        const { nbHits } = await this.algoliaIndex.search(this.search, {
          hitsPerPage: 0,
        });
        const vgosHits = await this.algoliaIndex.search("Teacher");
        const jobSeekerHits = await this.algoliaIndex.search("Job Seeker");
        this.count = nbHits;
        this.vgosCount = vgosHits.nbHits;
        this.jobSeekerCount = jobSeekerHits.nbHits;
      } else {
        const { nbHits } = await this.algoliaIndex.search(
          this.clusterSchool + " " + this.search,
          { hitsPerPage: 0 }
        );
        const vgosHits = await this.algoliaIndex.search(
          "Teacher" + " " + this.clusterSchool
        );
        const jobSeekerHits = await this.algoliaIndex.search(
          "Job Seeker" + " " + this.clusterSchool
        );
        this.count = nbHits;
        this.vgosCount = vgosHits.nbHits;
        this.jobSeekerCount = jobSeekerHits.nbHits;
      }
    },

    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    async updateProfessionalInfo(data, userId) {
      const response = await UsersController.updateProfessionalInfo(
        data,
        userId,
        data.id
      );
      //console.log(response);
    },
    goToStep2() {
      if (this.formbtnBool) {
        if (this.$refs.step1.validate()) {
          // this.updateUser(this.personalInfo, this.editId);
          this.e1 = 2;
        }
      } else {
        if (this.$refs.step1.validate()) {
          this.e1 = 2;
          this.expandedPanelIndex = 0;
          // console.log(this.personalInfo)
        }
      }
    },
    updateData(item) {
      this.editId = item.userId;
      //console.log("item data", item); // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      (this.personalInfo.title = item.title),
        (this.personalInfo.firstName = item.personalInfo.firstName),
        (this.personalInfo.middleName = item.personalInfo.middleName),
        (this.personalInfo.lastName = item.personalInfo.lastName),
        (this.personalInfo.contactInfo.email =
          item.personalInfo.contactInfo.email),
        (this.personalInfo.dob = item.personalInfo.dob),
        (this.personalInfo.gender = item.personalInfo.gender),
        (this.personalInfo.contactInfo.phoneNumber =
          item.personalInfo.contactInfo.phoneNumber),
        (this.personalInfo.addressInfo.countryName =
          item.personalInfo.addressInfo.countryName),
        (this.personalInfo.addressInfo.stateName =
          item.personalInfo.addressInfo.stateName),
        (this.personalInfo.addressInfo.cityName =
          item.personalInfo.addressInfo.cityName),
        (this.personalInfo.addressInfo.districtName =
          item.personalInfo.addressInfo.districtName),
        (this.personalInfo.addressInfo.address =
          item.personalInfo.addressInfo.address),
        (this.personalInfo.addressInfo.pincode =
          item.personalInfo.addressInfo.pincode),
        (this.academicsInfo = item.academicsInfo);
      this.professionalInfo = item.professionalInfo;
      // this.professionalInfo.experienceYear = item.experienceYear,
      // this.professionalInfo.experienceMonth = item.experienceMonth,
      // this.professionalInfo.position = item.position,
      // this.professionalInfo.employeeTypeId = item.employeeTypeId,
      // this.professionalInfo.board = item.board,
      this.dialog = true;
      // this.getAcademics(this.editId),
      // this.getProfessionalInfo(this.editId)
    },
    // nextStep() {
    //   if (this.e1 == 3) {
    //     this.createUser();
    //   } else {
    //     this.e1 = this.e1 + 1;
    //   }
    // },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      //console.log("suvject res", response);

      if (response.status == 200) {
        this.subjectsData = response.data.subjects;
      } else {
        alert("subject Not Found!!");
      }
    },
    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        this.tableLevels = response.data.levels;
      } else {
        alert("Levels Not Found!!");
      }

      // console.log("level data", this.levelData.rows);
    },
    async getGrades() {
      const array = this.tableLevels.filter((level) =>
        this.levels.includes(level.name)
      );
      console.log(array);

      console.log("params araray", array);
      this.gradesData = [];
      array.forEach((subArray) => {
        subArray.grades.forEach((grade) => {
          this.gradesData.push(grade);
        });
      });
    },
    goToStep3() {
      if (this.formbtnBool) {
        // this.updateAcademics();
        this.e1 = 3;
      } else {
        if (this.$refs.step2.validate()) {
          this.e1 = 3;
          this.expandedPanelIndex = 0;
        }
      }
    },
    async createUser() {
      //console.log("create method", this.formbtnBool, "condition bool");
      if (this.formbtnBool) {
        this.dialog = false;
        this.formbtnBool = false;
      } else if (this.$refs.step3.validate()) {
        (this.personalInfo.contactInfo.phoneNumber =
          "+91" + this.personalInfo.contactInfo.phoneNumber),
          (this.isCreatingUser = true);
        const response = await UsersController.createUser({
          userType: "TEACHER",
          status: "INACTIVE",
          cluster: this.cluster,
          school: this.school,
          levels: this.levels,
          grades: this.grades,
          board: this.board,
          subjects: this.subjects,
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
        });
        if (response.status == 200) {
          this.isCreatingUser = false;
          this.successDialog = true;
          this.dialog = false;
          this.fetchUsers();
        } else {
          this.isCreatingUser = false;
          this.errorMessage = response.data.error;
          this.errorDialog = true;
        }
      }
    },
    async updateUser() {
      const response = await UsersController.updateUserInfo(
        {
          userType: "Job Seeker",
          status: "INACTIVE",
          cluster: this.cluster,
          school: this.school,
          levels: this.levels,
          grades: this.grades,
          board: this.board,
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
        },
        this.editId
      );

      if (response.status) {
        this.isCreatingUser = false;
        this.successDialog = true;
        this.dialog = false;
        this.fetchUsers();
      } else {
        this.isCreatingUser = false;
        this.errorMessage = response.data.error;
        this.errorDialog = true;
      }
    },
    async updateAcademics(qualification, usrid) {
      // console.log("data  id acadmic id", qualification, usrid);
      const response = await UsersController.updateAcademics(
        qualification,
        usrid,
        qualification.id
      );
      //console.log(response);
    },
    async getAcademics(id) {
      const response = await UsersController.getAcademics(id);
      //console.log("res", response)
      this.academicsInfo = response.data.data;
      // console.log("academic data comming", this.academicsInfo)
    },

    async getProfessionalInfo(id) {
      const response = await UsersController.getprofessionalInfo(id);
      this.professionalInfo = response.data.data;
      // console.log("professional data comming", this.professionalInfo)
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },

    async deleteUser(items) {
      //console.log("selected user", items);
      var ids = [];
      items.forEach((doc) => {
        ids.push(doc.userId);
      });
      const response = await UsersController.deleteUser({
        ids: ids,
      });
      if (response.status == 200) {
        this.selected = [];
        this.fetchUsers();
        this.selected.length = 0;
        ids = [];
      } else {
        alert(response.data.error);
      }
      this.deleteDialog = false;
    },
    
    addAcademicQualification() {
      this.academicsInfo.push({
        institution: "",
        programme: "",
        startDate: "",
        endDate: "",
        fieldOfStudy: "",
        gradeScore: 0,
        gradeType: "",
        certificateUrl: "",
      });
      this.expandedPanelIndex = this.academicsInfo.length - 1;
    },
    addProfessionalInfo() {
      this.professionalInfo.push({
        experienceYear: 0,
        experienceMonth: 0,
        position: "",
        employeeTypeId: 0,
        board: 0,
        startDate: "",
        endDate: "",
        school: "",
      });
      this.expandedPanelIndex = this.professionalInfo.length - 1;
    },
    async fetchUsers() {
      await this.getUsersFromAlgolia();
      // this.count = this.users.length;
      this.isLoading = false;
      this.preloader = false;
      this.getTotalCount();

      // const response = await UsersController.getUsers();
      // //console.log("users", response);
      // if (response.data.flag) {
      //   this.users = response.data.users;
      //   this.count = response.data.count;
      //   //   this.users.forEach((item) => {
      //   //   item.fullName = item.first_name + " " + item.last_name;
      //   // });
      //   //console.log(response.data.data);
      // } else {
      //   alert(response.data.error);
      // }
    },
    async fetchCountries() {
      const response = await AddressController.getCountries();

      if (response.data.success) {
        this.countries = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
      //console.log(this.countries);
    },

    async fetchStates() {
      console.log("fetch states");
      var states = [];
      const querySnapshot = await getDocs(collection(db, "states"));
      if (querySnapshot.empty) {
        alert("States Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          states.push(doc.data());
      
        });
      }
      this.states = states;
      //console.log("state formm base", states);
    },

    async fetchDistricts() {
      //console.log(this.personalInfo.state_id);
      const q = query(
        collection(db, "districts"),
        where("state", "==", this.personalInfo.addressInfo.stateName)
      );
      const querySnapshots = await getDocs(q);
      this.districts = [];
      querySnapshots.forEach((doc) => {
        this.districts.push(doc.data());
        // console.log(doc.data());
      });
      // console.log("district",this.districts)

      //console.log(this.districts);
      // this.fetchCities();
    },

    async fetchCitiesAndTalukas() {
      const q = query(
        collection(db, "cities"),
        where("state", "==", this.personalInfo.addressInfo.stateName),
        where("district", "==", this.personalInfo.addressInfo.districtName)
      );
      const querySnapshots = await getDocs(q);
      this.cities = [];
      querySnapshots.forEach((doc) => {
        this.cities.push(doc.data());
        // console.log(doc.data());
      });
      // console.log("district",this.cities)

      //console.log(this.cities);
    },

    async fetchTalukas() {
      const response = await AddressController.getTalukas(
        this.personalInfo.addressInfo.districtName
      );

      if (response.data.success) {
        this.talukas = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
      //console.log(this.talukas);
    },
    async fetchCities() {
      const response = await AddressController.getCities(
        this.personalInfo.addressInfo.stateName,
        this.personalInfo.addressInfo.tulakName
      );
      if (response.data.success) {
        this.cities = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
      //console.log(this.cities);
    },
    removeDataFromSteps() {
      this.deleteUserDetails = true;
      if (this.e1 == 2) {
        this.academicsInfo.splice(this.indexValue, 1);
      } else if (this.e1 == 3) {
        this.professionalInfo.splice(this.indexValue, 1);
      }
      this.deleteUserDetails = false;
      this.indexValue = null;
    },
    openDeleteDiolog(index) {
      this.indexValue = index;
      this.deleteUserDetails = true;
    },
    async getBoards() {
      const response = await BoardController.getBoards();
      // console.log(response);

      if (response.status == 200) {
        this.boardsData = response.data.boards;
      } else {
        alert("Boards Not Found!!");
      }

      //  console.log("board log", this.boardsData);
    },
    async getSchool() {
      const response = await SchoolController.getSchool();
      if (response.status == 200) {
        this.schoolData = response.data.levels;
      } else {
        alert("Schools Not Found!!");
      }
    },

    async getUsersFromAlgolia() {
      if (this.clusterSchool != "") {
        await this.algoliaIndex
          .search(this.search, {
            hitsPerPage: this.pageSize,
            page: this.page - 1,
          })
          .then(({ hits }) => {
            console.log("hits", hits);
            this.users = hits;

            this.preloader = false;
          });
      } else {
        await this.algoliaIndex
          .search(this.search + " " + this.clusterSchool, {
            hitsPerPage: this.pageSize,
            page: this.page - 1,
          })
          .then(({ hits }) => {
            console.log("hits", hits);
            this.users = hits;

            this.preloader = false;
          });
      }
      // Fetch all records from Algolia index
      // var search = "";
      console.log(this.clusterSchool);
    },
    initAlgoliaClient() {
      // Initialize Algolia client
      const client = algoliasearch(
        this.$ALGOLIA_APP_ID,
        "a814971ff9eaeb34a7a143e7226d2b31"
      );
      this.algoliaIndex = client.initIndex(this.$USERS_INDEX);
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    filteredLevel() {
      if (this.searchLevels) {
        const regex = new RegExp(this.searchLevels);
        return this.tableLevels.filter((tableLevels) =>
          regex.test(tableLevels.name)
        );
      } else {
        return this.tableLevels;
      }
    },

    filteredBoard() {
      if (this.searchBoards) {
        const regex = new RegExp(this.searchBoards);
        return this.boardsData.filter((boardData) =>
          regex.test(boardData.name)
        );
      } else {
        return this.tableLevels;
      }
    },
    filteredSubject() {
      if (this.searchSubject) {
        const regex = new RegExp(this.searchSubject);
        return this.subject.filter((subject) => regex.test(subject.name));
      } else {
        return this.subject;
      }
    },
  },
  created() {
    // this.fetchCountries();
    console.log("in created");

    this.fetchStates();
    this.initAlgoliaClient();
    this.fetchUsers();
    this.getTotalCount();
    this.getLevels();
    this.getSubjects();
    this.getGrades();
    this.getBoards();
    this.getSchool();
    this.fetchAllClusters();
    this.SnapshotOnCluster();

    this.$store.state.breadcrumb = "Users";
    // this.updateUsers()
  },
  mounted() {
    if (this.$store.state.role.createdOn == undefined) {
      this.logout();
    }
    if (!this.$store.state.role.teacherOnboarding.read) {
      this.$router.push("/notFound");
    }
    this.getClusterOrSchool();
  },
};
</script>
<style scoped>
.btnStyle.v-btn--outlined {
  border: thin solid #190cca;
}
.graph {
  border-radius: 8px !important;
  margin: 8px;
  padding: 2px;
}
</style>
