<template>
    <canvas ref="chart" :id="chartName" class="gaugeChart"></canvas>
</template>
  
<script>
import "chartjs-gauge";

export default {

    props: ['chartName' , 'data'],

    mounted() {
        this.initializeChart();
    },
    methods: {
        initializeChart() {

            var config = {
                type: "gauge",
                data: {
                    datasets: [
                    this.data
                    ],
                },
                options: {
                    layout: {
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        },
                        
                    },
                    needle: {
                        radiusPercentage: 2,
                        widthPercentage: 5,
                        lengthPercentage: 80,
                        color: 'rgba(95, 159, 104, 1)',
                    },
                    valueLabel: "none",
                    borderRadius: 5,
                },
            };

            var ctx = document.getElementById(this.chartName).getContext("2d");
            this.myGauge = new Chart(ctx, config);
        },

    },
};
</script>

<style scoped>
.gaugeChart {
    width: 100% !important;
    height: 50% !important;
}
</style>